import { Cookies } from "react-cookie";
import { lightTheme } from "Theme";

let cookies = new Cookies();

const palette =
  cookies.get("isLight") === "true" ? lightTheme.palette : lightTheme.palette;

export default {
  "@global": {
    ".preview-disabled": {
      position: "relative",
      opacity: "0.5",
      "&::after": {
        content: "''",
        width: "2px",
        height: "25px",
        display: "block",
        background: "#9C9C9C",
        position: "absolute",
        transform: "rotate(60deg)",
      },
    },

    ".d-none": {
      display: "none",
    },
    ".short-text": {
      overflow: "hidden",
      "white-space": "nowrap",
      "text-overflow": "ellipsis",
      "line-height": "initial",
    },
    /**************************************************************************************************
    layout
    **************************************************************************************************/
    ".App-header": {
      // "padding-bottom": "130px",
    },
    /**************************************************************************************************
    form
    **************************************************************************************************/
    ".inline-form": {
      width: "100%",
      "justify-content": "space-between",
      "& .inner-form-wrap": {
        "&>div": {
          "&:not(:first-of-type)": {
            "margin-left": "5px",
          },
        },
      },
    },
    /**************************************************************************************************
    table
    **************************************************************************************************/
    ".custom-table": {
      position: "relative",

      "& .btn-close": {
        width: "100%",
        height: "22px",
        "margin-bottom": "15px",
        position: "sticky",
        right: 0,
        top: 0,
        background: "#fff",
        "text-align": "right",
        "z-index": "9",
        // "& .btn-icon": {
        //   "margin-top": "15px",
        //   "margin-right": "15px",
        // },
      },

      "& .inner-table-wrap": {
        position: "relative",
        "z-index": "5",
        background: "#fff",
        // padding: "0 40px",
      },

      "& .MuiTableCell-root": {
        // position: "relative",
      },

      "& .inner-table-title": {
        position: "relative",
        display: "inline-block",
        "margin-top": "5px",
        "font-size": "20px",
        "font-weight": "700",
        "padding-right": "15px",

        "&+ .btn-icon": {
          "vertical-align": "baseline",
        },
      },

      "& .inner-table-catg-title": {
        "font-size": "12px",
        color: palette.text.sub.gray01,
      },

      "& .inner-table-list": {
        "margin-top": "40px",
        "& li": {
          display: "inline-block",
          "vertical-align": "top",
          "word-break": "break-all",
          "&:nth-child(3)": {
            width: "20%",
          },
          "&:last-child": {
            width: "25%",
          },
          "&:not(:last-of-type)": {
            "margin-right": "50px",
          },

          "& img": {
            display: "block",
          },

          "& .inner-table-catg-title": {
            "margin-top": "12px",

            "&+p": {
              "margin-top": "5px",
              "font-size": "12px",
              "font-weight": "600",
            },
          },
        },
      },
    },

    ".custom-table-checkbox": {
      "& .MuiTableBody-root": {
        "& .MuiTableCell-root": {
          // "&:before": {
          //   content: "''",
          //   display: "block",
          //   position: "absolute",
          //   left: 0,
          //   bottom: 0,
          //   width: "100%",
          //   height: "1px",
          //   background: palette.line.gray02,
          // },

          "&:first-of-type": {
            "padding-right": "0",
          },
        },
      },

      "& .MuiCheckbox-root": {
        padding: 0,
      },

      // "& .table-inner-list": {
      //   display: "inline-flex",
      //   "flex-wrap": "wrap",
      //   "margin-left": "-10px",
      //   "margin-top": "-10px",

      //   "& li": {
      //     "margin-left": "10px",
      //     "margin-top": "10px",
      //   },
      //   // gap: "10px",
      // },
    },
    ".table-inner-list": {
      display: "inline-flex",
      "flex-wrap": "wrap",
      "margin-left": "-10px",
      "margin-top": "-10px",

      "& li": {
        "margin-left": "10px",
        "margin-top": "10px",

        "&:first-of-type": {
          "margin-left": "0 !important",
        },
      },
    },
  },
};
