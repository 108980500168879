import {
  Box,
  DialogContent,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Conference from "./conference";
import Email from "./email";
import FileResources from "./file-resources";
import GeneralInfo from "./general-info";
import MyPage from "./my-page";
import Notice from "./notice";
import OnlineTraining from "./online-training";
import PrProducts from "./pr-products";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0",
    "& .tab-panel": {
      padding: "40px 0 0px",
      "& .MuiBox-root": {
        overflow: "hidden",
        padding: 0,
        "& .MuiAccordion-root": {
          background: "#f3f4f6",
          boxShadow: "none",
          marginBottom: "20px",
          "&:before": {
            display: "none",
          },
          "&:last-child": {
            marginBottom: 0,
          },
          "& .MuiAccordionSummary-root": {
            padding: "40px",
            "&.Mui-expanded": {
              "& .num": {
                color: theme.palette.primary.main,
              },
              "& .tit": {
                color: theme.palette.primary.main,
              },
            },
            "& .MuiAccordionSummary-content": {
              margin: 0,
              flexDirection: "column",
              "& .num": {
                fontSize: 16,
                fontWeight: "bold",
                color: theme.palette.text.sub.gray01,
                marginBottom: "10px",
              },
              "& .tit": {
                fontSize: 14,
              },
            },
            "& .MuiAccordionSummary-expandIcon": {
              padding: 0,
              alignSelf: "flex-end",
            },
          },
          "& .MuiCollapse-root": {
            "& .MuiCollapse-wrapper": {
              marginTop: "0 !important",
              "& .MuiCollapse-wrapperInner": {
                padding: "0 120px 40px 60px",
                "& .MuiAccordionDetails-root": {
                  padding: 0,
                },
              },
            },
            "& .MuiAccordionDetails-root": {
              display: "block",
              "& .MuiTypography-root": {
                marginBottom: "20px",
                fontSize: 14,
                "&.Accordion-gray": {
                  display: "block",
                  color: theme.palette.text.sub.gray01,
                },
                "& .Accordion-tit": {
                  display: "block",
                  fontWeight: "bold",
                },
                "& .page-strong": {
                  display: "block",
                  fontWeight: "bold",
                },
              },
            },
          },
        },
      },
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function HowToUse(props) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      <DialogContent className={classes.container}>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="General Info" {...a11yProps(0)} />
          <Tab label="Notice" {...a11yProps(1)} />
          <Tab label="Marketing Asset" {...a11yProps(2)} />
          <Tab label="Conference" {...a11yProps(3)} />
          <Tab label="Documents" {...a11yProps(4)} />
          <Tab label="Online Training" {...a11yProps(5)} />
          <Tab label="My Page" {...a11yProps(6)} />
          <Tab label="Email" {...a11yProps(7)} />
        </Tabs>
        <div className="tab-panel">
          <TabPanel value={tabValue} index={0}>
            <GeneralInfo />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Notice />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <PrProducts />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Conference />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <FileResources />
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <OnlineTraining />
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <MyPage />
          </TabPanel>
          <TabPanel value={tabValue} index={7}>
            <Email />
          </TabPanel>
        </div>
      </DialogContent>
    </>
  );
}
