import { combineReducers } from "redux";
import codeManagement from "./codeManagement";
import paperRegistrationStatus from "./paper-registration-status";
import user from "./user";

const rootReducer = combineReducers({
  user,
  codeManagement,
  paperRegistrationStatus,
});

export default rootReducer;
