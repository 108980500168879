import React from "react";
import css from "./non-login-menu.css";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    "& a": {
      "&:visited": {
        color: "#000",
      },
      "&:hover": {
        color: "#000",
      },
    },
  },
}));

export default function NonLoginMenu(props) {
  const classes = useStyles();
  return (
    <div
      className={classes.container + " sitemap pc"}
      style={{ display: "block" }}
    >
      <h1
        className="logo"
        style={{
          marginTop: 1,
        }}
      >
        <a href={`${process.env.REACT_APP_SEEGENE_URL}/`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icon/icon-logo-public.svg`}
            alt="logo"
          />
        </a>
      </h1>
      <div
        id="gnbclosebtn"
        style={{ cursor: "pointer" }}
        onClick={() => {
          props?.setMenuOpen();
        }}
      >
        <img
          className="ico-close"
          src={`${process.env.PUBLIC_URL}/images/icon/icon-close.png`}
          alt="close"
        />
        {/* <i className="ico-close" /> */}
      </div>
      <div
        className="container"
        style={{
          position: "relative",
          maxWidth: 1560,
        }}
      >
        <div className="menuwrap2">
          <ul className="depth2">
            <li className="topmenu">
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/product`}>
                Products
              </a>
            </li>
            <li>
              <a href="#">Assays</a>

              <ul className="depth3">
                <li>
                  <a href={`${process.env.REACT_APP_SEEGENE_URL}/assays/rp`}>
                    Respiratory Infections
                  </a>
                </li>
                <li>
                  <a href={`${process.env.REACT_APP_SEEGENE_URL}/assays/gi`}>
                    Gastrointestinal Tract Infections
                  </a>
                </li>
                <li>
                  <a href={`${process.env.REACT_APP_SEEGENE_URL}/assays/hpv`}>
                    Human Papillomavirus Infections
                  </a>
                </li>
                <li>
                  <a href={`${process.env.REACT_APP_SEEGENE_URL}/assays/sti`}>
                    Sexually Transmitted Infections
                  </a>
                </li>
                <li>
                  <a href={`${process.env.REACT_APP_SEEGENE_URL}/assays/tb`}>
                    Tuberculosis
                  </a>
                </li>
                <li>
                  <a href={`${process.env.REACT_APP_SEEGENE_URL}/assays/dr`}>
                    Drug Resistance
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/assays/magicplex_sepsis_realtime_test`}
                  >
                    Sepsis
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/assays/meningitis`}
                  >
                    Meningitis
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_SEEGENE_URL}/assays/others`}
                  >
                    Others
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}`}>Advantages</a>

              <ul className="depth3">
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/advantages/wide_range_of_products`}
                  >
                    Product pipeline
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/advantages/benefit_of_full_panel_assays`}
                  >
                    Benefit of full panel assays
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/advantages/high_specificity_n_sensitivity`}
                  >
                    High specificity &amp; sensitivity
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/advantages/beyond_the_current_hpv_diagnosis`}
                  >
                    Beyond the current HPV diagnosis
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/advantages/complete_solution_for_the_covid_19_response`}
                  >
                    More advanced solution for COVID-19 and variants
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/eDoc">Seegene eDoc</Link>

              <ul className="depth3">
                <li>
                  <Link to="/file/manual">IFU</Link>
                </li>
                <li>
                  <Link to="/file/msds">MSDS</Link>
                </li>
                <li>
                  <Link to="/file/coa">COA</Link>
                </li>
                <li>
                  <Link to="/file/standard-brochure">SPB</Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="depth2">
            <li className="topmenu">
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/system`}>
                Systems
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}`}>Platform</a>

              <ul className="depth3">
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/platform/all_in_one`}
                  >
                    All MDx Assays in One Platform
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/platform/universal_extraction_system`}
                  >
                    Universal extraction system
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/platform/flexible_pcr_setup_solution`}
                  >
                    Flexible PCR Setup Solution
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/platform/sample_information_tracking`}
                  >
                    Sample information tracking
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/platform/actual_case_of_space_use`}
                  >
                    Actual case of space use
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/platform/one_day_report_system`}
                  >
                    One day report system
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}`}>Instruments</a>

              <ul className="depth3">
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/instruments/seeamp`}
                  >
                    SEEAMP™
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/instruments/vcms`}
                  >
                    VCMS
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/instruments/seeprep32`}
                  >
                    SEEPREP32™
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/instruments/seegene_nimbus`}
                  >
                    NIMBUS IVD
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/instruments/seegene_starlet`}
                  >
                    STARlet IVD
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/instruments/cfx96_dx`}
                  >
                    CFX96™ Dx
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/instruments/seegene_starlet_aios`}
                  >
                    Seegene STARlet-AIOS
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}`}>Software</a>

              <ul className="depth3">
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/software/seegene_launcher`}
                  >
                    Seegene Launcher
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/software/seegene_viewer`}
                  >
                    Seegene Viewer
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/software/sg_stats`}
                  >
                    SG STATS
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}`}>Technologies</a>

              <ul className="depth3">
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/technologies/mudt`}
                  >
                    MUDT™
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/technologies/toce`}
                  >
                    TOCE™
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/technologies/dpo`}
                  >
                    DPO™
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/technologies/read`}
                  >
                    READ™
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/technologies/acp`}
                  >
                    ACP™
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="depth2">
            <li className="topmenu">
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/news_n_media`}>
                News &amp; Media
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/press_release`}>
                Press Release
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/our_stories`}>
                Our Stories
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/product_news`}>
                Product News
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/conference`}>
                Conferences &amp; Exhibitions
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/video_library`}>
                Video Library
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}`}>
                Investors Relations
              </a>

              <ul className="depth3">
                <li>
                  <a
                    href={`${
                      process.env.REACT_APP_SEEGENE_URL
                    }/financial_statement`}
                  >
                    Financial Statement
                  </a>
                </li>
                <li>
                  <a href={`${process.env.REACT_APP_SEEGENE_URL}/ir_event`}>
                    IR Events &amp; Presentations
                  </a>
                </li>
                <li>
                  <a href={`${process.env.REACT_APP_SEEGENE_URL}/ir_contactus`}>
                    IR Contact Us
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="depth2">
            <li className="topmenu">
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/about_us`}>
                About Us
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/company`}>
                Company
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/facilities`}>
                Facilities
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/rnd_partnership`}>
                R&amp;D Partnership
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/our_history`}>
                Our History
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/global_networks`}>
                Global Networks
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/contact_us`}>
                Contact Us
              </a>
            </li>
          </ul>
          <ul className="depth2 esg-depth">
            <li className="topmenu">
              <a
                href={`${
                  process.env.REACT_APP_SEEGENE_URL
                }/esg/declaration_management`}
              >
                ESG
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_SEEGENE_URL
                }/esg/declaration_management`}
              >
                ESG
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_SEEGENE_URL
                }/esg/policy_environmental_management`}
              >
                Environmental
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_SEEGENE_URL
                }/esg/rights_management_policy`}
              >
                Social
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_SEEGENE_URL
                }/esg/company_regulations`}
              >
                Governance
              </a>
            </li>
          </ul>
          <ul className="depth2 in-life-depth">
            <li className="topmenu">
              <a href={`${process.env.REACT_APP_SEEGENE_URL}/in_life_pcr`}>
                In-Life PCR
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_SEEGENE_URL
                }/in_life_pcr/for_partners`}
              >
                For Partners
              </a>
            </li>
            <li>
              <a
                href={`${
                  process.env.REACT_APP_SEEGENE_URL
                }/in_life_pcr/for_the_public`}
              >
                For the Public
              </a>
            </li>
          </ul>
        </div>
        <ul className="sns" style={{ marginBottom: 3 }}>
          <li className="youtube">
            <a target="_blank" href="https://www.youtube.com/c/Seegene/videos">
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/images/icon/menu-sns-youtube.png`}
                alt="in"
              />
            </a>
          </li>
          <li
            className="twitter"
            style={{
              marginLeft: 13,
              marginRight: 13,
            }}
          >
            <a target="_blank" href="https://twitter.com/Seegene_HQ">
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/images/icon/menu-sns-twitter.png`}
                alt="in"
              />
            </a>
          </li>
          <li className="in">
            <a
              target="_blank"
              href="https://kr.linkedin.com/company/seegene-inc"
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/icon/menu-sns-in.png`}
                alt="in"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
