import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export default function OnlineTraining(props) {
  const classes = useStyles();

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">01</Typography>
          <Typography className="tit">About Online Training</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Through our online training, you can watch videos of training
            programs that we held for distributors invited from overseas.
            Through these programs, the overseas distributors learned about the
            theories and practices of our technologies, products, instruments,
            and software. You can also watch videos that can be used for
            promotional or sales purposes. These videos are neither downloadable
            nor viewable via mobile devices.
            <br />
            The videos are classified by category or recommended theme and have
            been created to give you a better understanding of our technologies,
            products, instruments, and software and learn best practices in
            customer relations.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">02</Typography>
          <Typography className="tit">Who the Videos Benefit Most</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>
                1. Distributors who are unable to attend offline training
                provided by the head office
              </li>
              <li>
                2. Distributors who need to repeat training or need to provide
                cascade training for others
              </li>
              <li>
                3. New distributors or new hires who need to acquire general
                knowledge of Seegene
              </li>
              <li>
                4. Those who want to use videos for their promotional activities
              </li>
              <li>
                5. Those who need to provide basic instrument or IVD reagent
                support for customers
              </li>
              <li>
                6. Those who need information on new products or instruments
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">03</Typography>
          <Typography className="tit">Length of Videos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The videos range anywhere from one minute to 150 minutes in length.
            Videos longer than 10 minutes may be divided into five- or 10-minute
            segments that can be watched separately. In addition, you can search
            for both segmented and full-length videos. After searching for and
            playing a segmented video, the full-length version of the video will
            be played automatically.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">04</Typography>
          <Typography className="tit">Types of Videos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Videos are classified by channel (theory, practice, seminar,
            symposium, interview, troubleshooting, promotion, and commercial)
            and category (products, instruments, technology, and software).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">05</Typography>
          <Typography className="tit">Additional Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We film the training programs held by the head office for
            distributors invited from overseas. These programs include
            confidential information and examples created only for training
            purposes. The videos are not downloadable and can be viewed only in
            locations and by people specified in the terms and conditions of
            use. Any unauthorized disclosure or leaks of information contained
            in these videos shall be subject to legal punishment.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">06</Typography>
          <Typography className="tit">
            Email Authentication for Watching Videos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To control access to the videos, members with the proper permission
            are required to complete an authentication process before watching
            the videos. For this authentication, a code will be sent to the
            authorized member by email. An individual authentication code is
            required for each video. If the computer you are using has not been
            designated as a computer dedicated to online training, you will have
            to complete the authentication procedure every time you play a
            video.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">07</Typography>
          <Typography className="tit">
            Designation of a Computer Dedicated to Online Training
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            It may be inconvenient to have to complete the authentication
            procedure every time you play a video, especially if you intend to
            watch several videos consecutively. To avoid this inconvenience, the
            website provides a means of designating a computer dedicated to
            online training. By so designating the computer and web browser that
            you use most frequently, you can watch as many videos as you want,
            through the browser and on the computer, without going through the
            authentication procedure for each video. However, only members who
            agree to the terms and conditions provided during the initial
            authentication procedure will be able to designate a dedicated
            computer. If you want to change your designated PC, you will need to
            contact your RM for permission.
          </Typography>
          <Typography>
            ※To designate a PC, cookies must be enabled in the browser.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">08</Typography>
          <Typography className="tit">
            Time Limit for Watching Videos After Completing the Authentication
            Process
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you receive authentication via email, the authentication remains
            valid for only 24 hours. If you try to watch the same video again on
            a different computer or after the 24-hour period has passed (unless
            you are using a designated computer), you will have to complete the
            authentication process again.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">09</Typography>
          <Typography className="tit">
            Limitations Due to Operating System and/or Web Browser
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            All videos are provided in streaming video format and can be viewed
            through different browsers. At first, however, you will have to
            install a video player. The speed at which the videos stream may
            vary depending on the performance of your computer and the operating
            system and browser you use. Our research shows that the video player
            performs very well on computers with Windows 7 and iOS 8 and above
            and Microsoft Explorer 10 or Chrome 50 and above. Please note that
            the videos cannot be viewed on mobile devices.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">10</Typography>
          <Typography className="tit">How to Search for Videos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can search for videos in three different ways.
            <ul>
              <li>
                <span className="Accordion-tit">1. By category</span>
                Videos can be searched for by category, allowing you find the
                videos you want easily and quickly.
              </li>
              <li>
                <span className="Accordion-tit">2. By theme</span>
                We recommend videos by theme. Depending on the purpose of the
                training, you can search for training videos for new hires,
                customer support videos, or videos for seminars or promotions.
              </li>
              <li>
                <span className="Accordion-tit">
                  3. By keyword (to be available in 2018)
                </span>
                You can also search for videos by typing in a keyword. Since the
                use of proper keywords makes it much easier to find the videos
                you want, we encourage you to use the recommended keywords.
              </li>
            </ul>
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              Page for Detailed View (Available Soon)
            </span>
            This page provides a screen for viewing the selected video and a
            list of related videos. This list helps you find the full-length
            versions of any video segments you watch. At the bottom of the page
            is a list of videos by category or theme, offering a convenient way
            to search for other videos.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
