import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Terms from "./utility/Terms";
import Privacy from "./utility/Privacy";

const useStyles = makeStyles((theme) => ({
  line: {
    display: "block",
    width: "100%",
    height: "1px",
    background: "#eee",
  },
  mainContent: {
    padding: "100px 100px 0 100px",
  },
  mainTitle: {
    padding: "0 0 40px 0",
    "& h2": {
      "font-size": "24px",
      "font-weight": "700 !important",
    },
  },
  copyright: {
    "margin-top": "16px",
    "font-size" : "13px",
  },
  footerinfo: {
    color: "#9c9c9c",
    "margin-bottom": "16px",
    "font-size" : "13px",
  },
  dialog: {
    "& .MuiDialog-container": {
      "justify-content": "flex-end",
      "align-items": "stretch",
      "& .MuiDialog-paper": {
        "max-height": "none",
        minWidth: "1150px",
        margin: "0",
        "border-radius": "0",
      },
    },
  },
  footerLeft: {
    "max-width": "45%",
    "flex-basis": "45%",
  },
  content: {
    padding: "0 100px 100px 100px ",
    position: "relative",
    overflowY: "overlay",
    "& .MuiDialogContent-root:first-child": {
      "padding-top": "40px",
    },
    "&::-webkit-scrollbar": {
      width: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      "border-radius": "9999px",
      "box-shadow": "inset 0 0 10px 10px rgba(0,0,0,0.1)",
      border: "solid 5px transparent",
    },
  },
  close: {
    "background-image": `url(${
      process.env.PUBLIC_URL
    }/images/icon/icon-list-close.svg)`,
    "background-repeat": "no-repeat",
    "background-position": "center",
    width: "30px",
    "min-width": "0",
    height: "30px",
    position: "fixed",
    top: "30px",
    right: "30px",
    "margin-right": "20px",
  },
  lang: {
    position: "absolute",
    top: "100px",
    right: "100px",
    padding: "0",
  },
  scrollTop: {
    position: "absolute",
    top: 18,
    right: 100,
  },
}));

function EdocFooter() {
  const classes = useStyles();
  const [policy, setPolicy] = useState(false);
  const [terms, setTerms] = useState(false);

  const [conduct, setConduct] = useState(false);
  const [lang, setLang] = useState("en");

  const [ScrollY, setScrollY] = useState(0);

  const openPolicy = () => {
    setPolicy(true);
  };
  const closePolicy = () => {
    setPolicy(false);
  };

  const openTerms = () => {
    setTerms(true);
  };
  const closeTerms = () => {
    setTerms(false);
  };

  const handleFollow = () => {
    setScrollY(window.pageYOffset);
  };
  const ScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setScrollY(0);
  };
  useEffect(() => {
    const watch = () => {
      window.addEventListener("scroll", handleFollow);
    };
    watch();
    return () => {
      window.removeEventListener("scroll", handleFollow);
    };
  });
  useEffect(() => {}, [ScrollY]);


  return (
    <div id="footer">
      {/*<Grid item xs={12}>*/}
      {/*  <Grid>*/}
      {/*    <p className={classes.copyright}>*/}
      {/*      Copyright© Seegene.Inc. All rights reserved.*/}
      {/*    </p>*/}
      {/*    <span className={classes.footerinfo}>*/}
      {/*      Information contained in this website is for a wide range of audiences and may contain product details or information not accessible or valid in your country. Please note that we do not take any responsibility for accessing information that does not comply with any legal process, regulation, registration or usage in your country.*/}
      {/*    </span>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}

      <Grid item xs={12}>
        <Grid
            xs={4}>
          {/*<Button>*/}
            {/*<img*/}
            {/*    src={`${*/}
            {/*        process.env.PUBLIC_URL*/}
            {/*    }/images/icon/logo-footer-seegene-new.svg`}*/}
            {/*    alt="logo"*/}
            {/*/>*/}
          {/*</Button>*/}
          <ul>
            <Button onClick={openTerms}>
              <li>Terms and Conditions</li>
            </Button>
            <Button onClick={openPolicy}>
              <li>Privacy Policy</li>
            </Button>
            {/*<Button onClick={openPolicy}>*/}
            {/*  <li>Privacy Policy</li>*/}
            {/*</Button>*/}
            <Button
                href="https://www.seegene.com/contact_us"
                target="_blank"
                className={classes.footerLink}
            >
              <p>Contact</p>
            </Button>
          </ul>
        </Grid>
        <Grid>
          <span className={classes.footerinfo}>
            Information contained in this website is for a wide range of
            audiences and may contain product details or information not accessible or valid in your country. Please note that we do not take any responsibility for accessing information that does not comply with any legal
            process, regulation, registration or usage in your country.
          </span>
          <br />
          <p className={classes.copyright}>
            Copyright© Seegene.Inc. All rights reserved.
          </p>
        </Grid>
      </Grid>
      <Dialog
          className={classes.dialog}
          open={terms}
          onClose={closeTerms}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <div className={classes.mainContent}>
          <DialogTitle id="alert-dialog-title" className={classes.mainTitle}>
            Terms and Conditions
          </DialogTitle>
          <span className={classes.line} />
          <Button onClick={closeTerms} className={classes.close} />
        </div>
        <DialogContent className={classes.content}>
          <Terms open={terms} />
        </DialogContent>
      </Dialog>

      <Dialog
          className={classes.dialog}
          open={policy}
          onClose={closePolicy}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <div className={classes.mainContent}>
          <DialogTitle id="alert-dialog-title" className={classes.mainTitle}>
            Privacy Policy
          </DialogTitle>
          <span className={classes.line} />
          <Button onClick={closePolicy} className={classes.close} />
        </div>
        <DialogContent className={classes.content}>
          <Privacy open={policy} />
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default EdocFooter;
