import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Grid,
  Input,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  Popover,
  Toolbar,
} from "@material-ui/core";
import react, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import useTranslation from "components/hook/use-translation";
import { useSelector } from "react-redux";
import translateJSON from "assets/translation/translate.json";

const useStyles = makeStyles((theme) => ({
  popover: {
    "& .content": {
      padding: 20,
    },
  },
}));

const path = [
  "/file/manual",
  "/file/msds",
  "/file/coa",
  "/file/doc",
  "/file/standard-brochure",
];
const language = [
  "en",
  // "ko",
  "de",
  "da",
  "fi",
  "sv",
  "fr",
  "el",
  "it",
  "nl",
  "pt",
  "es",
  "cs",
  "et",
  "hu",
  "lv",
  "lt",
  "sk",
  "bg",
  "ro",
  "hr",
  "tr",
  "pt-br",
  "es-xl",
  "fr-ca",
];

const getUserLanguage = (user) => {
  let lang = "";
  if (user.tp !== "3") lang = "en";
  else {
    lang =
      language.findIndex((l) => user.language === l) !== -1
        ? user.language
        : "en";
  }
  // console.log("lang", lang);
  return lang;
};

export default function TranslatePopup(props) {
  const { userInfo } = useSelector((state) => state.user);
  const classes = useStyles();
  const location = useLocation();
  const [cookie, setCookie] = useCookies(["globalLang"]);
  const userLanguage = getUserLanguage(userInfo);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  //   const [anchorEl, setAnchorEl] = useState(null);

  const handleClosePopover = () => {
    // setAnchorEl(null);
    setIsOpenPopover(false);
  };

  useEffect(() => {
    if (!cookie.globalLang) {
      setCookie("globalLang", "en");
      return;
    }

    // console.log(cookie.globalLang, userLanguage);
    if (
      cookie.globalLang !== userLanguage &&
      path.findIndex((p) => location.pathname === p) !== -1
    ) {
      setIsOpenPopover(true);
    }
  }, [location]);

  return (
    <Popover
      className={classes.popover}
      open={isOpenPopover}
      //   anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <div className="content">
        <p>
          {translateJSON[userLanguage]["translatePopupMessage-" + userLanguage]}
        </p>
      </div>
      <div className="popover-bottom">
        <Button
          onClick={() => {
            handleClosePopover();
          }}
        >
          {translateJSON[userLanguage].No}
        </Button>
        <span className="line" />
        <Button
          onClick={() => {
            setCookie("globalLang", userLanguage);
            handleClosePopover();
          }}
        >
          {translateJSON[userLanguage].Yes}
        </Button>
      </div>
    </Popover>
  );
}
