import { withStyles } from "@material-ui/core";
import { Cookies } from "react-cookie";
import { lightTheme } from "Theme";

let cookies = new Cookies();
const theme = cookies.get("isLight") === "true" ? lightTheme : lightTheme;

const palette =
  cookies.get("isLight") === "true" ? lightTheme.palette : lightTheme.palette;

const fontsize = "12px !important";
const tableHeight = "36px !important";
const tableLineHeight = "35px !important";

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.

  "@global": {
    // "body *": {
    //   wordBreak: "break-word !important",
    // },
    /**************************************************************************************************
    common etc
    **************************************************************************************************/
    ".hidden": {
      clear: "both",
      position: "absolute",
      top: "auto",
      left: 0,
      overflow: "hidden",
      width: 1,
      height: 1,
      padding: 0,
      margin: 0,
      fontSize: 0,
      lineHeight: 0,

      "&:after": {
        display: "none",
      },
    },

    /**************************************************************************************************
    color
    **************************************************************************************************/
    body: {
      "background-color": palette.background.main,
      color: palette.text.main,
      "& svg": {
        color: `${palette.text.main}`,
      },
    },

    ".color-black": {
      color: `${palette.text.black} !important`,
    },

    ".MuiCard-root": {
      overflow: "visible",
    },

    ".App-Page": {
      padding: "60px 100px 0px",
    },
    ".App-Page-gray": {
      margin: "0 auto",
      background: "#F3F4F6",
      height: "auto",
    },

    /**************************************************************************************************
    header
    **************************************************************************************************/
    ".header": {
      position: "absolute",
      "min-width": "100%",
      "&.MuiAppBar-root": {
        zIndex: "1200",
      },
    },
    ".header-link-page": {
      position: "relative",
      width: "100%",
      minWidth: "1366px",
      left: "0",
      height: "40px",
      background: "#f05044",
      padding: "0 100px",
      display: "flex",
      "align-items": "center",
      color: palette.text.white,
    },
    ".header-search-box": {
      boxSizing: "content-box",
      position: "relative",
      width: "100%",
      minWidth: "1366px",
      top: "107px",
      left: "0",
      display: "flex",
      flexDirection: "column",
      "align-items": "center",
      justifyContent: "center",
      color: palette.text.white,
    },
    ".MuiToolbar-regular": {
      padding: "53px 100px 0 100px",
      "justify-content": "space-between",
      "align-items": "flex-start",
      position: "relative",
      transition: "425ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      height: "110px",
      minWidth: "1366px",
      background: palette.background.white,
      "& .MuiButton-text": {
        padding: "0 20px 0 0",
      },
      "&.active": {
        height: "430px",
        transition: "425ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        background: palette.background.white,
        "border-radius": "0 0 16px 16px",
        "& .header-menu-ul .header-menulist-ul": {
          height: "auto",
          opacity: "1",
          transition: "opacity 0.8s",
        },
      },
      "& .header-menu-ul": {
        display: "flex",

        "& > li": {
          position: "relative",
          display: "inline-flex",
          "flex-grow": "1",
          paddingRight: 10,
          "&:last-child": {
            "padding-right": "0",
            "padding-bottom": "0",
          },
          "&.header-menu-list:first-child li:last-child": {
            "padding-bottom": "160px",
          },
          "&.header-menu-list:nth-child(2) li:last-child": {
            "padding-bottom": "80px",
          },
          "&.header-menu-list:nth-child(3) li:last-child": {
            "padding-bottom": "120px",
          },
          "&.header-menu-list:nth-child(4) li:last-child": {
            "padding-bottom": "60px",
          },
          "&.header-menu-list:nth-child(5) li:last-child": {
            "padding-bottom": "200px",
          },
          "&.header-menu-list:last-child li:last-child": {
            "padding-bottom": "250px",
          },
        },
      },
      "& .header-menulist-ul": {
        position: "absolute",
        top: "20px",
        left: "0",
        width: "100%",
        height: "0",
        overflow: "hidden",
        "text-align": "initial",
        "z-index": "10",
        opacity: 0,
        paddingRight: "10px",
        "& a": {
          "font-size": "14px",
          "font-weight": "400",
          lineBreak: "anywhere",
          "&:hover": {
            color: palette.primary.main,
          },
        },
        "& li": {
          paddingBottom: 20,
        },
        "& li:first-child": {
          paddingTop: 40,
        },
      },
      "& li:last-child": {
        "padding-bottom": "100px",
      },
      "& a": {
        position: "relative",
        display: "block",
        "font-size": "18px",
        color: "#000",
        "font-weight": "600",
      },
    },

    ".menu-background": {
      background: "rgba(0, 0, 0, 0.5)",
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "200%",
      "z-index": "10",
      transition: "425ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },

    /**************************************************************************************************
    button
    **************************************************************************************************/
    ".MuiButton-root": {
      height: "40px",
      "line-height": "16px",
      "font-size": "14px",
      padding: "0 20px",
      "text-transform": "none",
      "font-weight": "500",
      "&+.MuiButton-root": {
        "margin-left": "10px",
      },
    },
    // ".MuiIconButton-root": {
    //   width: "30px",
    //   height: "30px",
    //   padding: "0 !important",
    // },

    ".MuiButton-sizeSmall": {
      height: "34px",
      "line-height": "33px",
    },
    ".MuiButton-sizeLarge": {
      height: "80px",
      "line-height": "80px",
      borderRadius: 8,
      fontSize: 18,
    },

    ".MuiButton-outlinedPrimary": {
      "border-color": palette.primary.main,
    },

    ".MuiButton-outlinedSecondary": {
      "border-color": palette.secondary.main,
    },

    ".MuiButton-outlinedInfo": {
      "border-color": palette.info.main,
      color: palette.info.main,
      "&:hover": {
        "background-color": palette.info.outlinedHover,
      },
    },

    ".MuiButton-containedInfo": {
      "background-color": palette.info.main,
      color: palette.text.white,

      "&:hover": {
        "background-color": palette.info.containedHover,
      },
    },

    ".MuiButton-outlinedMuted": {
      "border-color": palette.muted.main,
      color: palette.text.sub.gray01,
    },

    ".MuiButton-containedMuted": {
      "background-color": palette.muted.main,
      color: palette.text.white,
    },

    ".MuiButton-outlinedDefault": {
      "border-color": palette.muted.main,
      color: palette.text.black,
    },
    ".MuiButton-outlinedSub": {
      "border-color": palette.secondary.sub,
      color: palette.secondary.sub,
    },
    ".MuiButton-containedSub": {
      "border-color": palette.secondary.sub,
      background: palette.secondary.sub,
      color: palette.text.white,
      "&:hover": {
        background: palette.secondary.sub,
      },
    },
    ".btn-fixed": {
      padding: 0,
      width: "90px !important",
    },

    ".btn-link": {
      "& +.btn-link": {
        "margin-left": "10px",
      },
    },
    ".btn-icon": {
      "min-width": "initial",
      width: "auto",
      height: "auto",
    },
    ".btn-icon-clip": {
      position: "relative",
      "padding-left": "17px",

      "&:after": {
        content: "''",
        display: "block",
        width: "11px",
        height: "14px",
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        "background-image": `url(${
          process.env.PUBLIC_URL
        }/images/icon/icon-clip-orange.svg)`,
        "background-size": "100%",
      },
    },

    ".MuiButton-startIcon ": {
      "& .MuiIcon-root": {
        position: "relative",
        "& img": {
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
        },
      },
    },

    ".MuiButton-endIcon ": {
      "& .MuiIcon-root": {
        position: "relative",
        "& img": {
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
        },
      },
    },

    ".MuiButton-downloadIcon": {
      "min-width": "95px",
      "& .MuiButton-startIcon": {
        "margin-right": "10px",
      },
    },

    ".MuiButton-rounded": {
      height: "62px",
      padding: "0 45px",
      "font-size": "18px",
      "border-radius": "1000px",
    },
    ".MuiButton-text": {
      padding: 0,
      "&:hover": {
        "background-color": "transparent",
      },

      "& .MuiButton-startIcon": {
        "margin-right": "-8px",
      },

      "& .MuiButton-endIcon": {
        "margin-left": "-8px",
      },
    },

    /********************************************************************************************************************************************
    form
    **************************************************************************************************/
    // radio,checkbox
    ".MuiFormGroup-root": {
      "&+.MuiFormHelperText-root": {
        "margin-top": "-5px",
      },
      "& .MuiCollapse-wrapperInner": {
        padding: "0 10px",
      },
    },
    ".MuiFormControlLabel-root": {
      marginLeft: 0,

      "& span": {
        "font-size": "14px",
      },
      "& .MuiIconButton-root": {
        width: "auto",
        height: "auto",
      },
      "& .MuiFormControlLabel-label": {
        marginLeft: 10,
        lineHeight: "20px",
      },

      // "& + .MuiFormControlLabel-root": {
      //   marginTop: 22,
      // },
    },
    ".radio-icon": {
      display: "block",
      width: "20px",
      height: "20px",
      border: `solid 1px ${palette.line.gray01}`,
      "border-radius": "50%",
    },

    ".radio-icon-checked": {
      position: "relative",
      display: "block",
      width: "20px",
      height: "20px",
      border: `solid 1px ${palette.primary.main}`,
      "border-radius": "50%",
      "background-color": palette.primary.main,
      "&:before": {
        content: '""',
        display: "block",
        width: "8px",
        height: "8px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        "background-color": palette.background.white,
        "border-radius": "50%",
      },
    },

    ".checkbox-icon": {
      display: "block",
      width: "20px",
      height: "20px",
      background: palette.background.white,
      border: `solid 1px ${palette.line.gray01}`,
      "border-radius": "4px",
    },

    ".checkbox-icon-checked": {
      position: "relative",
      display: "block",
      width: "20px",
      height: "20px",
      border: `solid 1px ${palette.primary.main}`,
      "border-radius": "4px",
      "background-color": palette.primary.main,

      "&:before": {
        content: '""',
        display: "block",
        width: "12px",
        height: "8.5px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        "background-image": `url(${
          process.env.PUBLIC_URL
        }/images/icon/icon-check.svg)`,
        "background-repeat": "no-repeat",
        "background-size": "100% auto",
      },
    },
    ".Mui-disabled": {
      "& .radio-icon": {
        "background-color": palette.background.gray02,
      },

      "& .radio-icon-checked": {
        "background-color": palette.background.gray01,
        "border-color": palette.line.gray01,
      },

      "& .checkbox-icon": {
        "background-color": palette.background.gray02,
        "border-color": palette.line.gray01,
      },

      "& .checkbox-icon-checked": {
        "background-color": palette.background.gray01,
        "border-color": palette.line.gray01,
      },
    },

    /**************************************************************************************************
    input
    **************************************************************************************************/
    "input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
    },
    "input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    "input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 30px white inset !important",
    },
    "input:-webkit-autofill:hover": {
      "-webkit-box-shadow": "0 0 0 30px white inset !important",
    },
    "input:-webkit-autofill:focus": {
      "-webkit-box-shadow": "0 0 0 30px white inset !important",
    },
    "input:-webkit-autofill:active": {
      "-webkit-box-shadow": "0 0 0 30px white inset !important",
    },
    ".MuiInputBase-root + .MuiInputBase-root": {
      "margin-left": "10px",
    },

    ".MuiInputBase-root + .custom-datepicker": {
      "margin-left": "10px",
    },

    ".input-form+.input-form": {
      "margin-top": "10px",
    },

    ".form-table": {
      width: "100%",

      "& +Button": {
        "margin-top": "10px",
      },

      "& tr": {
        "& +tr": {
          "margin-top": "10px",
        },
        display: "flex",

        "& th": {
          "text-align": "left",
          flex: "0 0 9%",
          "padding-right": "10px",
        },

        "& td": {
          flex: "1 1 100%",
          width: "100%",

          "& +th": {
            "margin-left": "50px",
          },
        },
      },
      "& label": {
        display: "inline-block",
        "font-size": "14px",
        color: palette.text.black,
        "vertical-align": "bottom",
        "word-break": "break-all",
      },
    },

    ".MuiInputLabel-shrink": {
      position: "relative",
      transform: "none",
    },

    ".valid-confirm": {
      "padding-right": "35px !important",
      background: `url(${
        process.env.PUBLIC_URL
      }/images/icon/icon-check-secondary.svg) no-repeat center right 13px`,
    },
    ".MuiInputBase-input": {
      height: "38px",
      // "line-height": "38px",
      // padding: "0 10px",
      padding: "0 10px",
      "font-size": "14px",
      border: `solid 1px ${palette.line.gray01}`,
      background: palette.background.white,
      "border-radius": "4px !important",
      "&::placeholder": {
        color: palette.text.sub.gray01,
        opacity: "1 !important",
      },
    },
    ".valid-confirm": {
      "padding-right": "35px !important",
      background: `url(${
        process.env.PUBLIC_URL
      }/images/icon/icon-check-secondary.svg) no-repeat center right 13px !important`,
    },
    ".MuiInputBase-input.Mui-disabled": {
      "background-color": palette.background.gray02,
      color: palette.text.sub.gray01,
    },

    ".MuiFormControl-wrap": {
      "flex-direction": "row",
      "align-items": "flex-start",
    },

    ".MuiFormControl-root": {
      "& +.MuiFormControl-wrap": {
        "margin-top": "10px",
      },

      "& +Button": {
        "margin-top": "10px",
      },

      "& .MuiBox-root": {
        "padding-right": "15px",

        "& +.MuiBox-root": {
          padding: 0,
          flex: "1",
          width: "100%",
        },
      },

      "& label": {
        // display: "inline-block",
        display: "inline-flex",
        color: palette.text.black,
        "font-size": "12px",
        "vertical-align": "bottom",
        "word-break": "break-all",
      },

      "& .MuiInputLabel-formControl": {
        position: "relative",
        transform: "none",
      },

      "& .MuiInputBase-input": {
        height: "38px",
        // padding: "0 10px",
        padding: "0 10px",
        "font-size": "14px",
        border: `solid 1px ${palette.line.gray01}`,
        "border-radius": "4px",
      },

      "& .valid-confirm": {
        "padding-right": "35px !important",
        background: `url(${
          process.env.PUBLIC_URL
        }/images/icon/icon-check-secondary.svg) no-repeat center right 13px`,
      },

      "& .MuiInput-input-rounded": {
        height: "62px",
        "border-radius": "50px !important",
        "background-color": "rgba(255,255,255,0.5)",
        padding: "0 35px !important",
        border: "none !important",
        "font-size": "18px !important",
      },
    },

    ".MuiFormHelperText-root": {
      color: palette.primary.main,
    },

    ".Mui-required": {
      position: "relative",

      "&:after": {
        content: '""',
        display: "block",
        width: "4px",
        height: "4px",
        position: "absolute",
        right: "-8px",
        top: 0,
        background: `url(${
          process.env.PUBLIC_URL
        }/images/icon/red-dot.svg) no-repeat`,
        "background-size": "100% auto",
      },
    },

    ".MuiFormLabel-asterisk": {
      display: "none",
    },

    ".border-primary": {
      "border-color": `${palette.primary.main} !important`,
    },

    // textarea
    ".MuiInputBase-multiline": {
      padding: 0,

      "& .MuiInputBase-inputMultiline": {
        padding: "20px !important",
      },
    },

    /**************************************************************************************************
    selectbox
    **************************************************************************************************/
    ".select-placeholder": {
      color: palette.text.sub.gray01,
    },

    ".MuiSelect-icon": {
      display: "none",
    },

    ".MuiSelect-root": {
      position: "relative",
      "line-height": "40px",
      "padding-right": "40px !important",
      "word-break": "break-all",
      maxWidth: "250px",

      "&:focus": {
        "background-color": palette.background.white,
      },

      "&:after": {
        content: '""',
        display: "block",
        width: "16px",
        height: "8px",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "15px",
        background: `url(${
          process.env.PUBLIC_URL
        }/images/icon/icon-select-arrow.svg) no-repeat`,
        "background-size": "100% auto",
      },
    },

    ".MuiSelect-root[aria-expanded='true']": {
      "background-color": palette.primary.main,
      "border-color": palette.primary.main,
      color: palette.text.white,

      "&:after": {
        background: `url(${
          process.env.PUBLIC_URL
        }/images/icon/icon-select-arrow-active.svg) no-repeat`,
      },
    },

    ".MuiSelect-root[aria-disabled='true']": {
      "&:after": {
        background: `url(${
          process.env.PUBLIC_URL
        }/images/icon/icon-select-arrow-disabled.svg) no-repeat`,
      },
    },

    ".MuiMenu-paper": {
      overflow: "overlay",
      "overflow-x": "hidden",
      padding: "15px 0",
      "max-height": "335px",
      "border-radius": "16px",

      // scrollbar 스타일
      "&::-webkit-scrollbar": {
        width: "16px",
      },

      "&::-webkit-scrollbar-thumb": {
        "border-radius": "9999px",
        "box-shadow": "inset 0 0 10px 10px rgba(0,0,0,0.1)",
        border: "solid 5px transparent",
      },

      "& .MuiMenu-list": {
        padding: "0",
        "& li": {
          height: "40px",
          "padding-left": "30px",
          "padding-right": "30px",
          fontSize: "14px",
          "&:hover,:focus,:active": {
            "background-color": palette.primary.translucent,
          },
        },
        "& li.Mui-selected": {
          "background-color": palette.primary.translucent,
          color: palette.primary.main,
        },
      },
    },

    /**************************************************************************************************
    datepicker(theming을 위해 globalStyles에 작성)
    **************************************************************************************************/
    ".custom-datepicker": {
      width: "200px !important",
    },

    ".datepicker-group": {
      "& >div + div": {
        "margin-left": "10px",
      },
      display: "flex",
      "flex-direction": "row",
      "justify-content": "flex-start",

      "& .DatePicker": {
        // flex: "1 1 50%",

        "& + .DatePicker": {
          "margin-left": "10px",
        },
      },

      "& +Button": {
        "margin-top": "10px",
      },
    },

    ".DatePicker": {
      width: "100%",
      "z-index": "initial",

      "&__input": {
        // width: "200px",
        width: "100%",
        position: "relative",
        padding: "0 40px 0 10px",
        height: "40px",
        "line-height": "40px",
        "font-size": "14px",
        "border-color": palette.line.gray01,
        "border-radius": "8px",
        "text-align": "left",
        "box-sizing": "border-box",
        "background-image": `url(${
          process.env.PUBLIC_URL
        }/images/icon/icon-calendar.svg)`,
        "background-repeat": "no-repeat",
        "background-size": "20px auto",
        "background-position": "center right 15px",

        "&:focus-visible": {
          outline: "none !important",
          color: palette.text.white,
          "background-color": palette.primary.main,
          "background-image": `url(${
            process.env.PUBLIC_URL
          }/images/icon/icon-calendar-active.svg)`,
          "border-color": palette.primary.main,

          "&::placeholder": {
            color: palette.text.white,
          },
        },
      },
      "& .DatePicker__calendarContainer": {
        "z-index": "99",
      },
      "& .Calendar": {
        "min-height": "inherit !important",
        height: "307px",
        "border-radius": "16px",
        "box-shadow":
          "0px 30px 52px rgba(0, 0, 0, 0.2), 0px 9.94853px 17.4853px rgba(0, 0, 0, 0.121367), 0px 4.13211px 7.2625px rgba(0, 0, 0, 0.0831038), 0px 1.4945px 2.6267px rgba(0, 0, 0, 0.0497377)",
      },

      "& .Calendar__header": {
        // position: "relative",
        // padding:'30px'
        padding: "20px 30px 30px 30px",

        "& .Calendar__monthYearContainer": {
          position: "relative",

          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            width: "24px",
            height: "24px",
            right: "-5px",
            top: "50%",
            transform: "translateY(-50%)",
            "background-image": `url(${
              process.env.PUBLIC_URL
            }/images/icon/icon-calendar-black.svg)`,
            "background-repeat": "no-repeat",
            "background-position": "center center",
            "background-size": "13px 13px",
            "border-radius": "50%",
            "z-index": "-1",
          },
        },

        "& .Calendar__monthYear": {
          "justify-content": "center",
          width: "100%",
          "text-align": "center",
        },

        "& .Calendar__monthText": {
          position: "inherit",
          margin: "0",
          padding: "0",
          "font-size": "16px",
          "font-weight": "500",
          transform: "none !important",
        },

        "& .Calendar__yearText": {
          position: "absolute",
          left: "0px",
          margin: 0,
          padding: 0,
          "font-size": "12px",
          "font-weight": "500",
          "vertical-align": "bottom",
          transform: "none !important",
        },

        "& .Calendar__monthArrowWrapper": {
          position: "absolute",
          "margin-top": "0 !important",

          "&.-right": {
            left: "85px",
            transform: "none",

            "& span": {
              width: "7px",
              height: "14px",
              "background-image": `url(${
                process.env.PUBLIC_URL
              }/images/icon/icon-arrow-left.svg)`,
            },
          },

          "&.-left": {
            right: "85px",
            transform: "none",
            "& span": {
              width: "7px",
              height: "14px",
              "background-image": `url(${
                process.env.PUBLIC_URL
              }/images/icon/icon-arrow-right.svg)`,
            },
          },
        },

        "& .-hidden": {
          "& +.Calendar__monthYearContainer": {
            "&:before": {
              "background-image": `url(${
                process.env.PUBLIC_URL
              }/images/icon/icon-calendar-active-sm.svg)`,
              "background-color": palette.primary.main,
            },
          },
        },
      },

      "& .Calendar__weekDays": {
        padding: "0 30px",

        "& .Calendar__weekDay": {
          padding: "0px",
          width: "20px",
          color: palette.text.black,
          "text-decoration": "none",
        },

        "& abbr[title=Sunday]": {
          color: palette.primary.main,
        },

        "& abbr[title=Saturday]": {
          color: palette.secondary.main,
        },
      },

      "& .Calendar__sectionWrapper": {
        "min-height": "inherit",
        height: "100%",
      },
      "& .Calendar__section": {
        padding: "0 20px 30px 20px",
      },
      "& .Calendar__weekRow": {
        "& .Calendar__day": {
          "&[aria-label*='Sunday']": {
            color: palette.primary.main,
          },

          "&[aria-label*='Saturday']": {
            color: palette.secondary.main,
          },

          "margin-bottom": "0 !important",
          "font-size": "12px",
          color: palette.text.black,

          "&:hover": {
            position: "relative",
            background: "none !important",
            color: `${palette.primary.main} !important`,

            "&:before": {
              content: '""',
              display: "block",
              width: "24px",
              height: "24px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              "border-radius": "50%",
              "background-color": palette.primary.translucent,
              "z-index": -1,
            },
          },

          "&.-selected": {
            position: "relative",
            background: "none !important",
            color: palette.text.white,

            "&:before": {
              content: '""',
              display: "block",
              width: "24px",
              height: "24px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              "border-radius": "50%",
              "background-color": palette.primary.main,
              "z-index": -1,
            },
          },
        },
      },

      "& .Calendar__monthSelector ": {
        "border-radius": "16px",
        "will-change": "inherit",

        "& .Calendar__monthSelectorItem": {
          height: "26px",

          "& .Calendar__monthSelectorItemText": {
            height: "100%",
            padding: "0 6px",
            "font-size": "14px",
            "border-radius": "4px !important",

            "&:hover": {
              color: palette.primary.main,
              "background-color": palette.primary.translucent,
            },
          },

          "&.-active button": {
            "background-color": `${palette.primary.main} !important`,
            "&:hover": {
              color: `${palette.text.white}`,
            },
          },
        },
      },

      "& .Calendar__yearSelectorAnimationWrapper": {
        "border-radius": "16px",
        "& .Calendar__yearSelectorItem": {
          height: "26px",

          "& .Calendar__yearSelectorText": {
            padding: "0 6px",
            height: "100%",
            "font-size": "14px",
            "border-radius": "4px !important",

            "&:hover": {
              color: palette.primary.main,
              "background-color": `${palette.primary.translucent} !important`,
            },
          },
        },

        "& .-active button": {
          "background-color": `${palette.primary.main} !important`,
          "&:hover": {
            color: `${palette.text.white}`,
          },
        },
      },

      "&__calendarArrow": {
        display: "none !important",
      },
    },

    /**************************************************************************************************
    Autocomplete
    **************************************************************************************************/
    ".MuiAutocomplete-root": {
      "& .MuiAutocomplete-inputRoot": {
        "& .MuiAutocomplete-input": {
          padding: 0,
          border: "none",
        },
        "& .MuiChip-root": {
          backgroundColor: palette.primary.main,
          borderColor: palette.primary.main,
          color: "#fff",
          "& .MuiChip-deleteIcon": {
            color: "#fff",
          },
        },
      },
    },

    /**************************************************************************************************
    File Upload
    **************************************************************************************************/
    ".form-file": {
      "& .file-upload": {
        display: "inline-block",
        marginLeft: 5,
        verticalAlign: "middle",
      },
      "& .file-text": {
        display: "inline-block",
        // width: "calc(100% - 85px)",
        // height: 40,
        // borderBottom: "1px solid #ccc",
        // lineHeight: "40px",
        verticalAlign: "middle",
        "& .MuiInputBase-input": {
          width: "100%",
        },
      },
    },

    /**************************************************************************************************
    tabs
    **************************************************************************************************/
    ".MuiTabs-root": {
      "& .MuiTabs-flexContainer": {
        "& .MuiTab-textColorPrimary": {
          color: palette.text.black,
          "font-weight": "600",
          "&.Mui-selected": {
            color: palette.primary.main,
          },
        },
        "& .MuiTab-root": {
          padding: "0",
          "min-width": "0",
          "font-size": "16px",
          "text-transform": "none",
          "&+.MuiTab-root": {
            "margin-left": "24px",
          },
        },
        "& + .MuiTabs-indicator": {
          // bottom: "5px",
          "border-radius": "10px",
          "background-color": palette.primary.main,
        },
      },
      "& .MuiTab-root": {
        padding: "0",
        "min-width": "0",
        "font-size": "16px",
        "text-transform": "none",
      },
    },
    ".MuiAppBar-colorPrimary": {
      "background-color": "transparent",
      color: palette.text.sub.gray01,
      "box-shadow": "none",
      "& .MuiTab-textColorInherit": {
        opacity: "1",
        "&+.MuiTab-root.MuiTab-textColorInherit": {
          "margin-left": "10px",
        },
        "& .MuiTab-wrapper": {
          border: `solid 1px ${palette.text.sub.gray01}`,
          padding: "8px 10px",
          "border-radius": "30px",
          "font-size": "12px",
          height: "30px",
        },
        "&.Mui-selected": {
          color: palette.primary.main,
          "& .MuiTab-wrapper": {
            border: `1px solid ${palette.primary.main} !important`,
            "box-shadow": `0px 3px 4px ${palette.primary.translucent}`,
          },
        },
      },
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },

    /**************************************************************************************************
    popover
    **************************************************************************************************/
    ".MuiPopover-paper": {
      "border-radius": "16px",
      "margin-top": "10px",
      "& .MuiListItem-root": {
        "padding-top": "10px",
        "padding-bottom": "10px",
        // "&:first-of-type": {
        //   "padding-top": "25px",
        // },
        // "&:last-child": {
        //   "padding-bottom": "25px",
        // },
      },
      "& .MuiTypography-displayBlock": {
        "font-size": "12px",
      },
    },

    /**************************************************************************************************
    table
    **************************************************************************************************/
    [theme.breakpoints.up("xl")]: {
      ".MuiGrid-grid-xl-5": {
        flexGrow: 0,
        maxWidth: "20%",
        flexBasis: "20%",
      },
    },
    ".MuiGrid-grid-xs-9": {
      position: "relative",
      "padding-left": "30px",
      "&:before": {
        content: "''",
        display: "block",
        width: "1px",
        height: "calc(100% - 30px)",
        position: "absolute",
        left: "0",
        top: "30px",
        background: palette.line.gray02,
      },
    },
    ".MuiGrid-grid-xs-3": {
      "& .MuiTableContainer-root": {
        "& .MuiTable-root": {
          "& .MuiTableBody-root": {
            "& .MuiTableRow-root": {
              "&.Mui-selected": {
                "& .MuiTableCell-root": {
                  color: palette.text.black,
                },
              },
              "& .MuiTableCell-root": {
                color: palette.text.sub.gray01,
                "text-align": "left",
                "padding-right": "50px",
              },
            },
          },
        },
      },
    },
    ".MuiTableContainer-root": {
      overflow: "overlay",
      "overflow-x": "hidden",
      // scrollbar 스타일
      "&::-webkit-scrollbar": {
        width: "16px",
      },

      "&::-webkit-scrollbar-thumb": {
        "border-radius": "9999px",
        "box-shadow": "inset 0 0 10px 10px rgba(0,0,0,0.1)",
        border: "solid 5px transparent",
      },

      "& .MuiTable-root": {
        "border-collapse": "collapse",
        "table-layout": "fixed",
        "word-break": "break-all",
        "& .MuiTableRow-root": {
          position: "relative",
          border: "none",
          "vertical-align": "middle",
        },

        "& .Mui-selected": {
          background: `url(${
            process.env.PUBLIC_URL
          }/images/icon/icon-next-arrow-primary.svg) no-repeat`,
          "background-position": "right 15px top 50%",
          "& .MuiTableCell-root": {
            color: palette.text.black,
          },
        },

        "& .MuiTableHead-root": {
          "& .MuiTableCell-root": {
            padding: "20px 0px",
            border: "none",
            "font-size": "12px",
            "font-weight": "400",
            color: palette.text.sub.gray01,
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              height: "1px",
              background: palette.line.gray02,
            },

            "&:first-of-type": {
              "&:before": {
                left: "0",
                right: "0",
              },
            },

            "&:last-of-type": {
              "&:before": {
                right: "0",
                left: "0",
              },
            },
          },
        },

        "& .MuiTableCell-stickyHeader": {
          background: palette.background.white,
        },

        "& .MuiTableBody-root": {
          "& .MuiTableRow-root": {},

          "& .MuiTableCell-root": {
            cursor: "pointer",
            border: "none",
            padding: "30px 50px 30px 0px",
            position: "relative",
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              height: "1px",
              background: palette.line.gray02,
            },

            "&:last-of-type": {
              "padding-right": "0px",
              "&:before": {
                right: "initial",
                left: "0",
              },
            },

            "&:only-of-type": {
              "&:before": {
                // width: "calc(100% - 30px) !important",
              },
            },
          },
        },
      },
    },

    /**************************************************************************************************
    category
    **************************************************************************************************/
    ".category-button": {
      display: "flex",
      "align-items": "center",
      "margin-right": "20px",
      "& .MuiButtonBase-root": {
        height: "auto",
        "& p": {
          color: palette.text.sub.gray01,
          margin: "0 5px",
        },
      },
    },
    ".popover": {
      "& .MuiPaper-root": {
        width: "400px",
        "max-height": "500px",
        "border-radius": "16px",
        "box-shadow": `0px 30px 52px rgba(0, 0, 0, 0.2), 0px 9.94853px 17.4853px rgba(0, 0, 0, 0.121367), 0px 4.13211px 7.2625px rgba(0, 0, 0, 0.0831038), 0px 1.4945px 2.6267px rgba(0, 0, 0, 0.0497377)`,
      },
    },
    ".popover-bottom": {
      display: "flex",
      justifyContent: "space-evenly",
      padding: "10px",
      background: palette.primary.main,
      "& .MuiButtonBase-root": {
        color: "rgba(255, 255, 255, 0.5)",
        "&:last-child": {
          color: palette.text.white,
        },
      },
      "& .line": {
        display: "block",
        width: "1px",
        opacity: "0.3",
        background: palette.text.white,
        margin: "10px 0",
      },
    },
    ".category-tabs": {
      padding: "30px 30px 0px 30px",
      "border-bottom": `solid 1px ${palette.line.gray02}`,
      "& .MuiTab-textColorPrimary": {
        "&.Mui-selected": {
          "& .MuiTab-wrapper": {
            color: palette.primary.main,
            "font-weight": "600",
          },
        },
        "& .MuiTab-wrapper": {
          color: palette.text.sub.gray01,
          "font-weight": "400",
        },
      },
    },
    ".category-tabpanel": {
      "& .MuiBox-root": {
        padding: "30px",
        "overflow-x": "hidden",
        height: "340px",
      },
    },
    ".category-box": {
      "& .MuiFormGroup-root": {
        "flex-direction": "initial",
        "& .MuiFormControlLabel-root": {
          flex: "1 1 48%",
        },
      },
    },

    /**************************************************************************************************
    Chip
    **************************************************************************************************/
    ".MuiChip-root": {
      margin: "0 5px",
      border: `solid 1px ${palette.line.gray02}`,
      background: palette.background.white,
      color: palette.text.sub.gray01,
    },
    ".MuiChip-deletable:focus": {
      background: "inherit",
    },
    ".MuiCollapse-wrapperInner": {
      padding: "0 10px",
      "& .MuiAccordionDetails-root": {
        // padding: "0 0 40px 0",
        "& .MuiLink-root": {
          color: theme.palette.secondary.sub,
          textDecoration: "underline",
          lineBreak: "anywhere",
        },
      },
    },

    /**************************************************************************************************
    no-data
    **************************************************************************************************/
    ".no-data": {
      padding: "50px 0",
      textAlign: "center",
      lineHeight: "1",

      "& p": {
        marginTop: "28px",
        fontSize: "14px",
      },
    },

    /**************************************************************************************************
    pagination
    **************************************************************************************************/
    ".MuiPagination-root": {
      "& .MuiPagination-ul": {
        "& .MuiPaginationItem-textPrimary": {
          "&.Mui-selected": {
            "background-color": "transparent",
            color: palette.primary.main,
            "&:hover": {
              "background-color": "transparent",
            },
          },
        },
      },
    },
    ".end": {
      "justify-content": "end",

      "& .MuiPagination-ul": {
        "& .MuiPaginationItem-textPrimary": {
          "&.MuiPaginationItem-sizeSmall": {
            margin: "0 5px",
            fontSize: "16px",
          },
        },
      },
    },

    /**************************************************************************************************
    box-gray
    **************************************************************************************************/
    ".cont-box-gray": {
      padding: "40px",
      backgroundColor: "#F3F4F6",
      borderRadius: "30px",

      "&::-webkit-scrollbar": {
        width: "16px",
      },
      "&::-webkit-scrollbar-thumb": {
        margin: "50px 0",
        borderRadius: "9999px",
        boxShadow: "inset 0 0 10px 10px rgba(0,0,0,0.1)",
        border: "solid 5px transparent",
      },
      "& .box-cont": {
        fontSize: "14px",
        backgroundColor: "transparent",

        "& .head": {
          marginTop: "50px",
          marginBottom: "20px",
          fontSize: "16px",
          fontWeight: "700",

          "&:first-of-type": {
            marginTop: "0",
          },
        },
        "& p + .list-dot": {
          marginTop: "40px",
        },
        "& [class*='list-']": {
          "& > li": {
            position: "relative",

            "&:before": {
              position: "absolute",
              left: "0",
            },
            "& + li": {
              marginTop: "15px",
            },
          },
        },
        "& .list-dot": {
          marginLeft: "20px",

          "& > li": {
            paddingLeft: "10px",

            "&:before": {
              content: '""',
              top: "8px",
              width: "3px",
              height: "3px",
              borderRadius: "50%",
              backgroundColor: "#000",
            },
          },
          "&.has-title": {
            "& > li": {
              paddingLeft: "12px",

              "&:before": {
                top: "8px",
                width: "4px",
                height: "4px",
              },

              "& .title": {
                marginBottom: "15px",
                fontWeight: "700",
              },

              "& + li": {
                marginTop: "30px",
              },
            },
          },
        },
        "& .list-num": {
          counterReset: "list-number",

          "& > li": {
            paddingLeft: "25px",
            counterIncrement: "list-number",

            "&:before": {
              content: "'0'counter(list-number)",
              top: "1px",
              fontSize: "12px",
              fontWeight: "700",
            },
          },
        },
        "& .group": {
          "& p + [class*='list-']": {
            marginTop: "20px",
          },
        },
      },

      "&.scroll": {
        maxHeight: "220px",
      },
    },

    /**************************************************************************************************
    page
    **************************************************************************************************/
    ".cont-title": {
      "justify-content": "space-between",
      "margin-bottom": "20px",
      "& .MuiGrid-container": {
        display: "flex",
        "align-items": "center",
        // "max-width": "50%",
        // "flex-basis": "50%",
        "& h2": {
          "font-size": "30px",
          "font-weight": "900",
          "padding-bottom": "0",
        },
      },
    },
    ".cont-length": {
      "font-size": "30px",
      color: palette.primary.main,
      "font-weight": "600",
      "margin-left": "5px",
    },
    ".cont-title-right": {
      "justify-content": "end",
      "& .MuiChip-root": {
        border: "1px solid #EEE",
        backgroundColor: "#fff",
      },
    },
    ".MuiPaper-root": {
      color: palette.text.main,
    },

    /**************************************************************************************************
    scroll
    **************************************************************************************************/
    ".scroll": {
      "&::-webkit-scrollbar": {
        width: "16px",
      },

      "&::-webkit-scrollbar-thumb": {
        "border-radius": "9999px",
        "box-shadow": "inset 0 0 10px 10px rgba(0,0,0,0.1)",
        border: "solid 5px transparent",
      },
    },

    /**************************************************************************************************
    tooltip
    **************************************************************************************************/
    ".MuiTooltip-popper": {
      top: "-12px !important",
      "& .MuiTooltip-tooltip": {
        background: palette.background.black,

        "max-width": "none",
        "border-radius": "16px",
        "& ul": {
          padding: "40px 60px 40px 40px",
          "max-width": "600px",
          "& li": {
            "margin-bottom": "25px",
            "font-size": "14px",
            "list-style": "disc",
            "&:last-child": {
              "margin-bottom": "0",
            },
          },
        },
        "& .MuiTooltip-arrow:before": {
          background: palette.background.black,
        },
      },
    },

    /**************************************************************************************************
    footer
    **************************************************************************************************/
    "#footer": {
      position: "relative",
      "font-size": "12px",
      "border-top": `solid 1px ${palette.line.gray02}`,
      padding: "30px 100px 53px",
      "& .MuiGrid-item": {
        display: "flex",
        "justify-content": "space-between",
        "align-items": "baseline",
        // "padding-bottom": "20px",
        "& .MuiButton-root": {
          height: "inherit",
          "min-width": "0",
        },
        "&:last-child": {
          "justify-content": "flex-start",
          "align-items": "flex-start",
          "& .MuiButton-root": {
            "margin-right": "30px",
            "margin-bottom": "38.72px",
            "margin-left": "0",
            "font-size": "12px",
            color: "#000",
            "font-weight": "400",
            "text-decoration": "underline",
            "text-underline-position": "under",
            "&:last-child": {
              "margin-right": "0",
            },
          },
        },
      },
    },

    /**************************************************************************************************
    swiper
    **************************************************************************************************/
    ".swiper-container": {
      width: "95%",
    },
    "[class*='swiper-button-prev']": {
      "background-image": `url(${
        process.env.PUBLIC_URL
      }/images/icon/icon-swiper-arrow-left.svg)`,
      "background-repeat": "no-repeat",
      "background-size": "100%",
      "background-position": "center",
      width: "12px",
      height: "24px",
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
      "&.swiper-button-disabled": {
        "background-image": `url(${
          process.env.PUBLIC_URL
        }/images/icon/icon-swiper-arrow-left-disable.svg)`,
      },
    },

    "[class*='swiper-button-next']": {
      "background-image": `url(${
        process.env.PUBLIC_URL
      }/images/icon/icon-swiper-arrow-right.svg)`,
      "background-repeat": "no-repeat",
      "background-size": "100% auto",
      "background-position": "center",
      width: "12px",
      height: "24px",
      position: "absolute",
      top: "50%",
      right: 0,
      transform: "translateY(-50%)",
      "&.swiper-button-disabled": {
        "background-image": `url(${
          process.env.PUBLIC_URL
        }/images/icon/icon-swiper-arrow-right-disable.svg)`,
      },
    },

    /**************************************************************************************************
    react-image-crop
    **************************************************************************************************/
    ".ReactCrop .ReactCrop__crop-selection": {
      borderRadius: "50%",
    },

    /**************************************************************************************************
    swipe 안에 있는 작은card (video clip)
    **************************************************************************************************/
    ".MuiCard-root": {
      "& .Mui-focusVisible": {
        display: "none",
      },
      "border-radius": "16px",
      "box-shadow": "none",
      border: `solid 1px ${palette.line.gray02}`,
      "& .MuiCardActionArea-root": {
        "border-radius": "16px",

        "& .MuiCardMedia-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "140px",
          "border-radius": "16px 16px 0 0",
          "background-repeat": "no-repeat",
          "background-position": "center",
          "background-size": "100% 100%",
          "& .preview": {
            opacity: "0",
            color: "#fff",
            fontWeight: "600",
            fontSize: 16,
            "& img": {
              width: "25px",
              height: "25px",
              margin: "0 auto",
              display: "block",
              "padding-bottom": "5px",
            },
          },
          "& .play-img": {
            position: "absolute",
            bottom: "12px",
            left: "15px",
            display: "block",
            width: "26px",
            height: "26px",
            "background-color": "rgba(0,0,0,0.6)",
            "border-radius": "50%",
            "& img": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            },
          },
        },
        "& .MuiCardContent-root": {
          height: "140px",
          display: "flex",
          "flex-direction": "column",
          "& .product-stat": {
            display: "flex",
            alignItems: "center",

            "& .ttl": {
              "font-size": "12px",
              "margin-right": "3px",
            },
            "& .product-new-txt": {
              color: palette.primary.main,
            },
            "& .product-soldout-txt": {
              color: palette.text.sub.gray01,
            },
            "& .product-update-txt": {
              color: palette.background.sub,
            },
            "& .product-new-icon": {
              display: "block",
              background: `url(${
                process.env.PUBLIC_URL
              }/images/icon/icon-product-new.svg) no-repeat`,
              "background-size": "100% auto",
              width: "12px",
              height: "12px",
              "margin-right": "5px",
            },
            "& .product-update-icon": {
              display: "block",
              background: `url(${
                process.env.PUBLIC_URL
              }/images/icon/icon-product-update.svg) no-repeat`,
              "background-size": "100% auto",
              width: "12px",
              height: "12px",
              "margin-right": "5px",
            },
            "& .product-soldout-icon": {
              display: "block",
              background: `url(${
                process.env.PUBLIC_URL
              }/images/icon/icon-mark.svg) no-repeat`,
              "background-size": "100% auto",
              width: "12px",
              height: "12px",
              "margin-right": "5px",
            },
          },

          "& .small-line": {
            display: "block",
            width: "1px",
            height: "8px",
            background: palette.primary.main,
            margin: "0 6px 0 3px",
          },
          "& .star": {
            background: `url(${
              process.env.PUBLIC_URL
            }/images/icon/icon-star-red.svg) no-repeat`,
            "background-size": "100% auto",
            width: "10px",
            height: "12px",
          },

          "& .plbkTm": {
            display: "flex",
            "align-items": "center",
            "font-size": "10px",
            color: palette.text.sub.gray01,
            "& .play-icon": {
              background: `url(${
                process.env.PUBLIC_URL
              }/images/icon/icon-video-play.svg) no-repeat`,
              "background-size": "100% auto",
              width: "10px",
              height: "12px",
              "margin-right": "3px",
            },
          },
          "& .descPc": {
            "font-size": "14px",
            "font-weight": "600",
          },
          "& .formatRgstYmd": {
            flex: "1",
            display: "flex",
            "align-items": "flex-end",
            "font-size": "12px",
            color: palette.text.sub.gray01,
          },
          "& .lang": {
            "font-size": "12px",
            color: palette.text.sub.gray01,
            "margin-right": "3px",
          },
          "& .date": {
            "font-size": "12px",
            color: palette.text.sub.gray01,
          },
        },
        /* ********* hover ********* */
        "&:hover": {
          // backgroundColor: "#000",
          "& .MuiCardMedia-root": {
            opacity: "0.8",
            "& .preview": {
              // opacity: "1",
            },
          },
          "& .MuiCardContent-root": {
            background: palette.background.sub,
            color: palette.text.white,
            "border-radius": "0 0 16px 16px",
            "& .product-stat": {
              "& .ttl": {
                color: palette.text.white + " !important",
              },
              "& .product-new-icon": {
                background: `url(${
                  process.env.PUBLIC_URL
                }/images/icon/icon-product-new-white.svg) no-repeat`,
              },
              "& .product-soldout-icon": {
                background: `url(${
                  process.env.PUBLIC_URL
                }/images/icon/icon-product-soldout-white.svg) no-repeat`,
              },
              "& .product-update-icon": {
                background: `url(${
                  process.env.PUBLIC_URL
                }/images/icon/icon-product-update-white.svg) no-repeat`,
              },
            },

            "& .small-line": {
              background: palette.text.white,
            },
            "& .star": {
              background: `url(${
                process.env.PUBLIC_URL
              }/images/icon/icon-star-white.svg) no-repeat`,
            },
            "& .plbkTm": {
              color: palette.text.white,
              "& .play-icon": {
                background: `url(${
                  process.env.PUBLIC_URL
                }/images/icon/icon-video-play-white.svg) no-repeat`,
              },
            },
            "& .formatRgstYmd": {
              color: palette.text.white,
            },
            "& .lang": {
              color: palette.text.white,
            },
            "& .date": {
              color: palette.text.white,
            },
            "& .download": {
              background: `url(${
                process.env.PUBLIC_URL
              }/images/icon/icon-download-white.svg) no-repeat`,
            },
          },
        },
      },
    },

    /**************************************************************************************************
    modal - treeview
    **************************************************************************************************/
    ".MuiTreeItem-root": {
      marginBottom: "12px !important",

      "& >.MuiTreeItem-content": {
        "& .MuiTreeItem-label": {
          backgroundColor: palette.background.main + " !important",
        },
      },
    },
    ".Mui-expanded": {
      "& >.MuiTreeItem-content": {
        color: palette.primary.main,
      },
    },
    ".MuiCollapse-wrapper": {
      marginTop: "9px !important",
    },

    ".MuiDialog-paper": {
      "& .MuiDialogTitle-root": {
        "& .MuiTypography-h6": {
          fontWeight: 600,
        },
      },
    },

    /**************************************************************************************************
    responsive 
    **************************************************************************************************/
    "@media only screen and (max-width: 1366px)": {
      ".App-contents": {
        paddingTop: "0",
      },
      ".App-Page": {
        padding: "60px 30px 0px",
        overflow: "hidden",
      },
      ".header": {
        position: "relative",
      },

      /**************************************************************************************************
      responsive - header
      **************************************************************************************************/
      ".header-link-page": {
        position: "relative",
        padding: "0px 30px",
        top: "0",
        "font-size": "14px",
      },

      ".header-search-box.desktop": {
        display: "none",
        position: "relative",
        padding: "0px 30px",
        top: "0",
        "font-size": "14px",
      },
      ".header-search-box.mobile": {
        top: "-15px",
        "min-width": "0",
        "box-shadow": "initial",
        padding: "0",
        margin: "0 40px",
        "& .MuiBox-root": {
          width: "100%",
        },
      },

      /**************************************************************************************************
      responsive - footer
      **************************************************************************************************/
      "#footer": {
        padding: "20px 32px 53px",
        "& .MuiGrid-item": {
          "&:last-child": {
            "justify-content": "space-between",
          },
        },
      },

      /**************************************************************************************************
      responsive - swiper
      **************************************************************************************************/
      ".swiper-container": {
        width: "100%",
        overflow: "initial !important",
        "& .swiper-slide-small": {
          width: "250px !important",
        },
        "& .swiper-slide-highest": {
          width: "280px !important",
        },
      },
      "[class*='swiper-button-prev']": {
        display: "none",
      },
      "[class*='swiper-button-next']": {
        display: "none",
      },
    },
  },
})(() => null);

export default GlobalCss;
