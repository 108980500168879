import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  line: {
    display: "block",
    width: "100%",
    height: "1px",
    background: "#eee",
  },
  mainContent: {
    padding: "100px 100px 0 100px",
  },
  content: {
    padding: "0",
    "&:first-child": {
      padding: "0",
      "&>div": {
        display: "flex",
        "justify-content": "space-between",
      },
    },
    "& h2": {
      "font-size": "20px",
      "font-weight": "bold",
    },
  },
  mainTitle: {
    padding: "0 0 40px 0",
    "& h2": {
      "font-size": "24px",
      "font-weight": "bold",
    },
  },
  title: {
    padding: "50px 0 30px",
  },
  primarytitle: {
    padding: "0px 0 30px",
    color: "#cf000e",
  },
  list: {
    "font-size": "14px",
    color: "#000",
    "margin-bottom": "0",
    width: "90%",
    "& li": {
      display: "flex",
      "margin-bottom": "30px",
      "&:first-child": {
        "margin-bottom": "40px",
      },
      "&:last-child": {
        "margin-bottom": "0",
      },
      "& .num": {
        flex: "0 0 1.5%",
        color: "#000",
        "font-weight": "bold",
        margin: "2px 15px 0 0",
      },
      "& .title": {
        "font-size": "16px",
        "font-weight": "bold",
        "padding-bottom": "15px",
      },
    },
  },
  box: {
    width: "110%",
    padding: "40px",
    margin: "30px 0 0px",
    border: "1px solid #ccc",
    "border-radius": "30px",
    color: "#000",
    "font-size": "14px",
    "& ul": {
      width: "90%",
      "& li:not(:last-child)": {
        "margin-bottom": "15px",
      },
    },
    "& .small-dot": {
      flex: "0 0 1.5%",
      "margin-top": "9px",
      "background-image": `url(${
        process.env.PUBLIC_URL
      }/images/icon/icon-list-dot.svg)`,
      "background-repeat": "no-repeat",
    },
  },
  boxtitle: {
    color: "#000",
    padding: "0 0 20px 0",
    "&:not(:first-child)": {
      "margin-top": "40px",
    },
    "& h2": {
      "font-size": "16px",
    },
  },
}));

const Conduct = (props) => {
  //   console.log("props", props);

  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.primarytitle}>
          씨젠과 협력업체의 목표
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <ul>
            <li>
              <div>
                우리의 목표는 최고 품질의 제품과 서비스를 전세계에 공급하여 인류
                건강 증진에 앞장서는 것입니다.
              </div>
            </li>
            <li>
              <span className="num">I.</span>
              <div>
                <div className="title">최고 품질의 제품 생산</div>
                <div className="text">
                  씨젠은 전 세계인의 건강 증진을 목표로 두고 최고 품질의 제품을
                  개발하고 생산합니다. 우리 제품의 품질은 환자의 진단과 밀접한
                  관련이 있기 때문에 최고의 정확성을 갖추고 일해야만 합니다.
                  따라서 씨젠의 모든 협력업체는 (이하 “협력사”라고 합니다) 위와
                  같은 씨젠의 목표를 이해하고 그에 맞춰 협력할 의무가 있습니다.
                </div>
              </div>
            </li>
            <li>
              <span className="num">II.</span>
              <div>
                <div className="title">의료 전문가와의 협업 관계 유지</div>
                <div className="text">
                  씨젠은 전 세계 의료 시스템을 발전시키고자 많은 의료 전문가들과
                  협력하고 있습니다. 의료 전문가들과의 원활한 협업은 씨젠의
                  성장에 필수적인 요소입니다. 따라서 협력사는 의료 전문가들의
                  권리를 존중해야 하고 그들과 불법적인 관계를 맺어서는 안됩니다.
                  특히 관련 법률에 반하여 의료 전문가에게 부적절한 이익을
                  제공하지 말아야 합니다. 의료업계에서 요구되는 윤리 기준은
                  비교적 높을 수 있으니 이 점을 유의하여 모든 법률과 규정을
                  준수합시다.
                </div>
              </div>
            </li>
            <li>
              <span className="num">III.</span>
              <div>
                <div className="title">광고 및 홍보</div>
                <div className="text">
                  씨젠은 고객의 만족을 우선시합니다. 따라서 협력사는 씨젠의
                  제품과 서비스에 대하여 정확한 정보를 제공함으로써 고객만족에
                  힘써야 합니다. 정확하지 않은 정보는 환자의 진단과 치료에
                  부정적인 영향을 줄 수 있기에 세심하게 주의해야 합니다. 또한
                  협력사는 관련 법률을 준수하고 불공정한 마케팅 행위를 하지
                  말아야 합니다.
                </div>
              </div>
            </li>
          </ul>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle
          id="alert-dialog-title"
          className={classes.primarytitle}
          style={{ paddingTop: 70 }}
        >
          협력사 Code of Conduct
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            씨젠은 윤리경영과 준법경영을 추구합니다. 협력사도 맞춰서 모든 관련
            법규와 윤리기준을 준수해야 합니다.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항: 협력사는 아래 사항들을 준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  국내외 법규를 반영하는 사내 윤리강령 혹은 준법관리 프로그램을
                  제정하고 주요 준법 이슈들에 대해서는 임직원을 대상으로 하는
                  정기적인 교육을 실시합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  본 Code of Conduct의 내용을 파견 근무자와 임원을 포함한 모든
                  임직원에게 고지합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  관련 법규나 윤리기준을 위반한 경우, 해당 사실을 씨젠에게
                  알리고 적극적으로 해결방안을 찾습니다.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          1. 인권 및 노동
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <ul>
            <li>
              <div>
                씨젠은 유엔(UN)이 공표한 세계인권선언(Universal Declaration of
                Human Rights), UN 기업과 인권 이행 원칙(UN Guiding Principles on
                Business and Human Rights), 국제노동기구(ILO) 핵심협약 등 기본적
                인권에 관하여 국제기구에서 채택하고 공표한 다양한 인권 관련 국제
                이니셔티브를 존중합니다.
              </div>
            </li>
          </ul>
          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항: 협력사는 아래 사항들을 준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  최소 근로 연령에 미달하는 아동의 고용을 금지하며, 청소년 노동
                  또한 최소 근로연령과 근로시간, 근로조건을 준수합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  강제노동, 착취노동 등 근로자의 의사에 반하는 모든 노동을
                  금지하며, 근로자의 자국언어로 명확한 근로조건이 명시된
                  근로계약서를 작성합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  채용과 승진, 보상, 교육의 기회 제공 등과 같은 고용 관행에 있어
                  인종, 피부색, 나이 성별, 성정체성, 출신, 국적, 장애, 임신,
                  종교, 정치성향, 노조가입, 결혼 여부에 근거한 차별을
                  금지합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  모든 근로자를 인격적으로 존중하고, 성희롱, 학대, 체벌, 정신적
                  또는 신체적 강압, 폭언 등의 비인도적인 대우나 위협이 없는
                  작업환경을 조성합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  최대 근로시간 및 일수, 최저임금, 복리후생, 노동시간 및 보상 등
                  관련 법적 기준을 모두 준수합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  법으로 보장된 결사의 자유와 단체교섭의 권리를 인정하고,
                  근로자가 차별이나 보복, 협박, 괴롭힘에 대한 두려움 없이
                  근로조건에 대해 경영진과 의사 소통할 수 있는 환경을
                  조성합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  근로자들이 안전한 작업 환경에서 건강하게 근무할 수 있도록
                  위험요소 평가 및 제거, 지속적 교육, 비상대응훈련, 개인보호장비
                  제공 등 법적으로 요구되는 예방조치를 취합니다.{" "}
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>
      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          2. 환경의 지속가능성
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <ul>
            <li>
              <div>
                씨젠은 환경 관련 법령을 준수하고 환경경영을 추구합니다. 협력사도
                이에 맞춰 환경 관련 법령과 기준을 준수해야 합니다.
              </div>
            </li>
          </ul>
          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항: 협력사는 아래 사항들을 준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  모든 필수 환경 인허가 및 등록사항을 취득, 유지, 갱신하고
                  제조공정에 있어 발생하는 유해물질, 폐기물 및 폐수, 대기오염 등
                  환경과 지역사회에 미칠 수 있는 모든 부정적 영향을 선제적으로
                  최소화하기 위해 최선을 다합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  씨젠의 합리적 요청이 있을 경우, 생산, 제품, 운송과 관련된 전
                  과정 평가(Life Cycle Assessment)를 실시하고, 해당 평가를
                  뒷받침하는 환경 데이터를 제공합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  제품 생산 시 재생가능한 에너지 사용을 확대하기 위해 노력한다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  건강 또는 안전상의 위험, 수자원고갈, 폐기물, 오염 등의
                  환경파괴를 초래할 수 있는 불법, 비윤리적 또는 부적절한 방법을
                  통해 취득한 원자재의 사용을 금합니다.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          3. 부정부패 방지와 뇌물 공여의 금지
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <ul>
            <li>
              <div>
                씨젠은 부정부패와 뇌물 공여를 금지합니다. 따라서 협력사는
                부정부패나 뇌물 공여로 보일 수 있는 어떠한 행동도 하지 않습니다.
              </div>
            </li>
            <li>
              <div>
                <div className="title">3-1. 뇌물 공여</div>
                <div className="text">
                  뇌물 공여는 형사 처분을 받을 수 있는 명백한 불법행위입니다.
                  따라서 씨젠은 뇌물 공여를 철저하게 금지합니다. 뇌물 공여란
                  상대방으로부터 부당한 이익을 얻기 위하여 직접 혹은 대리인을
                  통해 경제적 이익을 제공하는 것을 뜻합니다. 뇌물로 정의 될 수
                  있는 경제적 이익에는 선물, 금전, 유가증권, 접대, 노동력 등이
                  포함됩니다. 협력사는 뇌물 공여 관련 법규를 준수하고 오해를
                  야기할 수 있는 행동을 하지 않습니다.{" "}
                </div>
              </div>
            </li>
            <li>
              <div>
                <div className="title">3-2. 선물, 접대, 향응</div>
                <div className="text">
                  선물을 하고 접대를 함으로써 상대방의 결정에 영향을 미치도록
                  하는 행위는 뇌물 공여의 한 종류로 여겨집니다. 따라서 협력사는
                  선물, 접대, 향응 등을 제공하여 씨젠에게 피해가 발생하지 않도록
                  주의해야 합니다.{" "}
                </div>
              </div>
            </li>
            <li>
              <div>
                <div className="title">3-3. 여비 지급</div>
                <div className="text">
                  협력사가 업무를 진행하다 보면 직무를 수행한 사람 혹은 업체에게
                  여비를 제공해야 할 상황이 생깁니다. 하지만 과하게 여비를
                  지급하거나 비용 변제를 할 경우 상대방의 행동에 영향을 미치고자
                  하는 부정한 행위로 오해 받을 수 있습니다. 따라서 교통비,
                  숙박비와 같은 여비를 지급할 시에는 합리적인 금액만을 지급해야
                  합니다.
                </div>
              </div>
            </li>
            <li>
              <div>
                <div className="title">3-4. 후원, 기부, 기증</div>
                <div className="text">
                  많은 기업들이 사회적 책임을 다하기 위하여 후원 혹은 기부의
                  형태로 사회적 환원 사업을 진행합니다. 하지만 씨젠은 그러한
                  행위가 뇌물 공여의 수단으로 악용되는 것을 금지합니다.{" "}
                </div>
              </div>
            </li>
            <li>
              <div>
                <div className="title">3-5. 대리인을 통한 부정행위</div>
                <div className="text">
                  직무를 수행하다 보면 씨젠과 협력사 임직원 이외에 다른 사람
                  혹은 업체가 개입될 수 있습니다. 협력사는 어떠한 경우에도
                  대리인을 통하여 뇌물 공여 등의 범법행위를 저질러서는 안됩니다.
                  또한 협력사는 업무에 개입된 자가 과거 혹은 현재에 부정부패에
                  연루된 기록이 있는지 확인해야 합니다.
                </div>
              </div>
            </li>
          </ul>
          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항 : 협력사는 아래 사항들을 준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  자금 지출의 목적이 명확하지 않거나 부적절할 시에는 지출하지
                  않습니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  정해진 절차에 따라 자금을 지출하고 지출 기록을 명확하게
                  기록합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>지출을 기록하기 위한 사내 관리 시스템을 구축합니다.</div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  거래상대방에게 부적절한 이익을 제공해서도, 제공하기로
                  약속해서도, 제공할 의도를 표현해서도 안됩니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  공무원과의 거래에서도 부정부패나 뇌물 공여의 문제가 발생하지
                  않도록 주의해야 합니다.
                </div>
              </li>
            </ul>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항 : 선물, 접대, 향응 등에 대해서는 아래 사항을
              준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  선물과 접대 등은 가치, 유형, 빈도수 및 받는 이의 입장을
                  고려하여 정해야 합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  선물을 하거나 접대한 기록을 회사 장부에 투명하고 정확하게
                  기록하여야 합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  주변 상황을 고려했을 때 뇌물 공여로 오해 받을 수 있는 행동은
                  하지 않습니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  상대방으로부터 이익을 바라고 선물이나 향응을 제공하지
                  않습니다. 또한 뇌물 공여로 의심받을 수 있는 선물이나 접대를
                  받지 않습니다.
                </div>
              </li>
            </ul>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항 : 후원, 기부, 기증 등에 대해서는 아래 사항을
              준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  부적절한 의도가 내포되어 있을 시 그 어떤 후원금이나 기부금도
                  주고받지 않습니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  뇌물 공여의 수단으로 악용되지 않도록 주의해야 하며 오직 정당한
                  목적이 있을 시에만 지급합니다.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          4. 공정거래법, 독점금지법 그리고 지식재산권법의 준수
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            씨젠은 씨젠의 사업이 진행되고 있는 모든 국가의 법규를 준수합니다.
            따라서 협력사도 관련 법규, 특히 공정거래와 독점금지 관련 법규를
            준수해야 합니다. 협력사는 시장 독점을 야기할 수 있는 행위, 예를 들어
            경쟁자 간의 가격 통일, 입찰 담합, 거래제한 계약 등을 시도해서는 안
            됩니다. 또한 우월적 지위를 악용하여 공정한 거래를 방해하면 안
            됩니다. 덧붙여, 협력사는 저작권, 특허권, 상표권과 같은 지식 재산권을
            적극적으로 보호해야 합니다. 그러기 위해서는 지식재산권 관련 법규를
            항상 준수하여야 합니다.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항 : 협력사는 아래 사항들을 준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>독점금지법과 공정거래법을 준수합니다.</div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  가격 통일, 입찰 담합, 고객 할당 등으로 오해 받을 행동을 하지
                  않습니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  모든 거래처, 특히 씨젠의 지식 재산권을 침해할 수 있는 행동을
                  하지 않습니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  문제 될만한 사항이 발견될 시, 적극적으로 법률전문가의 도움을
                  받아 문제를 해결합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  법규 위반 시에는 즉시 씨젠에게 해당 사실을 알리고 담당 기관의
                  조사에 성실히 임합니다.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          5. 이해상충 문제의 회피
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            이해상충의 문제는 여러 방식으로 일어날 수 있습니다. 협력사가 씨젠의
            제품을 판매하고 유통하는 과정에서 이해충돌을 일으킬 수 있는 사람,
            예를 들어 씨젠의 임직원, 고객 그리고 공무원과 거래하는 경우가 가장
            대표적인 이해상충의 사례입니다.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항 : 협력사는 아래 사항들을 준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  씨젠을 대리하여 일하는 동안 씨젠 고객 혹은 씨젠 고객사의
                  임직원을 고용 하거나 고용을 제안 하지 않습니다. 금전적 거래
                  또한 하지 않습니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  협력사의 임직원이 씨젠의 고객 혹은 공무원과 가족관계일 경우
                  씨젠에게 해당 사실을 알려야 합니다. 가족 관계가 아니더라도
                  협력사 임직원이 씨젠의 고객과 이해상충의 문제를 일으킬 수 있는
                  관계에 있다면 해당 사실을 알릴 의무가 있습니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  협력사와 씨젠의 계약 관계를 통하여 개인적인 이득을 취하지
                  않습니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  이해상충의 문제를 관리하기 위한 내부 컨트롤 시스템을
                  도입합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>이해상충의 문제가 발견되는 즉시 씨젠에게 알립니다.</div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          6. 금융범죄 방지의 의무
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            자금 세탁과 테러 자금 지원과 같은 금융범죄는 씨젠의 성공을 막는
            걸림돌이 될 수 있습니다. 따라서 씨젠은 금융범죄로 이어질 수 있는
            모든 부적절한 행위가 근절되길 희망합니다. 씨젠은 금융범죄를 예방하기
            위하여 법률적 요건과 당사 윤리 요건을 모두 충족시킬 수 있는 회사와만
            거래합니다. 협력사의 재무구조는 세심하게 검토되는 사항입니다.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항 : 협력사는 아래 사항들을 준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  재무구조와 경영구조에 관한 씨젠의 문의에 성실하게 답변하고
                  필요한 문서를 제때에 제공합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>정확하고 투명하게 재무기록물을 관리합니다.</div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  협력사 임직원이 부적절한 거래를 하고 있다고 의심 될 경우
                  적극적으로 조사합니다. 문제 발생 시 씨젠과 담당 정부 기관에
                  즉시 보고합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  금융범죄로 이어질 수 있는 행위를 임직원에게 지시하지 않습니다.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          7. 개인 정보 보호의 의무
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            씨젠은 고객과의 신뢰를 매우 중요하게 생각합니다. 고객의 신뢰를
            얻으려면 개인 정보 관리에 힘을 쏟아야 합니다. 특히 의료업계의 경우
            높은 수준의 개인 정보 관리가 요구 됩니다. 따라서 씨젠의 협력사는 각
            국가의 개인정보보호법을 숙지하고 준수해야 합니다.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항 : 협력사는 아래 사항들을 준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>개인 정보 수집 시 수집 목적을 명확하게 밝혀야 합니다.</div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  개인 정보 유출을 예방할 수 있는 내부 시스템을 구축합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  씨젠과 관련된 정보 유출 사건 발생 시 씨젠에게 관련 사실을 즉시
                  알리고 지체 없이 해결방안을 마련해야 합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  사전동의 없이 개인 정보를 제3자에게 전달하지 않습니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>필요한 경우, 적극적으로 법률전문가의 도움을 받습니다.</div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  의도하였던 수집 목적을 벗어난 개인정보를 수집하지 않습니다.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          8. 수입∙수출통제법과 세관 규정의 준수
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            씨젠은 전 세계에 고객을 둔 글로벌 회사이기에 수입∙수출통제법과 세관
            규정을 준수해야 할 의무가 있습니다. 따라서 협력사는 국내 및 국제
            화물 시스템을 이해하고 관련 법규를 준수해야 합니다. 특히 수출 시에는
            해당 국가의 수입 규제 현황을 잘 파악하고 있어야 합니다. 수출∙수입
            규제는 상품뿐만 아니라 소프트웨어나 기술의 판매에도 영향을 줄 수
            있기에 주의해야 합니다.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항 : 협력사는 아래 사항들을 준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  각 국의 수출∙수입 규제 현황과 세관 규정을 모두 파악한 후
                  직무를 수행하여야 합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  제품과 기술의 이송 과정을 모니터 하기 위한 사내 관리 시스템을
                  구축해야 합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  제품과 기술 수출에 필요한 신고서와 허가서를 발급받아야 합니다.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          9. 회계 관리의 의무
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            씨젠은 협력사가 정직하게 일하기를 희망합니다. 따라서 협력사는
            씨젠과의 상호 신뢰 관계를 유지하기 위해 회계법을 준수하고 회계
            전문가를 고용하여 투명한 회계 관리를 시행해야 합니다. 덧붙여,
            협력사는 고객과 투자자에게 정확하고 믿을 수 있는 정보만을 전달할
            의무가 있습니다. 협력사가 씨젠에게 제공하거나 대중에게 공개하는 모든
            자료는 오직 진실만을 담고 있어야 합니다. 특히 공시되는 자료의 경우
            거짓된 정보를 포함할 시 형사처분을 받을 수 있다는 점을 유의해야
            합니다.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              협력사 의무 준수 사항 : 협력사는 아래 사항들을 준수하여야 합니다.
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  신뢰할 수 있는 회계 관리 인력을 둬야 하며 사내 문서 관리
                  시스템을 구축해야 합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  공개 자료와 비공개 자료 모두 진실된 정보만을 담고 있어야
                  합니다.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  허위 진술과 문서 위조는 형사 처분을 받을 수 있는 중대한
                  범죄행위임을 인지합니다.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle
          id="alert-dialog-title"
          className={classes.primarytitle}
          style={{ paddingTop: 60 }}
        >
          문의처 : Contacting Point
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            본 Code of Conduct를 포함한 모든 윤리규범에 대한 문의사항이 있을
            경우 본인이 속해있는 회사에 가장 먼저 문의하고 내부 절차에 따라
            해결책을 모색 해야 합니다. 협력사의 임직원은 위반 행위를 목격할 경우
            적극적으로 신고해야 합니다. 협력사는 최선을 다하여 신고자의 신분을
            보호해야 하며 모든 사건을 면밀히 조사해야 할 의무가 있습니다. 또한
            협력사가 씨젠 임직원 혹은 대리인의 부적절한 행위를 목격한 경우 즉시
            씨젠에게 해당 사실을 알려야 합니다.
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle
          id="alert-dialog-title"
          className={classes.primarytitle}
          style={{ paddingTop: 60 }}
        >
          씨젠 문의처
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            씨젠에게 문의할 사항이 있거나 신고할 사항이 있을 경우
            winwinseegene@seegene.com으로 연락 부탁드립니다.
          </div>
        </DialogContentText>
      </DialogContent>
    </>
  );
};

export default Conduct;
