import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import translateJSON from "assets/translation/translate.json";

export default function useTranslation(targetLang = false, isSet = false) {
  const [cookie, setCookie] = useCookies(["globalLang"]);
  const [translatedTxt, setTranslatedTxt] = useState(
    translateJSON[cookie.globalLang ? cookie.globalLang : "en"]
  );

  useEffect(() => {
    if (targetLang && isSet) {
      setCookie("globalLang", targetLang);
    }
  }, [targetLang]);

  useEffect(async () => {
    if (targetLang || cookie.globalLang) {
      setTranslatedTxt(translateJSON[targetLang || cookie.globalLang]);
    }
  }, [cookie.globalLang]);

  return translatedTxt;
}
