import { Button, Grid, makeStyles } from "@material-ui/core";
import axios from "axios";
import useWindowSize from "components/hook/use-window-size";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Cookies, withCookies, useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import useTranslation from "components/hook/use-translation";
import PathBar from "./path-bar";
import TranslatePopup from "./translate-popup";
import NonLoginMenu from "../file/non-login-menu";

const cookies = new Cookies();
// console.log("initila", cookies);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 76,
    minWidth: 1366,
  },
  headerWrap: {
    width: "100%",
    paddingLeft: 100,
    paddingRight: 100,
    justifyContent: "space-between",
    // maxWidth: 1720,
    // marginRight: 15,
  },
  rowWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  divider: {
    height: 18.2,
    width: 0,
    border: "1px solid #cccccc",
    marginLeft: 10,
    marginRight: 10,
  },
  headerLogoTxt: {
    fontFamily: "Inter",
    fontWeight: "Bold",
    fontSize: 20,
    lineHeight: "20px",
    minWidth: 54,
    transition: "color ease 0.2s",
    "&:hover": {
      color: "#cf000e !important",
    },
    "&:visited": {
      color: "#000",
    },
  },
  nav: {
    marginLeft: 100,
    "& ul": {
      display: "flex",
      "& li": {
        // flexGrow: 1,
        marginRight: 90,
        "& a": {
          color: "#000",
          transition: "color ease 0.2s",
          "&:hover": {
            color: "#cf000e !important",
          },
        },
        "& span": {
          fontFamily: "Inter",
          fontSize: 18,
          lineHeight: "21px",
        },
      },
    },
  },
  menuActive: {
    color: "#cf000e !important",
  },
  linkButton: {
    minWidth: 147,
    height: 36,
    borderRadius: 50,
    "& >span:first-child": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",

      "& p": {
        fontFamily: "Inter",
        fontSize: 14,
        lineHeight: "14px",
      },
      "& img": {
        marginTop: 2,
        width: 5.33,
        height: 10.67,
      },
    },
  },
  menuButton: {
    cursor: "pointer",
    width: 30,
    height: 22,
    marginLeft: 30,
  },
}));

function PublicMenu(props) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [nowPath, setNowPath] = useState("");

  const translatedTxt = useTranslation();
  const location = useLocation();

  useEffect(() => {
    let path = location.pathname;
    path = path.split("/");
    setNowPath(path);
    setMenuOpen(false);
  }, [location]);

  if (!nowPath || !translatedTxt) return <></>;

  return (
    <>
      <div className={classes.container}>
        <Grid className={classes.rowWrap + " " + classes.headerWrap}>
          <div className={classes.rowWrap}>
            <div className={classes.rowWrap}>
              <a
                href={process.env.REACT_APP_SEEGENE_URL}
                style={{
                  width: 140,
                }}
              >
                <img
                  src={`${
                    process.env.PUBLIC_URL
                  }/images/icon/icon-logo-public.svg`}
                  alt="logo"
                />
              </a>
              <div className={classes.divider} />
              <Link
                to="/eDoc"
                className={
                  classes.headerLogoTxt +
                  " " +
                  (nowPath[1] === "eDoc" ? classes.menuActive : "")
                }
              >
                <p>eDoc</p>
              </Link>
            </div>
            <Grid className={classes.headerMenu}>
              <Grid className={classes.nav}>
                <ul>
                  <li>
                    <Link
                      to={`/file/manual`}
                      className={
                        nowPath[2] == "manual" ? classes.menuActive : ""
                      }
                    >
                      <span>IFU</span>
                      {/*약어는 번역될필요없어서 고정했어요*/}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/file/msds`}
                      className={nowPath[2] == "msds" ? classes.menuActive : ""}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt.MSDS,
                        }}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/file/coa`}
                      className={nowPath[2] == "coa" ? classes.menuActive : ""}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt.COA,
                        }}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/file/standard-brochure`}
                      className={
                        nowPath[2] == "standard-brochure"
                          ? classes.menuActive
                          : ""
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt["standard-brochure"],
                        }}
                      />
                    </Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </div>

          <Grid className={classes.rowWrap}>
            <Button
              fullWidth
              className={classes.linkButton}
              color="primary"
              variant="contained"
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_SEEGENE_URL}`;
              }}
            >
              <p>seegene.com</p>
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/images/icon/icon-non-login-button-stroke.svg`}
                alt="stroke"
              />
            </Button>
            <img
              onClick={() => {
                setMenuOpen(true);
              }}
              className={classes.menuButton}
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-non-login-menu.svg`}
              alt="hamburger"
            />
          </Grid>
        </Grid>
      </div>
      <PathBar top={0} nowPath={nowPath} translatedTxt={translatedTxt} />
      {menuOpen && <NonLoginMenu setMenuOpen={setMenuOpen} />}
    </>
  );
}
export default withRouter(withCookies(PublicMenu));
