import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export default function Conference(props) {
  const classes = useStyles();

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">01</Typography>
          <Typography className="tit">
            Support for Attendance of a Local Conference
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Distributors planning to attend a local conference may receive the
            following support from the head office.
          </Typography>
          <Typography>
            <ul>
              <li>
                1. Posters, X-banners, brochures, gifts, and other supplies
              </li>
              <li>2. Invitations of speakers for seminars or symposiums</li>
              <li>
                3. Promotional videos, dummy kits, interactive videos, email
                introducing the conference, and placement of an online banner
              </li>
            </ul>
          </Typography>
          <Typography>
            We have a system in place to provide distributors with any
            promotional materials and&#47;or support they may need, including an
            exhibition booth package. All support provided is for one booth
            only. You may also request support for product displays online by
            providing some basic information on the conference you will attend
            through this website. In this way, we aim to ensure that you are
            well prepared for the conference, even if you receive no assistance
            from an agent specializing in conference displays.
          </Typography>
          <Typography>
            Depending on your booth type, you may be able to request a support
            package. If you would like to remodel or expand your booth, please
            consult with your RM to receive support from the head office.
            Information on conferences for which you have received support to
            attend from the head office will be saved. We do this so that the
            next time you would like to request support to attend the same
            conference, all you will need to do is submit the same request
            again.
          </Typography>
          <Typography>
            Depending on the type of support provided by the head office,
            transportation fees and/or additional costs may be incurred. Please
            contact your RM for more information.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">02</Typography>
          <Typography className="tit">
            Option 1: Shell Scheme Package for Exhibition Booth
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This package is suitable for a standard booth &#40;3m x 3m&#41;,
            such as a shell scheme booth provided by an exhibition organizer.
            With this package, you can order as many posters on different topics
            as you need to place on the walls of the booth. The package consists
            of posters, X-banners, other table accessories, and printed
            promotional materials.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">03</Typography>
          <Typography className="tit">
            Option 2: Roll-up Banner Package for Exhibition Booth
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This package is great for those who intend to design and set up
            their own customized booth.
            <br />
            You can choose to decorate your booth with either three small
            roll-up banner stands or one large banner stand. All you need to do
            is select the size, number, and poster theme that you want and place
            your order.
            <br />
            The package consists of posters, X-banners, various booth
            accessories, and printed promotional materials.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">04</Typography>
          <Typography className="tit">
            Option 3: Banners, Promotional Materials, and Requests for
            Conference Speakers
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You may also review graphic files that the head office has used at
            past events and reuse them, allowing you to order only the
            X-banners, printed promotional materials, and gifts for your
            conference. If you need to invite a speaker for the conference,
            please contact your RM via email or phone for support. However, it
            should be noted that we may not always be able to find and invite
            speakers appropriate for every conference.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">05</Typography>
          <Typography className="tit">
            How to Request Support for Attending a Conference
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span className="Accordion-tit">
              1-1. How to create a new request
            </span>
            Go to<strong> Conference &#62; Small Booth Package </strong>and
            click the<strong> Create New Request </strong>button. The system
            will create a new request for you automatically. There are three
            steps to follow.
            <br />
            (Step 1: Provide requested information on the conference &#62; Step
            2: Choose graphics and promotional materials &#62; Step 3: Provide
            the address to which the package will be delivered and confirm the
            request)
          </Typography>

          <Typography>
            <span className="Accordion-tit">
              1-2. How to put on hold and resume a request
            </span>
            If for some reason you need to put a request for support to attend a
            conference on hold at some point during the three steps,1) you can
            save temporarily save the details you have entered and come back to
            it again later at the same step. You can check a request you have
            saved temporarily by going to
            <strong> Conference &#62; Small Booth Package. </strong>Your request
            can be also found in the<strong> Cart List.</strong> You can
            complete such requests at anytime you want.
          </Typography>

          <Typography>
            <span className="Accordion-tit">
              1-2. How to put on hold and resume a request
            </span>
            If for some reason you need to put a request for support to attend a
            conference on hold at some point during the three steps,1) you can
            save temporarily save the details you have entered and come back to
            it again later at the same step. You can check a request you have
            saved temporarily by going to
            <strong> Conference &#62; Small Booth Package. </strong>Your request
            can be also found in the<strong> Cart List.</strong> You can
            complete such requests at anytime you want.
          </Typography>

          <Typography>
            1) (Step 1: Provide information on the conference &#62; Step 2:
            Choose a package and promotional materials &#62; Step 3: Confirm the
            request)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">06</Typography>
          <Typography className="tit">How to Choose Posters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span className="Accordion-tit">1-1. Number of posters</span>
            You may order up to 15 posters. Generally, you may need as many as
            nine posters for a shell scheme booth. Please make sure to check the
            size and type of booth before deciding the number of posters you
            will need.
          </Typography>

          <Typography>
            <span className="Accordion-tit">1-2. Poster size</span>
            Please measure the size of the walls of the booth on which you plan
            to place posters before deciding the sizes of the posters. A
            standard shell scheme booth is about 900mm by 1,900mm. However,
            please always measure the size of the walls, because the size of
            shell scheme booths may vary depending on the exhibition. It is
            important to measure the size correctly so that you can avoid having
            to cut the posters to the appropriate size at the exhibition.
          </Typography>

          <Typography>
            <span className="Accordion-tit">1-3. Set of poster themes</span>
            Choosing a set of posters consisting of recommended themes makes it
            easier and faster to place your order. If you want to create a
            customized set of posters that differs from the recommended themes,
            you can do so by clicking on “Poster Content (Individually),” where
            you can add or delete individual posters.
          </Typography>

          <Typography>
            <span className="Accordion-tit">
              1-4. Insertion of distributors’ logos
            </span>
            If you would like to modify the posters or insert your own logo,
            please send us references and your logo files with a comment so that
            the person in charge at the head office can be informed of the
            change.
          </Typography>

          <Typography>
            <span className="Accordion-tit">
              1-5. Saving a request temporarily
            </span>
            You can temporarily save any information that you have already
            entered at any step in the request process by clicking
            <strong> Save Booth Poster </strong>at the bottom of the screen. If
            you click Reset, all previously entered poster request information
            will be deleted.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">07</Typography>
          <Typography className="tit">How to Choose An X Banner</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span className="Accordion-tit">1-1. Size and quantity</span>
            Indicate the size and quantity of the X-banners you need. We have
            two sizes of X-banners: small (600mm) and large (800mm). Choose the
            quantity of X-banners before choosing the graphics. Please leave a
            comment if there is anything that we should be aware of when
            creating your X-banners.
          </Typography>

          <Typography>
            <span className="Accordion-tit">
              1-2. Insertion of distributors’ logo
            </span>
            You can request Seegene’s logo file if needed. If you want to have
            your (distributor’s) logo printed on the banners, please upload your
            logo file to this website.
          </Typography>

          <Typography>
            <span className="Accordion-tit">
              1-3. Saving a request temporarily
            </span>
            You can temporarily save any information that you have already
            entered at any step of the request process by clicking Save
            X-Banner. If you click Reset, all previously entered X-banner
            request information will be deleted.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">08</Typography>
          <Typography className="tit">
            How to Choose Additional Options&#47;Promotional Materials
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span className="Accordion-tit">
              1-1. Choose additional options
            </span>
            <ul>
              <li>
                Please check if you need any of the following: tablecloths,
                invitations, displays, or video content.
              </li>
              <li>
                Which items are to be provided will be decided through
                discussion with your RM, as we may not be able to provide some
                items.
              </li>
            </ul>
          </Typography>

          <Typography>
            <span className="Accordion-tit">
              1-2. Choose promotional materials
            </span>
            <ul>
              <li>
                We can provide you with any brochures/catalogs or gifts you may
                need for your exhibition booth. However, the quantities of
                printed promotional materials we are able to provide depends on
                the size of the conference, size of your booth, and length of
                the exhibition. Please note that you can order only one type of
                gift other than ballpoint pens.
              </li>
              <li>
                Indicate the quantity of each item you would like to order and
                place the items in the cart to complete your request.
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">09</Typography>
          <Typography className="tit">
            Confirmation of Request List for Attending a Conference
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span className="Accordion-tit">
              1-1. How to check an incomplete request list for attending a
              conference
            </span>
            You can check a request you did not complete and saved temporarily
            by going to Conference &#62; Small Booth Package. Your request can
            also be found in the<strong> Cart List.</strong>
          </Typography>

          <Typography>
            <span className="Accordion-tit">
              1-2. Choose promotional materials
            </span>
            <ul>
              <li>
                We can provide you with any brochures&#47;catalogs or gifts you
                may need for your exhibition booth. However, the quantities of
                printed promotional materials we are able to provide depends on
                the size of the conference, size of your booth, and length of
                the exhibition. Please note that you can order only one type of
                gift other than ballpoint pens.
              </li>
              <li>
                Indicate the quantity of each item you would like to order and
                place the items in the cart to complete your request.
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">10</Typography>
          <Typography className="tit">
            How RMs Can Request Support for Their (Distributor’s) Attendance of
            a Conference (Separate Approval Required)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            RMs can request support for distributors’ attendance of a conference
            on behalf of their distributors. However, this requires the approval
            of a manager in the distributors’ region. Such approval is also
            necessary when the cost of attendance exceeds the set budget.
            <br />
            On the website, RMs can print out the support request letter in the
            final step of the request process.
          </Typography>

          <Typography>
            * If distributors submit the request, all that is required is the
            RM’s approval (as long as the cost does not exceed the budget).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">11</Typography>
          <Typography className="tit">
            RM’s Approval of a Request for Support to Attend a Conference (RM)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Once a distributor submits a request for support to attend a local
            conference, that distributor’s RM can check and review the request
            in<strong> My Page &#62; Distributors’ Requests </strong>and grant
            approval accordingly.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">12</Typography>
          <Typography className="tit">
            Important Information Regarding Requests for Files
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you wish to receive electronic files instead of printed products,
            you must sign a confidentiality agreement, which is intended to
            protect the copyright of the files and prevent their misuse. If you
            submit your request on this website, however, most of the files can
            be provided without the signing of such an agreement, according to
            the terms and conditions of the website, excluding some data that
            you will need to obtain your RM’s approval to receive.
          </Typography>
          <Typography>
            We can also provide promotional products translated into a local
            language in electronic file format. If you need such files, please
            contact your RM.
            <br />
            However, the distributors will be required to pay the cost of
            translation and editing.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">13</Typography>
          <Typography className="tit">
            Important Information Regarding Delivery of Promotional Materials
            (Distributors)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Please provide a name and the address to which the materials are to
            be delivered. We recommend that you take delivery of the materials
            directly rather than having them delivered to the exhibition. Also,
            it is important to note the delivery time before placing an order.
            If there is insufficient time for a delivery, you may be able to
            receive electronic files containing graphics, banner designs, or
            other such promotional content and print them out yourself.
          </Typography>
          <Typography>
            The delivery method (procedure) and fees need to be discussed with
            your RM beforehand. Transportation fees or additional costs may be
            incurred depending on the materials orders.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">14</Typography>
          <Typography className="tit">
            Important Information Regarding Receipt of Orders by Distributors
            (RM)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            RMs are directly responsible for discussing with distributors the
            delivery of ordered materials to distributors, including delivery
            fees, and registering the order in the ERP.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">15</Typography>
          <Typography className="tit">Delivery Method and Fees</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Distributors should discuss the delivery method (procedure) and fees
            with their RMs beforehand.
            <br />
            Transportation fees or additional costs may be incurred depending on
            the materials to be delivered to distributors.
          </Typography>
          <Typography>
            If the head office is scheduled to deliver other items (e.g. MDx
            assays, instruments, etc.) to you (distributors) at the same time,
            your order of conference-related promotional materials may be packed
            and delivered with them.
            <br />
            Otherwise, your order of promotional materials will be delivered by
            the requested date.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">16</Typography>
          <Typography className="tit">
            Support Approval and Delivery Procedure
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The process of approving support for attending a conference and the
            delivery procedure are as follows:
          </Typography>
          <Typography>
            Support is requested (by distributor) &#62; Request is reviewed (by
            RM) &#62; Request is received by the responsible department (PR Team
            at the head office) &#62; Request is accepted and product designs
            are developed &#62; Distributor/RM confirm the design &#62; Products
            are manufactured &#62; Products are packed &#62; RM sends the
            products &#62; Distributor receive the products &#62; Distributor
            takes the products to the conference
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
