import {Avatar, Badge, Box, Button, Grid, makeStyles, Menu, MenuItem, Popover} from "@material-ui/core";
import axios from "axios";
import useWindowSize from "components/hook/use-window-size";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Cookies, withCookies, useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import useTranslation from "components/hook/use-translation";
import PathBar from "./path-bar";
import TranslatePopup from "./translate-popup";
import NonLoginMenu from "../file/non-login-menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


const cookies = new Cookies();
// console.log("initila", cookies);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 76,
    minWidth: 1366,
  },
  headerWrap: {
    width: "100%",
    paddingLeft: 100,
    paddingRight: 100,
    justifyContent: "space-between",
    // maxWidth: 1720,
    // marginRight: 15,
  },
  rowWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  divider: {
    height: 18.2,
    width: 0,
    border: "1px solid #cccccc",
    marginLeft: 10,
    marginRight: 10,
  },
  headerLogoTxt: {
    fontFamily: "Inter",
    fontWeight: "Bold",
    fontSize: 20,
    lineHeight: "20px",
    minWidth: 54,
    transition: "color ease 0.2s",
    "&:hover": {
      color: "#cf000e !important",
    },
    "&:visited": {
      color: "#000",
    },
  },
  nav: {
    marginLeft: 100,
    "& ul": {
      display: "flex",
      "& li": {
        // flexGrow: 1,
        marginRight: 90,
        "& a": {
          color: "#000",
          transition: "color ease 0.2s",
          "&:hover": {
            color: "#cf000e !important",
          },
        },
        "& span": {
          fontFamily: "Inter",
          fontSize: 18,
          lineHeight: "21px",
        },
      },
    },
  },
  menuActive: {
    color: "#cf000e !important",
  },
  linkButton: {
    minWidth: 147,
    height: 36,
    borderRadius: 50,
    "& >span:first-child": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",

      "& p": {
        fontFamily: "Inter",
        fontSize: 14,
        lineHeight: "14px",
      },
      "& img": {
        marginTop: 2,
        width: 5.33,
        height: 10.67,
      },
    },
  },
  menuButton: {
    cursor: "pointer",
    width: 30,
    height: 22,
    marginLeft: 30,
  },
  menuPopover: {
    "& .MuiPaper-root": {
      width: 264,

      "& .content": {
        position: "relative",
        padding: "15px 15px 43px 15px",
        "& .btn-close": {
          position: "absolute",
          right: 15,
        },
        "& .avatar-area": {
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          "& .MuiAvatar-root": {
            width: 60,
            height: 60,
          },
          "& >p": {
            marginTop: 10,
            fontSize: 20,
            fontWeight: 600,
          },
          "& .my-page": {
            marginTop: 20,
            cursor: "pointer",
            padding: "0 26px",

            "& >span": {
              position: "relative",
              fontSize: 12,
              fontWeight: 600,

              "&:before": {
                position: "absolute",
                top: -2,
                left: -24,
                content: "''",
                width: 18,
                height: 18,
                background: `url(${
                    process.env.PUBLIC_URL
                }/images/icon/icon-catg-mypage.svg)
                no-repeat`,
                backgroundPosition: "center",
              },
              "&:after": {
                position: "absolute",
                top: 2,
                right: -18,
                content: "''",
                width: 14,
                height: 14,
                background: `url(${
                    process.env.PUBLIC_URL
                }/images/icon/icon-arrow-right.svg)
                no-repeat`,
                backgroundPosition: "center",
                backgroundSize: 7,
              },
            },
          },
        },
        "& ul": {
          marginTop: 30,
          "& li": {
            marginTop: 20,
            "& p:first-child": {
              fontSize: 12,
              color: theme.palette.text.sub.gray01,
            },
            "& p:last-child": {
              marginTop: 4,
              fontSize: 14,
              fontWeight: 600,
            },
            "&:first-child": {
              marginTop: 0,
            },
          },
        },
        "& .download-count": {
          marginTop: 20,
          paddingTop: 23,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderTop: "1px solid " + theme.palette.line.gray02,

          "& .MuiBadge-root": {
            "& .MuiBadge-anchorOriginTopRightRectangle": {
              transform: "inherit",
            },
          },
        },
      },

      "& .popover-bottom": {
        padding: 8,
      },
    },
  },

}));

function PublicMenu(props) {

  const { userInfo } = useSelector((state) => state.user);

  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [nowPath, setNowPath] = useState("");

  const [expendFamilyAnchorEl, setExpendFamilyAnchorEl] = useState(null);

  const translatedTxt = useTranslation();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const handleFamilyExpendClick = (e) => {
    setExpendFamilyAnchorEl(e.currentTarget);
  };

  const handleFamilyExpendClose = () => {
    setExpendFamilyAnchorEl(null);
  };

  const handleOpenPopover = (e) => {
    setAnchorEl(e.currentTarget);
    setIsOpenPopover(true);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setIsOpenPopover(false);
  };

  const handleLogOut = (e) => {
    handleClosePopover();
    window.localStorage.removeItem("X-AUTH-TOKEN");
    window.localStorage.removeItem("X-REFRESH-TOKEN");
    window.location.href = "/login";
  };

  const handleMovePage = (url) => {
    handleClosePopover();
    props.history.push(url);
  };

  useEffect(() => {
    let path = location.pathname;
    path = path.split("/");
    setNowPath(path);
    setMenuOpen(false);
  }, [location]);

  if (!nowPath || !translatedTxt) return <></>;

  return (
    <>
      <div className={classes.container} style={{boxShadow:"0 3px 2px -2px lightgray", marginBottom:"2px"}}>
        <Grid className={classes.rowWrap + " " + classes.headerWrap}>
          <div className={classes.rowWrap}>
            <div className={classes.rowWrap}>
              <a
                href="/outsourcing/communication"
                style={{
                  width: 140,
                }}
              >
                <img
                    style={{
                      width: 140,
                    }}
                  src={`${
                    process.env.PUBLIC_URL
                  }/images/icon/sg-one-logo.png`}
                  alt="logo"
                />
              </a>
            </div>
            <Box
                className={classes.semibold}
                style={{
                  width: 10,
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingTop : "10px",
                }}
                onClick={handleFamilyExpendClick}
            >
              <Button
                  className={classes.Button}
                  style={{
                    paddingRight: 0,
                    flex: 1,
                    minWidth:"10px",
                    justifyContent: "flex-end",
                  }}
              >
              </Button>
              <ExpandMoreIcon
                  className={classes.expendButton}
                  style={{
                    transform: Boolean(expendFamilyAnchorEl)
                        ? "rotate(180deg)"
                        : "rotate(0)",
                    transition: "all 0.2s linear",
                  }}
              />
            </Box>
            <Grid className={classes.headerMenu}>
              <Grid className={classes.nav}>
                <ul>
                  <li>
                    <Link
                      to={`/outsourcing/communication`}
                      className={
                        nowPath[2] == "communication" ? classes.menuActive : ""
                      }
                    >
                      <span>Communication</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/outsourcing/outsourcing-list`}
                      className={nowPath[2] == "outsourcing-list" ? classes.menuActive : ""}
                    >
                      <span>SG-Link</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/outsourcing/my-business`}
                      className={nowPath[2] == "my-business" ? classes.menuActive : ""}
                    >
                      <span>My Business</span>
                    </Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </div>

          <Grid className={classes.rowWrap}>
            <div
                className={classes.profile}
                onClick={(e) => {
                  handleOpenPopover(e);
                }}
            >
              <Box className={classes.Avatar} style={{ cursor: "pointer" }}>
                <Badge color="primary">
                  <Avatar
                      src={
                        userInfo && userInfo.aiSeq && userInfo.aiSeq !== ""
                            ? axios.defaults.baseURL +
                            "/api/st/attach-image/get/" +
                            userInfo.aiSeq
                            : process.env.PUBLIC_URL +
                            "/images/icon/default-avatar.svg"
                      }
                  />
                </Badge>
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>
      {/*<PathBar top={0} nowPath={nowPath} translatedTxt={translatedTxt} />*/}

      <Popover
          className={classes.menuPopover}
          open={isOpenPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
      >
        <div className="content">
          <div className="btn-close">
            <Button className="btn-icon" onClick={handleClosePopover}>
              <img
                  src={`${process.env.PUBLIC_URL}/images/icon/icon-close-btn.svg`}
                  alt="Close"
              />
            </Button>
          </div>
          <div className="avatar-area">
            <Avatar
                src={
                  userInfo && userInfo.aiSeq && userInfo.aiSeq !== ""
                      ? axios.defaults.baseURL +
                      "/api/st/attach-image/get/" +
                      userInfo.aiSeq
                      : process.env.PUBLIC_URL + "/images/icon/default-avatar.svg"
                }
            />
            <p>{userInfo && userInfo.nm}</p>
            {/*<span*/}
            {/*    className="my-page"*/}
            {/*    onClick={() => {*/}
            {/*      handleMovePage(*/}
            {/*          userInfo.tp === 2*/}
            {/*              ? "/mypage/my-distributor"*/}
            {/*              : "/mypage/my-request"*/}
            {/*      );*/}
            {/*    }}*/}
            {/*>*/}
            {/*  <span>{translatedTxt["My page"]}</span>*/}
            {/*</span>*/}
          </div>
          <ul>
            <li>
              <p>
                {translatedTxt["E-mail"]}({translatedTxt.ID})
              </p>
              <p>{userInfo && userInfo.email}</p>
            </li>
            <li>
              <p>{translatedTxt.Name}</p>
              <p>{userInfo && userInfo.nm}</p>
            </li>
            <li>
              <p>{translatedTxt.Company}</p>
              <p>{userInfo && userInfo.company}</p>
            </li>
            <li>
              <p>{translatedTxt.Tel}</p>
              <p>{userInfo && userInfo.tel}</p>
            </li>
            <li>
              <p>{translatedTxt.Mobile}</p>
              {/* <p>
                {userInfo ? (userInfo.cph !== "" ? userInfo.cph : "-") : "-"}
              </p> */}
              <p>{userInfo && userInfo.cph}</p>
            </li>
            <li>
              <p>{translatedTxt.Country}</p>
              <p>{userInfo && userInfo.country}</p>
            </li>
            <li>
              <p>{translatedTxt.Authorization}</p>
              <p>
                {userInfo &&
                    (userInfo.tp === "1"
                        ? "Seegene HQ"
                        : userInfo.tp === "2"
                            ? "RM"
                            : userInfo.dstbtTp)}
              </p>
            </li>
          </ul>
        </div>
        <div className="popover-bottom">
          <Button onClick={handleLogOut}>Log out</Button>
          <span className="line" />
          <Button
              onClick={() => {
                handleMovePage(userInfo && userInfo.loginType ==="azureAD"?"/mypage/modify-infomation":"/mypage/change-my-infomation");
              }}
          >
            {translatedTxt["Change Info"]}
          </Button>
        </div>
      </Popover>
      {menuOpen && <NonLoginMenu setMenuOpen={setMenuOpen} />}
      <Menu
          id="simple-menu"
          anchorEl={expendFamilyAnchorEl}
          open={Boolean(expendFamilyAnchorEl)}
          onClose={handleFamilyExpendClose}
          style={{left:"-170px",}}
          PaperProps={{
            style: {
              left: "50%",
              transform: "translateX(10%) translateY(15%)",
              width: 200,
            },
          }}
          MenuListProps={{
            style: {
              padding: 0,
            },
          }}
      >
        <MenuItem
            onClick={() => {
              window.open("/main", "_self");
            }}
        >
          SG Archive
        </MenuItem>
        <MenuItem
            onClick={() => {
              window.open(
                  "/outsourcing/communication",
                  "_self"
              );
            }}
        >
          SG ONE
        </MenuItem>
      </Menu>
    </>
  );
}
export default withRouter(withCookies(PublicMenu));
