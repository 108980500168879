import { all, delay, fork, put, takeEvery } from "redux-saga/effects";
import * as actions from "../reducers/codeManagement";

function* loadCodeGroup({ payload }) {
  yield delay(2000);
  yield put({ type: actions.LOAD_CODEGROUP_SUCCESS });
}

function* createCodeGroup({ payload }) {
  yield delay(2000);
  yield put({ type: actions.CODEGROUP_CREATE_SUCCESS });
}

function* codeGroupSelect({ payload }) {
  yield delay(2000);
  yield put({ type: actions.LOAD_CODE });
}

function* modifyCodeGroup({ payload }) {
  yield delay(2000);
  yield put({ type: actions.CODEGROUP_MODIFY_SUCCESS });
}

function* deleteCodeGroup({ payload }) {
  yield delay(2000);
  yield put({ type: actions.DELETE_CODEGROUP_SUCCESS });
}

function* loadCode({ payload }) {
  yield delay(2000);
  yield put({ type: actions.LOAD_CODE_SUCCESS });
}

function* createCode({ payload }) {
  yield delay(2000);
  yield put({ type: actions.CODE_CREATE_SUCCESS });
}

function* modifyCode({ payload }) {
  yield delay(2000);
  yield put({ type: actions.CODE_MODIFY_SUCCESS });
}

function* deleteCode({ payload }) {
  yield delay(2000);
  yield put({ type: actions.DELETE_CODE_SUCCESS });
}

function* watchLoadCodeGroup() {
  yield takeEvery(actions.LOAD_CODEGROUP, loadCodeGroup);
}

function* watchCreateCodeGroup() {
  yield takeEvery(actions.CODEGROUP_CREATE_REQUEST, createCodeGroup);
}

function* watchModifyCodeGroup() {
  yield takeEvery(actions.CODEGROUP_MODIFY_REQUEST, modifyCodeGroup);
}

function* watchCodeGroupSelect() {
  yield takeEvery(actions.CODEGROUP_SELECT, codeGroupSelect);
}

function* watchDeleteCodeGroup() {
  yield takeEvery(actions.DELETE_CODEGROUP_REQUEST, deleteCodeGroup);
}

function* watchLoadCode() {
  yield takeEvery(actions.LOAD_CODE, loadCode);
}

function* watchCreateCode() {
  yield takeEvery(actions.CODE_CREATE_REQUEST, createCode);
}

function* watchModifyCode() {
  yield takeEvery(actions.CODE_MODIFY_REQUEST, modifyCode);
}

function* watchDeleteCode() {
  yield takeEvery(actions.DELETE_CODE_REQUEST, deleteCode);
}

export default function* codeManagementSaga() {
  yield all([
    fork(watchLoadCodeGroup),
    fork(watchCreateCodeGroup),
    fork(watchCodeGroupSelect),
    fork(watchDeleteCodeGroup),
    fork(watchLoadCode),
    fork(watchCreateCode),
    fork(watchModifyCodeGroup),
    fork(watchModifyCode),
    fork(watchDeleteCode),
  ]);
}
