import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export default function FileResources(props) {
  const classes = useStyles();

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">01</Typography>
          <Typography className="tit">About File Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            From<strong> File Resources,</strong> you can download resources in
            electronic file format. These files are classified into two groups:
            those that require the RM’s approval for download and those that may
            be downloaded immediately without such approval.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">02</Typography>
          <Typography className="tit">Available Data and Files</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Currently (as of 2017), we are providing electronic copies of
            product manuals in PDF format. We are also providing files for
            various software products, MSDS and QCMD documentation,
            certificates, videos, CI/BI materials, brochures (designers’
            versions and AI format), and graphics, banners, and posters
            (designers’ versions and AI format).
            <br />
            Not all files are available to everyone. If you cannot find the
            files you need, please contact your RM.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">03</Typography>
          <Typography className="tit">How to Order</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Click<strong> File Resources </strong>and choose and add the files
            you need to the<strong> Cart List.</strong>
            <br />
            When you request a file, your RM will decide whether to disclose
            that file to you and allow you to use it.
            <br />
            Once your RM approves your use of the file, you can download it at
            <strong> My Request History </strong>by entering the required
            password. Manuals are provided in PDF format and require a password
            for download. Please take care not to forget the password. Files
            that do not require approval for download can be downloaded simply
            by clicking the<strong> Download </strong>button.
          </Typography>
          <Typography>
            <span className="Accordion-tit">Documents (PDF) and Videos</span>
            Request a file &#62; Request is reviewed and approved by your RM
            &#62; Go to<strong> My Page &#62; My Request History </strong>&#62;
            Check approval status &#62; Download the file
          </Typography>
          <Typography>
            How to get brochures, graphics (banners and posters), and
            advertisements for printing (from head office)
            <br /> Request a file &#62; Request is reviewed and approved by RM
            &#62; Go to<strong> My Page &#62; My Request History </strong>&#62;
            Check approval status Printable files (AI format) can be sent
            through email upon request.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">04</Typography>
          <Typography className="tit">How to View Video Files</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Access to some videos files is limited, even though such files are
            produced by Seegene, in order to protect copyright or portrait
            rights.
            <br /> Videos that say “Free” can be used and distributed freely,
            both online and offline. Videos that say “Limited” cannot be
            screened at large events such as a conference, but they can be
            played for sales purposes at small meetings or seminars with fewer
            than 50 people in attendance.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">05</Typography>
          <Typography className="tit">
            How to Access Brochures, Graphics (Banners and Posters), and
            Advertisements
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Click on
            <strong> File Resource &#62; Graphic Files Request </strong>to see
            what promotional materials are available. Please contact your RM if
            you would like to change the size or customize the content of your
            selected materials according to your needs. Your RM will then ask
            the responsible department at the head office to make the requested
            modifications to the promotional materials you selected. Depending
            on your needs, the materials will be sent to you in either printed
            or electronic form.
          </Typography>
          <Typography>
            The head office has posters, graphics, X-banners, and print ads that
            can be customized to suit the needs of local distributors.
            <br /> If such materials are requested offline, a confidentiality
            agreement will need to be signed. If the request is made online,
            according to the terms and conditions of the website, a
            confidentiality agreement will not be necessary.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">06</Typography>
          <Typography className="tit">
            How to Modify/Create Promotional Materials
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The head office can provide distributors with customized graphics
            and printed promotional materials in addition to the materials
            available on this website. If you need to have existing promotional
            materials translated into your local language, please contact your
            RM. However, it is the distributor’s responsibility to have such
            materials translated into their local language.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">07</Typography>
          <Typography className="tit">
            Status of Requests for Files (History)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A list of approved file requests can be found in
            <strong> My Request History.</strong>
            Here, you can also check whether your RM has received, approved,
            rejected, or modified your request and find passwords for any files
            you may need to download.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">08</Typography>
          <Typography className="tit">
            Procedure for Approving Files Requested by Distributors
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Files can be approved in either of the following ways.
            <br />
            1. Free download: You may download and open the file without your
            RM’s approval.
            <br />
            2. Download requiring approval of RM: You may download and open the
            file only after your RM has reviewed and approved your request.
            <br />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">09</Typography>
          <Typography className="tit">Password</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Except for the files that may be downloaded freely, passwords are
            required to open downloadable files. These passwords are subject to
            change. Please make sure you acquire the passwords before
            downloading the files.
            <br />
            The passwords are provided in PDF and zip format.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">10</Typography>
          <Typography className="tit">Scope of Use</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The files you download can be used for Seegene customer service or
            sales activities. It is expected that all files will be used only
            for the purpose for which they are intended. If you wish to make
            changes to the files or use them for other purposes, please consult
            with the head office first. Without the proper permission, it is
            prohibited to use the files for other purposes or in combination
            with the products of other companies, as it may cause damage to the
            reputation of Seegene.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">11</Typography>
          <Typography className="tit">
            Consent to Terms and Conditions of Use
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Brochures and files downloaded from the website are protected by
            law. Any breach of the terms and conditions of use, such as breach
            of copyright, unauthorized distribution, or use of electronic files
            for purposes other than those for which they were intended shall be
            subject to legal punishment.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
