import React, { useEffect, useState } from "react";

export default function PathBar(props) {
  const { nowPath, translatedTxt } = props;
  const [curPath, setCurPath] = useState({});

  const makePath = (nowPath) => {
    const path = [...nowPath];
    if (path.length >= 2) {
      if (path[2] === "category-list") {
        switch (path[3]) {
          case "1":
            path[2] = "Promotion";
            break;
          case "2":
            path[2] = "Symposium";
            break;
          case "3":
            path[2] = "Interview";
            break;
          case "4":
            path[2] = "Lecture";
            break;
          case "5":
            path[2] = "Practice";
            break;
        }
        path.pop();
      } else if (path[2] === "product-type") {
        switch (path[3]) {
          case "1":
            path[2] = "Brochure";
            break;
          case "2":
            path[2] = "Gift";
            break;
          case "3":
            path[2] = "X-banner File";
            break;
          case "4":
            path[2] = "Booth Poster File";
            break;
          case "5":
            path[2] = "Advertisement";
            break;
          case "6":
            path[2] = "E-card";
            break;
        }
        path.pop();
      } else if (path[1] === "file") {
        switch (path[2]) {
          case "manual" :
            path[2] = "ifu";
            break;
        }
      }else if (path[2] === "view") path.pop();
    }
    for (let i in path) {
      path[i] = path[i].replaceAll("-", " "); // 문자열 치환
    }

    //console.log(path);
    return path;
  };

  useEffect(() => {
    const obj = {};
    for (let i in nowPath) {
      obj[i] = nowPath[i];
    }
    obj[0] = "Home";

    //console.log(nowPath);
    setCurPath(obj);
  }, [nowPath]);

  if (!translatedTxt) return <></>;

  return (
    <>
      {nowPath[1] !== "main" &&
        nowPath[1] !== "search" &&
        nowPath[1] !== "eDoc" && (
          <div
            className="header-link-page"
            key={"header-link-page" + nowPath}
            style={{
              top: props.top,
            }}
          >
            <>
              {translatedTxt["Home"]}
              {makePath(nowPath).map((item, index) => {
                // console.log("path- ", item, translatedTxt[item]);
                return (
                  <React.Fragment
                    key={"pathBar-" + index + "-" + translatedTxt[item]}
                  >
                    <p
                      style={{
                        textTransform: "capitalize",
                        fontWeight:
                          index == makePath(nowPath).length - 1 ? 600 : 400,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: translatedTxt[item],
                      }}
                    />
                    {makePath(nowPath).length - 1 !== index && (
                      <p>&nbsp;&#62;&nbsp;</p>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          </div>
        )}
    </>
  );
}
