import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export default function GeneralInfo(props) {
  const classes = useStyles();

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">01</Typography>
          <Typography className="tit">Types of Service</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The SG Archive website provides a wide range of Marketing Asset and
            notices from the head office to help distributors and their
            designated RMs (regional managers) share information and communicate
            with each other more effectively.
            <br /> Through this website, RMs may directly deliver the head
            office’s notices, depending on the subject, to specific
            distributors, and distributors can request services and deliver them
            to their RMs. The RMs will then review these Request Lists and relay
            the requests for support to the head office. Please take a look at
            the services below for more details about Request Lists.
          </Typography>
          <Typography>
            <span className="Accordion-tit">1. Information sharing</span>
            You can find official information on the head office’s policies and
            communicate with the head office through the Q&#38;A sections. All
            communication through this website is saved in the web history,
            which archives your questions and allows them to be retrieved even
            in the event of personnel changes among the staff in charge of
            managing the questions.
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              2. Gallery of and requests for promotional materials
            </span>
            This website provides a convenient overview of the promotional
            materials provided by the head office. You may also request to be
            received advertisement files, various brochures, and even poster
            graphic files, according to the designated procedure.
          </Typography>
          <Typography className="Accordion-gray">
            ※ All materials contents may be changed into your language upon
            request. Please consult with your RMs for the availability of this
            service.
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              3. Request for support to attend a local event
              (conference/exhibition)
            </span>
            If you would like to attend a local event such as conference or
            exhibition, you can request for its preparation including posters
            and other promotional materials from the head office. You may submit
            a request via the Conference section for a diverse range of support,
            which will be provided at the discretion of your RM.
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              4. Online education and training
            </span>
            If you are unable to attend the training course at head office, just
            a same training and education lecture of the basics of our products
            and technologies can be provided through online videos.
            <br /> Also those who are already completed the training for new
            hires from the head office can review the lectures here. Additional
            training videos also are provided including a simple troubleshooting
            and necessary customer support procedures.
          </Typography>
          <Typography>
            <span className="Accordion-tit">5. Archives</span>
            Printable manuals and documents (related certification documents and
            software are currently being prepared) are available for
            distributors to use. Upon request, the information and materials
            will be provided according to Seegene’s security policies and
            regulations.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">02</Typography>
          <Typography className="tit">For Members Only</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span className="Accordion-tit">
              1. Distributors (including subsidiaries)
            </span>
            Distributors may request for an accessibility of information and all
            other services provided in this website by the head office.
            Subsidiaries may directly manage their distributors with the same
            authority as RMs in head office.
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              2. RMs (including subsidiaries’ sales departments)
            </span>
            RMs have the authority to approve all requests for services and/or
            information made by their distributors or subsidiaries, and may
            deliver the head office’s notices directly to their distributors.
          </Typography>
          <Typography>
            <span className="Accordion-tit">3. Head office employees</span>
            This website has been designed to bring overseas distributors and
            the head office closer together by narrowing the information gap and
            enhancing communication. Available services for head office
            employees are limited to access to basic information. If additional
            services are required, please submit request to the departments
            responsible for such services.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">03</Typography>
          <Typography className="tit">
            How Distributors Become Members
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Distributors who wish to become a member should apply for membership
            by themselves, should not be applied on behalf of others.
            <br /> Once the application approves as a member and granted access
            to information by the website manager will be able to use our
            services.
          </Typography>
          <p style={{ color: "#9C9C9C", fontSize: 14 }}>
            ※ Employees of the head office or employees of subsidiaries
            registered on Groupware are not required to follow a separate
            membership procedure. When using the website for the first time,
            please choose and initialize a password or contact the PR team
            (website manager) to set a password.
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">04</Typography>
          <Typography className="tit">How to Cancel Membership</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Members wishing to cancel their membership can do so by logging in
            and going to My Page. The membership of employees who have resigned
            the company may be canceled by their RMs or other employees on the
            same team. When your membership is cancelled, your personal
            information (excluding your name) and the list of companies
            registered under your account will be deleted. However, the records
            of your inquiries and service use will be retained.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">05</Typography>
          <Typography className="tit">
            Sharing of Information with Distributors
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In any exchange of information, the information in question is
            shared not between individuals but to all members of distributor
            company. Inquiries and request from distributors will be reviewed
            and answered by their RMs first, and when an RM is changed, all
            information is transferred to a new RM
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">06</Typography>
          <Typography className="tit">
            Suspension of Membership and Reinstatement of Suspended Members
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Your RM can limit your (distributors’) access to this website or
            reinstate the limitation of your access, when necessary. Even in the
            event your access is restricted, information on service use and your
            personal information will not be deleted. Once your suspended
            account is reinstated, you will regain access to that information.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">07</Typography>
          <Typography className="tit">Designation of RMs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Distributors cannot designate their RMs; it is RMs who designates
            their distributors. If you have any questions about such
            designation, please contact the PR team for more details.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">08</Typography>
          <Typography className="tit">
            Dormant Accounts and Cancellation of Memberships of Inactive Users
            (Discussion Required)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            When the record shows you have not logged into the website for four
            consecutive months, your account will be placed into a dormant state
            and your access to the website will be restricted and if you stays
            in a dormant state your RM or the website manager may cancel your
            membership. The cancellation of the memberships of dormant account
            holders is conducted on a regular basis, and such members are
            contacted before their memberships are cancelled. Once your
            membership is cancelled, you will have to go through the process to
            become a member again in order to regain access to our services.
            Personal information of your previous account will not be restored
            or linked to your new account.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">09</Typography>
          <Typography className="tit">Confidentiality</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Without permission, no member is permitted to make changes to
            content provided through the website or disclose such content to
            external parties. Any arbitrary use of proprietary content or
            designs produced by Seegene may incur a licensing fee or result in a
            claim for damages. Any leakage or sharing of such content or designs
            to or with our competitors may incur legal punishment accordingly.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">10</Typography>
          <Typography className="tit">
            Limitations on Changes to Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Any change or misuse of information provided through the website is
            strictly prohibited.
            <br /> Especially, you must not change our manuals or official
            documents before using them. You may, however, suggest changes of
            such content. Any changes made to our manuals or documents without
            agreement will be subject to legal punishment.
            <br />
            When you required any changes of promotional materials to satisfy
            local needs, you may contact the head office and request that such
            changes be made. Or you change such materials yourself after receive
            an approval of changes from the head office.
            <br /> However, promotional content other than that produced by
            Seegene is not permitted to be used in Seegene’s promotional
            materials.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">11</Typography>
          <Typography className="tit">Language</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The official language to use in this website is English and all
            communication through this website should be in English.
            <br /> However, some of promotional materials in the file resource
            section may be contained materials in different languages, as
            requested by distributors.
            <br /> Such content should always be used in accordance with
            Seegene’s guidelines and rules.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
