import { createTheme } from "@material-ui/core/styles";

const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1367,
    },
  },
  palette: {
    background: {
      gray01: "#cccccc",
      gray02: "#eeeeee",
      main: "#fff",
      black: "#000",
      white: "#fff",
      sub: "#4B74B2",
    },

    primary: {
      main: "#DA291C",
      translucent: "rgba(207, 0, 14, 0.03)",
      // contrastText: "#000", 반전컬러 ex) contained버튼의 글씨 색상
    },
    secondary: {
      main: "#000000",
      sub: "#4A8FF7",
    },
    info: {
      main: "#FFB930",
      containedHover: "#e2a222",
      outlinedHover: "rgba(255, 185, 48, 0.03)",
    },
    muted: {
      main: "#CCCCCC",
    },
    text: {
      black: "#000",
      main: "#000",
      white: "#fff",
      sub: {
        gray01: "#9C9C9C",
      },
    },
    line: {
      gray01: "#cccccc",
      gray02: "#eeeeee",
    },
  },

  typography: {
    fontFamily: ["Inter"],
  },

  props: {
    MuiButtonBase: {
      disableripple: "true",
    },
    MuiButton: {
      disableripple: "true",
      disableElevation: true,
    },
    MuiInput: {
      disableripple: "true",
      disableUnderline: true,
      autoComplete: "off",
    },
    MuiInputLabel: {
      shrink: true,
      focused: false,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          "font-family": "Inter",
          "font-size": "16px",
        },
      },
    },
  },
});

const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1367,
    },
  },
  palette: {
    background: {
      gray01: "#cccccc",
      gray02: "#eeeeee",
      main: "#fff",
      black: "#000",
      white: "#fff",
      sub: "#4B74B2",
    },

    primary: {
      main: "#000",
      translucent: "rgba(0, 0, 0, 0.05)",
      // contrastText: "#000", 반전컬러 ex) contained버튼의 글씨 색상
    },
    secondary: {
      main: "#dcdcdc",
      sub: "#4A8FF7",
    },
    info: {
      main: "#FFB930",
      containedHover: "#e2a222",
      outlinedHover: "rgba(255, 185, 48, 0.04)",
    },
    muted: {
      main: "#CCCCCC",
    },
    text: {
      black: "#000",
      white: "#fff",
      sub: {
        gray01: "#9C9C9C",
      },
    },
    line: {
      gray01: "#cccccc",
      gray02: "#eeeeee",
    },
  },

  typography: {
    fontFamily: ["Inter"],
  },

  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiInput: {
      disableRipple: true,
      disableUnderline: true,
      autoComplete: "off",
    },
    MuiInputLabel: {
      shrink: true,
      focused: false,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          "font-family": "Inter",
          "font-size": "12px",
        },
      },
    },
  },
});

// const test = () => {
//   let isLight = cookies.get("isLight") === "true" ? true : false;
//   console.log(isLight);
//   if (isLight === true) {
//     return lightTheme;
//   } else {
//     return darkTheme;
//   }
// };

export { lightTheme, darkTheme };
