import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export default function Email(props) {
  const classes = useStyles();

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">01</Typography>
          <Typography className="tit">Types of Emails</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Emails are classified into four types.
            <ul>
              <li>1. Emails regarding orders and approval status</li>
              <li>
                2. Emails regarding questions and answers submitted by RMs and
                distributors
              </li>
              <li>3. Emails regarding important notices and authentication</li>
              <li>
                4. Emails providing our newsletter and the latest updates and
                news
              </li>
              <li>
                5. Emails regarding how to become a member and modify membership
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">02</Typography>
          <Typography className="tit">Additional Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Emails provide only limited information. For more information,
            please visit and log into the SG Archive website.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
