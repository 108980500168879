import CombineWithStyles from "assets/js/combine";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { default as React, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomRoute from "components/auth/custom-route";
import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";
//import { setUserInfoAction, checkWhiteUserAction } from "redux/reducers/user";
import { setUserInfoAction } from "redux/reducers/user";
import "./App.scss";
import Main from "./assets/js/main";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet-async";

ReactGA.initialize("UA-221507217-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App(props) {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.user);
  const { loginSuccess, loginResponseData } = useSelector(
      (state) => state.user
  );

  // const {
  //   userInfo,
  //   loginSuccess,
  //   loginResponseData,
  //   isWhiteUser,
  //   isWhiteUserSuccess,
  // } = useSelector((state) => state.user);

  useEffect(() => {
    if (
        location.pathname === "/educational-video/create-independent-url" ||
        location.pathname ===
        "/educational-video/create-independent-url-preview" ||
        location.pathname === "/ecampus-management/ecampus-preview"
    )
      return;
    /*로그인자리 찾기 dkpark*/
    if (
        location.pathname != "/login" &&
        location.pathname != "/login/oauth2/code/azure"
    ) {
      if (loginSuccess) {
        window.localStorage.setItem(
            "X-AUTH-TOKEN",
            `${loginResponseData.tokenType} ${loginResponseData.accessToken}`
        );

        window.localStorage.setItem(
            "X-REFRESH-TOKEN",
            `${loginResponseData.refreshToken}`
        );

        const myDecodedToken = jwt_decode(loginResponseData.accessToken);
        dispatch(setUserInfoAction(myDecodedToken));

        axios.defaults.headers.Authorization = `${
            loginResponseData.tokenType
        } ${loginResponseData.accessToken}`;
      } else {
        const accessToken = window.localStorage.getItem("X-AUTH-TOKEN");
        const refreshToken = window.localStorage.getItem("X-REFRESH-TOKEN");

        if (!accessToken && !refreshToken) {
          return;
        }
        const myDecodedToken = jwt_decode(accessToken);
        dispatch(setUserInfoAction(myDecodedToken));
        axios.defaults.headers.Authorization = accessToken;
      }
    }
  }, [loginSuccess]);

  // useEffect(() => {
  //   dispatch(checkWhiteUserAction());
  // }, []);

//  if (!isWhiteUserSuccess) return <></>;

  return (
      <div className="App">
        <BrowserRouter>
          <Router>
            <Suspense fallback={<div />}>
              <Helmet>
                <meta name="robots" content="noindex" />
                <meta name="description" content="SG Archive public website" />
                <title>SG Archive</title>
              </Helmet>
              {/*<CustomRoute userInfo={userInfo} isWhiteUser={isWhiteUser} />*/}
              <CustomRoute userInfo={userInfo} />
            </Suspense>
          </Router>
        </BrowserRouter>
      </div>
  );
}

export default CombineWithStyles(Main)(App);
