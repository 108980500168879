import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Conduct from "./utility/Conduct";
import ConductKor from "./utility/ConductKor";
import HowToUse from "./utility/how-to-use";
import Privacy from "./utility/Privacy";
import Terms from "./utility/Terms";

const useStyles = makeStyles((theme) => ({
  line: {
    display: "block",
    width: "100%",
    height: "1px",
    background: "#eee",
  },
  mainContent: {
    padding: "100px 100px 0 100px",
  },
  mainTitle: {
    padding: "0 0 40px 0",
    "& h2": {
      "font-size": "24px",
      "font-weight": "700 !important",
    },
  },
  copyright: {
    "margin-bottom": "16px",
  },
  footerinfo: {
    color: "#9c9c9c",
  },
  dialog: {
    "& .MuiDialog-container": {
      "justify-content": "flex-end",
      "align-items": "stretch",
      "& .MuiDialog-paper": {
        "max-height": "none",
        minWidth: "1150px",
        margin: "0",
        "border-radius": "0",
      },
    },
  },
  footerLeft: {
    "max-width": "45%",
    "flex-basis": "45%",
  },
  content: {
    padding: "0 100px 100px 100px ",
    position: "relative",
    overflowY: "overlay",
    "& .MuiDialogContent-root:first-child": {
      "padding-top": "40px",
    },
    "&::-webkit-scrollbar": {
      width: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      "border-radius": "9999px",
      "box-shadow": "inset 0 0 10px 10px rgba(0,0,0,0.1)",
      border: "solid 5px transparent",
    },
  },
  close: {
    "background-image": `url(${
      process.env.PUBLIC_URL
    }/images/icon/icon-list-close.svg)`,
    "background-repeat": "no-repeat",
    "background-position": "center",
    width: "30px",
    "min-width": "0",
    height: "30px",
    position: "fixed",
    top: "30px",
    right: "30px",
    "margin-right": "20px",
  },
  lang: {
    position: "absolute",
    top: "100px",
    right: "100px",
    padding: "0",
  },
  scrollTop: {
    position: "absolute",
    top: 18,
    right: 100,
  },
}));

function Footer() {
  const classes = useStyles();
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [conduct, setConduct] = useState(false);
  const [howToUse, setHowToUse] = useState(false);
  const [lang, setLang] = useState("en");

  const [ScrollY, setScrollY] = useState(0);
  const handleFollow = () => {
    setScrollY(window.pageYOffset);
  };
  const ScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setScrollY(0);
  };
  useEffect(() => {
    const watch = () => {
      window.addEventListener("scroll", handleFollow);
    };
    watch();
    return () => {
      window.removeEventListener("scroll", handleFollow);
    };
  });
  useEffect(() => {}, [ScrollY]);

  const openHowToUse = () => {
    setHowToUse(true);
  };
  const closeHowToUse = () => {
    setHowToUse(false);
  };
  const openTerms = () => {
    setTerms(true);
  };
  const closeTerms = () => {
    setTerms(false);
  };
  const openPolicy = () => {
    setPolicy(true);
  };
  const closePolicy = () => {
    setPolicy(false);
  };
  const openConduct = () => {
    setConduct(true);
  };
  const closeConduct = () => {
    setConduct(false);
  };

  return (
    <div id="footer">
      {/*<Button onClick={ScrollTop} className={classes.scrollTop}>*/}
      {/*  <img*/}
      {/*    src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-top.svg`}*/}
      {/*    alt="arrow"*/}
      {/*  />*/}
      {/*</Button>*/}
      <Grid item xs={12}>
        <Grid className={classes.footerLeft}>
          <Button>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/logo-footer-seegene-new.svg`}
              alt="logo"
            />
          </Button>
          <ul>
            {/* <Button onClick={openHowToUse}>
              <li>How to use</li>
            </Button> */}
            <Button onClick={openConduct}>
              <li>Seegene Partner Code of Conduct</li>
            </Button>
            <Button onClick={openTerms}>
              <li>Terms and Conditions</li>
            </Button>
            <Button onClick={openPolicy}>
              <li>Privacy Policy</li>
            </Button>
          </ul>
        </Grid>
        <Grid>
          <p className={classes.copyright}>
            Copyright© Seegene.Inc. All rights reserved.
          </p>
          <span className={classes.footerinfo}>
            Information contained in this website is for a wide range of
            audiences and may contain product details
            <br /> or information not accessible or valid in your country.
            Please note that we do not take any responsibility
            <br /> for accessing information that does not comply with any legal
            process, regulation, registration or usage
            <br /> in your country.
          </span>
        </Grid>
      </Grid>

      <Dialog
        className={classes.dialog}
        open={howToUse}
        onClose={closeHowToUse}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.mainContent}>
          <DialogTitle id="alert-dialog-title" className={classes.mainTitle}>
            How to use
          </DialogTitle>
          <span className={classes.line} />
          <Button onClick={closeHowToUse} className={classes.close} />
        </div>
        <DialogContent className={classes.content}>
          <HowToUse open={howToUse} />
        </DialogContent>
      </Dialog>

      <Dialog
        className={classes.dialog}
        open={terms}
        onClose={closeTerms}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.mainContent}>
          <DialogTitle id="alert-dialog-title" className={classes.mainTitle}>
            Terms and Conditions
          </DialogTitle>
          <span className={classes.line} />
          <Button onClick={closeTerms} className={classes.close} />
        </div>
        <DialogContent className={classes.content}>
          <Terms open={terms} />
        </DialogContent>
      </Dialog>

      <Dialog
        className={classes.dialog}
        open={policy}
        onClose={closePolicy}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.mainContent}>
          <DialogTitle id="alert-dialog-title" className={classes.mainTitle}>
            Privacy Policy
          </DialogTitle>
          <span className={classes.line} />
          <Button onClick={closePolicy} className={classes.close} />
        </div>
        <DialogContent className={classes.content}>
          <Privacy open={policy} />
        </DialogContent>
      </Dialog>

      <Dialog
        className={classes.dialog}
        open={conduct}
        onClose={closeConduct}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Button onClick={closeConduct} className={classes.close} />

        <DialogContent className={classes.lang}>
          <Button
            onClick={() => {
              setLang("kor");
            }}
            variant={lang == "kor" ? "contained" : "outlined"}
            color={lang == "kor" ? "primary" : ""}
          >
            한국어
          </Button>
          <Button
            onClick={() => {
              setLang("en");
            }}
            variant={lang == "kor" ? "outlined" : "contained"}
            color={lang == "kor" ? "" : "primary"}
          >
            English
          </Button>
        </DialogContent>

        {lang === "en" ? (
          <>
            <div className={classes.mainContent}>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.mainTitle}
              >
                Seegene Partner Code of Conduct
              </DialogTitle>
              <span className={classes.line} />
            </div>
          </>
        ) : (
          <>
            <div className={classes.mainContent}>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.mainTitle}
              >
                씨젠 협력사 CODE OF CONDUCT
              </DialogTitle>

              <span className={classes.line} />
            </div>
          </>
        )}
        <DialogContent className={classes.content}>
          {lang === "en" ? <Conduct /> : <ConductKor />}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Footer;
