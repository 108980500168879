import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Grid,
  Input,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  Popover,
  Toolbar,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import useWindowSize from "components/hook/use-window-size";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Cookies, withCookies, useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import useTranslation from "components/hook/use-translation";
import PathBar from "./path-bar";
import TranslatePopup from "./translate-popup";

const cookies = new Cookies();
console.log("initila", cookies);

const useStyles = makeStyles((theme) => ({
  expendButton: {
    cursor: "pointer",
    marginRight: 28,
    marginBottom: 2,
    width: 24,
    height: 24,
  },
  menuButton: {
    fontWeight: 700,
    fontSize: 18,
  },
  Button: {
    minWidth: 0,
    fontSize: 14,
    fontWeight: "600 !important",
    color: theme.palette.text.black,
    margin: "0 !important",
    height: "auto !important",
  },
  headerLogo: {
    position: "relative",
    bottom: "5px",
    flex: "0 0 6%",
  },
  headerMenu: {
    flex: "1 1 60%",
    textAlign: "center",
    flexDirection: "column",
    "& ul": {
      "& li": {
        textAlign: "left",
      },
    },
  },
  menuActive: {
    color: "#cf000e !important",
  },
  profileWrap: {
    display: "flex",
    marginTop: "-6px",
  },
  semibold: {
    alignItems: "center",
    "& img": {
      "margin-right": "5px",
    },
  },

  Collapse: {
    background: "#999",
  },
  tablet: {
    height: "135px !important",
    padding: "50px 30px 0 !important",
    boxShadow: "0px 6px 15px rgb(0 0 0 / 6%)",
  },
  Drawer: {
    "& .MuiDrawer-paper": {
      width: "480px",
      padding: "30px 60px 60px",
    },
  },
  closebutton: {
    position: "fixed",
    top: "25px",
    right: "45px",
    height: "auto",
    "min-width": "auto",
  },
  tabletMenu: {
    display: "flex",
    alignItems: "flex-start",
    paddingTop: "30px",
    "& a": {
      color: theme.palette.text.sub.gray01,
      fontSize: "16px",
      fontWeight: "500",
    },
    "& + &": {
      borderBottom: "1px solid #eee",
    },
    "& img": {
      marginRight: "10px",
      marginTop: "3px",
    },
    "& ul li": {
      paddingBottom: "30px",

      "&:last-child": {
        paddingBottom: "20px",
      },
    },
  },
  Box: {
    height: 48,
    display: "flex",
    alignItems: "center",
    border: "1px solid #f05044",
    borderRadius: "3px",
    width: "53%",
    "& .MuiInputBase-root": {
      flex: "0 0 15%",
      "& .MuiSelect-select": {
        padding: "0 0 0 20px",
        height: "100%",
        lineHeight: "60px",
        background: "#f05044",
        color: theme.palette.text.white,
        "&.MuiSelect-root:after": {
          filter: "invert(1)",
        },
        "&.MuiSelect-root[aria-expanded='true']:after": {
          filter: "initial",
        },
      },
    },
    "& .MuiInputBase-input": {
      flex: 1,
      height: "100%",
      padding: "0 20px",
      border: "none",
      borderRadius: "initial !important",
      fontSize: "18px",
      fontWeight: "700",
      "&::placeholder": {
        letterSpacing: "0",
        color: "#ccc",
        fontSize: "16px",
        fontWeight: "400",
      },
    },
    "& .search-input": {
      flex: "1",
      height: "100%",
      "& input": {
        borderRadius: "4px !important",
      },
    },
  },
  keywordBox: {
    color: "#000",
    width: "52%",
    position: "absolute",
    top: "65px",
    background: "#FFF",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    padding: "30px 20px",
    zIndex: "5",
    "& p": {
      fontSize: "14px",
      cursor: "pointer",
      "&:not(:first-child)": {
        marginTop: "30px",
      },

      "& span": {
        color: theme.palette.secondary.sub,
      },
    },
  },
  bold: {
    color: `${theme.palette.text.black} !important`,
    fontWeight: "600 !important",
  },
  profile: {
    textDecoration: "none",
    paddingBottom: `0 !important`,
  },
  menuPopover: {
    "& .MuiPaper-root": {
      width: 264,

      "& .content": {
        position: "relative",
        padding: "15px 15px 43px 15px",
        "& .btn-close": {
          position: "absolute",
          right: 15,
        },
        "& .avatar-area": {
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          "& .MuiAvatar-root": {
            width: 60,
            height: 60,
          },
          "& >p": {
            marginTop: 10,
            fontSize: 20,
            fontWeight: 600,
          },
          "& .my-page": {
            marginTop: 20,
            cursor: "pointer",
            padding: "0 26px",

            "& >span": {
              position: "relative",
              fontSize: 12,
              fontWeight: 600,

              "&:before": {
                position: "absolute",
                top: -2,
                left: -24,
                content: "''",
                width: 18,
                height: 18,
                background: `url(${
                  process.env.PUBLIC_URL
                }/images/icon/icon-catg-mypage.svg)
                no-repeat`,
                backgroundPosition: "center",
              },
              "&:after": {
                position: "absolute",
                top: 2,
                right: -18,
                content: "''",
                width: 14,
                height: 14,
                background: `url(${
                  process.env.PUBLIC_URL
                }/images/icon/icon-arrow-right.svg)
                no-repeat`,
                backgroundPosition: "center",
                backgroundSize: 7,
              },
            },
          },
        },
        "& ul": {
          marginTop: 30,
          "& li": {
            marginTop: 20,
            "& p:first-child": {
              fontSize: 12,
              color: theme.palette.text.sub.gray01,
            },
            "& p:last-child": {
              marginTop: 4,
              fontSize: 14,
              fontWeight: 600,
            },
            "&:first-child": {
              marginTop: 0,
            },
          },
        },
        "& .download-count": {
          marginTop: 20,
          paddingTop: 23,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderTop: "1px solid " + theme.palette.line.gray02,

          "& .MuiBadge-root": {
            "& .MuiBadge-anchorOriginTopRightRectangle": {
              transform: "inherit",
            },
          },
        },
      },

      "& .popover-bottom": {
        padding: 8,
      },
    },
  },
  boxShadow: {
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.06)",
    paddingBottom: "15px !important",
    transition: "none !important",
  },
  desktopSearchBox: {
    paddingBottom: 0,
    transition: "all 0.4s",
    overflow: "hidden",
  },
}));

function HeaderMenu(props) {
  const classes = useStyles();
  const { mobileView, drawerOpen } = props.viewState;
  const { setViewState } = props;
  const [cookie, setCookie] = useCookies(["globalLang"]);
  const [schTxt, setSchTxt] = useState("");
  const [keyWordList, setKeywordList] = useState([]);
  const [isActive, setActive] = useState(true);
  const [isFocus, setFocus] = useState(false);
  const [downloadCount, setDownloadCount] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [nowPath, setNowPath] = useState("");
  const [isOpenSearchBox, setIsOpenSearchBox] = useState(false);
  const [isOpenEmptyTxtAlert, setIsOpenEmptyTxtAlert] = useState(false);
  const [expendAnchorEl, setExpendAnchorEl] = useState(null);
  const [expendFamilyAnchorEl, setExpendFamilyAnchorEl] = useState(null);

  const translatedTxt = useTranslation();
  const { userInfo } = useSelector((state) => state.user);
  const history = useHistory();
  const size = useWindowSize();
  const location = useLocation();

  const handleMenuClick = () => {
    resetSchTxt();

    if (mobileView) {
      handleDrawerClose();
    } else {
      handleCloseMenu();
    }
  };

  const handleFamilyExpendClick = (e) => {
    setExpendFamilyAnchorEl(e.currentTarget);
  };

  const handleFamilyExpendClose = () => {
    setExpendFamilyAnchorEl(null);
  };

  const handleExpendClick = (e) => {
    setExpendAnchorEl(e.currentTarget);
  };

  const handleExpendClose = () => {
    setExpendAnchorEl(null);
  };

  const handleOpenPopover = (e) => {
    setAnchorEl(e.currentTarget);
    setIsOpenPopover(true);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setIsOpenPopover(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setFocus(false);
    if (schTxt === "") {
      setIsOpenEmptyTxtAlert(true);
      return;
    }
    history.push({
      pathname: "/search",
      state: {
        schTxt,
      },
    });
  };

  const handleKeywordSearch = (e, schTxt) => {
    history.push({
      pathname: "/search",
      state: {
        schTxt,
      },
    });
    setFocus(false);
  };

  const handleChangeTxt = (e) => {
    if (e.target.value) {
      printValue(e.target.value);
    } else {
      setKeywordList([]);
    }
    setFocus(true);
    setSchTxt(e.target.value);
  };

  const handleOpenMenu = () => {
    setActive(false);
  };
  const handleCloseMenu = () => {
    setActive(true);
  };

  const handleFocus = (e) => {
    setFocus(true);
  };

  const handleFocusOut = (e) => {
    setTimeout(() => {
      setFocus(false);
    }, 100);
  };

  const handleLogOut = (e) => {
    handleClosePopover();
    window.localStorage.removeItem("X-AUTH-TOKEN");
    window.localStorage.removeItem("X-REFRESH-TOKEN");
    window.location.href = "/login";
  };

  const handleMovePage = (url) => {
    handleClosePopover();
    props.history.push(url);
  };

  const handleDrawerOpen = () =>
    setViewState((prevState) => ({ ...prevState, drawerOpen: true }));

  const handleDrawerClose = () =>
    setViewState((prevState) => ({ ...prevState, drawerOpen: false }));

  const debounce = (callback, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);

      timer = setTimeout(() => callback(...args), delay);
    };
  };

  const printValue = useCallback(
    debounce((value) => {
      axios
        .get("/api/st/research/keyword", {
          params: {
            schTxt: value,
          },
        })
        .then((res) => {
          setKeywordList(res.data.data.keywordList);
        });
    }, 500),
    []
  );

  const getDownloadCount = () => {
    return new Promise((resolve) => {
      axios.get(`/api/dh/my-download-history-total-count`).then((res) => {
        resolve(Number(res.data.data.count));
      });
    });
  };

  const resetSchTxt = () => {
    setSchTxt("");
  };

  const getHighlightText = (text) => {
    if (schTxt.indexOf("\\") > -1) return text;

    const parts = text.split(new RegExp(`(${schTxt})`, "gi"));
    return parts.map((part, i) => {
      if (part.toLowerCase() === schTxt.toLowerCase()) {
        return <span key={i}>{part}</span>;
      } else {
        return part;
      }
    });
  };

  const displayDesktop = () => {
    return (
      <>
        <Grid>
          <Toolbar className={isActive ? null : "active"}>
            <Link
              to="/main"
              className={classes.headerLogo}
              onClick={handleMenuClick}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/icon/icon-logo-new.svg`}
                alt="logo"
              />
            </Link>
            { (userInfo.mbrDtlTp === "1" || userInfo.mbrDtlTp === "2" || userInfo.mbrDtlTp === "4" || userInfo.mbrDtlTp === "5") && (
              <Grid
                  className={classes.semibold}
                  style={{
                    width: 77,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  onClick={handleFamilyExpendClick}
              >
                <Button
                    className={classes.Button}
                    style={{
                      paddingRight: 0,
                      flex: "0 0 0px",
                      justifyContent: "flex-end",
                    }}
                >
                </Button>
                <ExpandMoreIcon
                    className={classes.expendButton}
                    style={{
                      transform: Boolean(expendFamilyAnchorEl)
                          ? "rotate(180deg)"
                          : "rotate(0)",
                      transition: "all 0.2s linear",
                    }}
                />
              </Grid>
            )}
            <Grid className={classes.headerMenu}>
              <Grid className={classes.nav}>
                <ul
                  className="header-menu-ul"
                  onMouseEnter={handleOpenMenu}
                  onMouseOver={handleOpenMenu}
                >
                  <li className="header-menu-list">
                    <Link
                      to={`/notice`}
                      className={
                        nowPath[1] == "notice" ? classes.menuActive : ""
                      }
                      onClick={handleMenuClick}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt.Notice_list,
                        }}
                      />
                    </Link>
                    <ul className="header-menulist-ul">
                      <li>
                        <Link
                          to={`/notice`}
                          className={
                            nowPath[1] == "notice" && !nowPath[2]
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Notice_list,
                            }}
                          />
                        </Link>
                      </li>

                      {userInfo.mbrDtlTp != "3" && (
                        <li>
                          <Link
                            to="/notice/certification"
                            className={
                              nowPath[2] == "certification"
                                ? classes.menuActive
                                : ""
                            }
                            onClick={handleMenuClick}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: translatedTxt.Certification,
                              }}
                            />
                          </Link>
                        </li>
                      )}

                      {userInfo.mbrDtlTp != "3"
                          && (
                        <li>
                        <Link
                        to="/notice/certification-in-progress"
                        className={
                        nowPath[2] == "certification-in-progress"
                        ? classes.menuActive
                        : ""
                      }
                        onClick={handleMenuClick}
                        >
                        <span
                        dangerouslySetInnerHTML={{
                        __html: translatedTxt.Certification_progress,
                      }}
                        />
                        </Link>
                        </li>
                        )}


                      {/*{userInfo.organizationName !== "" && ( userInfo.organizationName.includes("RA",0) ||*/}
                      {/*        userInfo.organizationName.includes("품질경영",0) ||*/}
                      {/*        userInfo.organizationName.includes("디지털컨텐츠") )*/}
                      {/*    && (*/}
                      {/*  <li>*/}
                      {/*    <Link*/}
                      {/*      to="/notice/certification-in-progress"*/}
                      {/*      className={*/}
                      {/*        nowPath[2] == "certification-in-progress"*/}
                      {/*          ? classes.menuActive*/}
                      {/*          : ""*/}
                      {/*      }*/}
                      {/*      onClick={handleMenuClick}*/}
                      {/*    >*/}
                      {/*      <span*/}
                      {/*        dangerouslySetInnerHTML={{*/}
                      {/*          __html: translatedTxt.Certification_progress,*/}
                      {/*        }}*/}
                      {/*      />*/}
                      {/*    </Link>*/}
                      {/*  </li>*/}
                      {/*)}*/}


                      {userInfo.tp === "4" && (
                        <li>
                          <Link
                            to="/market-insight/seegene-on-newsletter"
                            className={
                              nowPath[2] == "seegene-on-newsletter"
                                ? classes.menuActive
                                : ""
                            }
                            onClick={handleMenuClick}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: translatedTxt.Seegene_on_newsletter,
                              }}
                            />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                  <li className="header-menu-list">
                    <Link
                      to={`/documents/manual`}
                      className={nowPath[1] == "file" ? classes.menuActive : ""}
                      onClick={handleMenuClick}
                    >
                      {translatedTxt.Documents}
                    </Link>
                    <ul className="header-menulist-ul">
                      <li>
                        <Link
                          to="/documents/manual"
                          className={
                            nowPath[2] == "manual" ? classes.menuActive : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Manual,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/documents/msds"
                          className={
                            nowPath[2] == "msds" ? classes.menuActive : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.MSDS,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/documents/coa"
                          className={
                            nowPath[2] == "coa" ? classes.menuActive : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.COA,
                            }}
                          />
                        </Link>
                      </li>
                      {userInfo.tp !== "3" && (
                        <li>
                          <Link
                            to="/documents/doc"
                            className={
                              nowPath[2] == "doc" ? classes.menuActive : ""
                            }
                            onClick={handleMenuClick}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: translatedTxt.DOC,
                              }}
                            />
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link
                          to="/documents/standard-brochure"
                          className={
                            nowPath[2] == "standard-brochure"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Standard_Brochure,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/documents/quick-protocol"
                          className={
                            nowPath[2] == "quick-protocol"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Quick_Protocol,
                            }}
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="header-menu-list">
                    <Link
                      to="/marketing-asset"
                      className={
                        nowPath[1] == "marketing-asset"
                          ? classes.menuActive
                          : ""
                      }
                      onClick={handleMenuClick}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt["Marketing Asset"],
                        }}
                      />
                    </Link>
                    <ul className="header-menulist-ul">
                      <li>
                        <Link
                          to="/marketing-asset/product-type/1"
                          className={
                            nowPath[2] == "product-type" && nowPath[3] == "1"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Brochure,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/marketing-asset/product-type/4"
                          className={
                            nowPath[2] == "product-type" && nowPath[3] == "4"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Booth_poster_File,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/marketing-asset/product-type/3"
                          className={
                            nowPath[2] == "product-type" && nowPath[3] == "3"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.X_banner_File,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/marketing-asset/product-type/5"
                          className={
                            nowPath[2] == "product-type" && nowPath[3] == "5"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Advertisement,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/marketing-asset/product-type/2"
                          className={
                            nowPath[2] == "product-type" && nowPath[3] == "2"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Gift,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/marketing-asset/product-type/6"
                          className={
                            nowPath[2] == "product-type" && nowPath[3] == "6"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.E_card,
                            }}
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="header-menu-list">
                    <Link
                      to="/market-insight/seegene-on-newsletter"
                      className={
                        nowPath[1] == "market-insight" ? classes.menuActive : ""
                      }
                      onClick={handleMenuClick}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt.Market_Insight,
                        }}
                      />
                    </Link>
                    <ul className="header-menulist-ul">
                      <li>
                        <Link
                          to="/market-insight/seegene-on-newsletter"
                          className={
                            nowPath[2] == "seegene-on-newsletter"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Seegene_on_newsletter,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/market-insight/nudge-scholar"
                          className={
                            nowPath[2] == "nudge-scholar"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Nudge_Scholar,
                            }}
                          />
                        </Link>
                      </li>
                      {/*<li>*/}
                      {/*  <Link*/}
                      {/*    to="/market-insight/market-report"*/}
                      {/*    className={*/}
                      {/*      nowPath[2] == "market-report"*/}
                      {/*        ? classes.menuActive*/}
                      {/*        : ""*/}
                      {/*    }*/}
                      {/*    onClick={handleMenuClick}*/}
                      {/*  >*/}
                      {/*    <span*/}
                      {/*      dangerouslySetInnerHTML={{*/}
                      {/*        __html: translatedTxt.Market_Report,*/}
                      {/*      }}*/}
                      {/*    />*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      <li>
                        <Link
                          to="/market-insight/market-voyager"
                          className={
                            nowPath[2] == "market-voyager"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Market_Voyager,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/market-insight/competitor-profiles"
                          className={
                            nowPath[2] == "competitor-profiles"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Competitor_Profiles,
                            }}
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="header-menu-list">
                    <Link
                      to={`/videos`}
                      className={
                        nowPath[1] == "videos" ? classes.menuActive : ""
                      }
                      onClick={handleMenuClick}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt.Videos,
                        }}
                      />
                    </Link>
                    <ul className="header-menulist-ul">
                      <li>
                        <Link
                          to={`/videos/category-list/1`}
                          className={
                            nowPath[2] == "category-list" && nowPath[3] == "1"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Promotion,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/videos/category-list/2`}
                          className={
                            nowPath[2] == "category-list" && nowPath[3] == "2"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Symposium,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/videos/category-list/3`}
                          className={
                            nowPath[2] == "category-list" && nowPath[3] == "3"
                              ? classes.menuActive
                              : ""
                          }
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Interview,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/videos/category-list/4`}
                          className={
                            nowPath[2] == "category-list" && nowPath[3] == "4"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Lecture,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/videos/category-list/5`}
                          className={
                            nowPath[2] == "category-list" && nowPath[3] == "5"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Practice,
                            }}
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="header-menu-list">
                    <Link
                      to="/academic-library/article"
                      className={
                        nowPath[1] == "academic-library"
                          ? classes.menuActive
                          : ""
                      }
                      onClick={handleMenuClick}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt.Academic_Library,
                        }}
                      />
                    </Link>
                    <ul className="header-menulist-ul">
                      <li>
                        <Link
                          to="/academic-library/article"
                          className={
                            nowPath[2] == "article" ? classes.menuActive : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Article,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/academic-library/white-paper"
                          className={
                            nowPath[2] == "white-paper"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.White_Paper,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/academic-library/registration-status"
                          className={
                            nowPath[2] == "registration-status"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Registration_Status,
                            }}
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="header-menu-list">
                    <Link
                      to="/ecampus-management/course"
                      className={
                        nowPath[1] == "ecampus-management"
                          ? classes.menuActive
                          : ""
                      }
                      onClick={handleMenuClick}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt.e_Campus,
                        }}
                      />
                    </Link>
                    <ul className="header-menulist-ul">
                      <li>
                        <Link
                          to="/ecampus-management/course"
                          className={
                            nowPath[2] == "course" ? classes.menuActive : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Course,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/ecampus-management/my-class"
                          className={
                            nowPath[2] == "my-class" ? classes.menuActive : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.My_Class,
                            }}
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="header-menu-list">
                    <Link
                      to={`/conference/conference`}
                      className={
                        nowPath[1] == "conference" ? classes.menuActive : ""
                      }
                      onClick={handleMenuClick}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt.Conference,
                        }}
                      />
                    </Link>
                    <ul className="header-menulist-ul">
                      <li>
                        <Link
                          to={`/conference/conference`}
                          className={
                            nowPath[2] == "conference" ? classes.menuActive : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.List_by_Country,
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/conference/conference-support-request"
                          className={
                            nowPath[2] == "conference-support-request"
                              ? classes.menuActive
                              : ""
                          }
                          onClick={handleMenuClick}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translatedTxt.Support_Request,
                            }}
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </Grid>
            </Grid>

            <Grid className={classes.profileWrap}>
              <Box
                className={classes.semibold}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {nowPath[1] !== "" && (
                  <Button
                    className={classes.Button}
                    onClick={() => {
                      setIsOpenSearchBox(!isOpenSearchBox);
                    }}
                  >
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/images/icon/icon-search-black.svg`}
                      alt="search-icon"
                    />
                  </Button>
                )}
              </Box>
              <Box
                className={classes.semibold}
                style={{
                  width: 130,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                onClick={handleExpendClick}
              >
                <Button
                  className={classes.Button}
                  style={{
                    paddingRight: 0,
                    flex: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  {translatedTxt.SG_LINK}
                </Button>
                <ExpandMoreIcon
                  className={classes.expendButton}
                  style={{
                    transform: Boolean(expendAnchorEl)
                      ? "rotate(180deg)"
                      : "rotate(0)",
                    transition: "all 0.2s linear",
                  }}
                />
              </Box>
              <div
                className={classes.profile}
                onClick={(e) => {
                  handleOpenPopover(e);
                }}
              >
                <Box className={classes.Avatar} style={{ cursor: "pointer" }}>
                  <Badge badgeContent={downloadCount} color="primary">
                    <Avatar
                      src={
                        userInfo && userInfo.aiSeq && userInfo.aiSeq !== ""
                          ? axios.defaults.baseURL +
                            "/api/st/attach-image/get/" +
                            userInfo.aiSeq
                          : process.env.PUBLIC_URL +
                            "/images/icon/default-avatar.svg"
                      }
                    />
                  </Badge>
                </Box>
              </div>
            </Grid>
          </Toolbar>
        </Grid>
      </>
    );
  };

  const displayMobile = () => {
    return (
      <Toolbar className={classes.tablet}>
        <Link
          to="/main"
          className="header-logo"
          onClick={handleMenuClick}
          style={{
            top: -7.5,
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/icon/icon-logo-new.svg`}
            alt="logo"
          />
        </Link>
        { (userInfo.mbrDtlTp === "1" || userInfo.mbrDtlTp === "2" || userInfo.mbrDtlTp === "4" || userInfo.mbrDtlTp === "5") && (
          <Grid
              className={classes.semibold}
              style={{
                width: 77,
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={handleFamilyExpendClick}
          >
            <Button
                className={classes.Button}
                style={{
                  paddingRight: 0,
                  flex: "0 0 0px",
                  justifyContent: "flex-end",
                }}
            >
            </Button>
            <ExpandMoreIcon
                className={classes.expendButton}
                style={{
                  transform: Boolean(expendFamilyAnchorEl)
                      ? "rotate(180deg)"
                      : "rotate(0)",
                  transition: "all 0.2s linear",
                }}
            />
          </Grid>
      )}
        <div className="header-search-box mobile">
          <Box className={classes.Box} component="form" onSubmit={handleSearch}>
            <Input
              className="search-input"
              placeholder=""
              inputProps={{ "aria-label": "Search everything, anywhere" }}
              value={schTxt}
              onChange={handleChangeTxt}
              onFocus={handleFocus}
              onBlur={handleFocusOut}
            />
            <Button
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/images/icon/icon-search-red.svg`}
                alt="search"
              />
            </Button>
          </Box>
          {isFocus && keyWordList && keyWordList.length > 0 && (
            <Box className={classes.keywordBox} component="div">
              {keyWordList.map((item, index) => (
                <p
                  key={"keyWordList1-" + index}
                  onClick={(e) => {
                    handleKeywordSearch(e, item.kwd);
                  }}
                >
                  {getHighlightText(item.kwd)}
                </p>
              ))}
            </Box>
          )}
        </div>
        <Grid
          className={classes.profileWrap}
          style={{
            flex: "0 0 17%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box className={classes.semibold}>
            <Button
              className={classes.Button}
              onClick={() => {
                window.open("https://sgstats.seegene.com/landing", "_blank");
              }}
            >
              {translatedTxt.SG_STATS}
            </Button>
          </Box>
          <Box className={classes.semibold}>
            <Button
              className={classes.Button}
              onClick={() => {
                window.open("https://kms.seegene.com", "_blank");
              }}
            >
              {translatedTxt.KMS}
            </Button>
          </Box>
          <Box className={classes.semibold}>
            <Button
              className={classes.Button}
              onClick={() => {
                window.open(
                  "https://voc.seegene.com/common/action/login.jspx?cmd=doLoginPR&user_id=" +
                    userInfo.email +
                    "&name=" +
                    userInfo.nm +
                    "&rm=",
                  "_blank"
                );
              }}
            >
              {translatedTxt.VOC}
            </Button>
          </Box>
          <Box>
            <Button
              className={classes.Button}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
              style={{ padding: "0" }}
            >
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/images/icon/btn-tablet-menu.svg`}
                alt="menu"
              />
            </Button>
          </Box>
        </Grid>

        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          className={classes.Drawer}
        >
          <Button className={classes.closebutton} onClick={handleDrawerClose}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-close-btn.svg`}
              alt="close"
            />
          </Button>
          <Grid className={classes.tabletMenu}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-header-tablet-home.svg`}
              alt="home"
            />
            <Link to="/main" className={classes.bold} onClick={handleMenuClick}>
              <span
                dangerouslySetInnerHTML={{
                  __html: translatedTxt.Home,
                }}
              />
            </Link>
          </Grid>
          <Grid className={classes.tabletMenu}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-header-tablet-resources.svg`}
              alt="fileresources"
            />
            <ul>
              <li>
                <Link
                  to="/documents/manual"
                  className={classes.bold}
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Documents,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to="/documents/manual" onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Manual,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to="/documents/msds" onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.MSDS,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to="/documents/coa" onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.COA,
                    }}
                  />
                </Link>
              </li>
              {userInfo.tp !== "3" && (
                <li>
                  <Link to="/documents/doc" onClick={handleMenuClick}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: translatedTxt.DOC,
                      }}
                    />
                  </Link>
                </li>
              )}
              <li>
                <Link to="/documents/standard-brochure" onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Standard_Brochure,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to="/documents/quick-protocol" onClick={handleMenuClick}>
                  <span>
                      Quick Protocol
                  </span>
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid className={classes.tabletMenu}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-header-tablet-product.svg`}
              alt="product"
            />
            <ul>
              <li>
                <Link
                  to="/main"
                  className={classes.bold}
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt["Marketing Asset"],
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/marketing-asset/product-type/1"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Brochure,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/marketing-asset/product-type/4"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Booth_poster_File,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/marketing-asset/product-type/3"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.X_banner_File,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/marketing-asset/product-type/5"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Advertisement,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/marketing-asset/product-type/2"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Gift,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/marketing-asset/product-type/6"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.E_card,
                    }}
                  />
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid className={classes.tabletMenu}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-header-tablet-product.svg`}
              alt="product"
            />
            <ul>
              <li>
                <Link
                  to="/main"
                  className={classes.bold}
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Market_Insight,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/market-insight/seegene-on-newsletter"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Seegene_on_newsletter,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/market-insight/market-voyager"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Market_Voyager,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/market-insight/nudge-scholar"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Nudge_Scholar,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/market-insight/market-report"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Market_Report,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/market-insight/competitor-profiles"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Competitor_Profiles,
                    }}
                  />
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid className={classes.tabletMenu}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-header-tablet-videos.svg`}
              alt="videos"
            />
            <ul>
              <li>
                <Link
                  to={`/videos`}
                  className={classes.bold}
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Videos,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to={`/videos/category-list/1`} onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Promotion,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to={`/videos/category-list/2`} onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Symposium,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to={`/videos/category-list/3`} onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Interview,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to={`/videos/category-list/4`} onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Lecture,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to={`/videos/category-list/5`} onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Practice,
                    }}
                  />
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid className={classes.tabletMenu}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-header-tablet-videos.svg`}
              alt="Notice"
            />
            <ul>
              <li>
                <Link
                  to={`/notice`}
                  className={classes.bold}
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Notice,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to={`/notice`} onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Notice_list,
                    }}
                  />
                </Link>
              </li>
              {(userInfo.tp === "1" || userInfo.tp === "2") && (
                <li>
                  <Link to="/notice/certification" onClick={handleMenuClick}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: translatedTxt.Certification,
                      }}팅
                    />
                  </Link>
                </li>
              )}
              {userInfo.mbrDtlTp != "3"
                  && (
                      <li>
                        <Link
                            to="/notice/certification-in-progress"
                            onClick={handleMenuClick}
                        >
                    <span
                        dangerouslySetInnerHTML={{
                          __html: translatedTxt.Certification_progress,
                        }}
                    />
                        </Link>
                      </li>
                  )}

              {/*{userInfo.organizationName !== ""*/}
              {/*    && ( userInfo.organizationName.includes("RA",0) || userInfo.organizationName.includes("품질경영",0) ||*/}
              {/*        userInfo.organizationName.includes("디지털컨텐츠") )*/}
              {/*      && (*/}
              {/*  <li>*/}
              {/*    <Link*/}
              {/*      to="/notice/certification-in-progress"*/}
              {/*      onClick={handleMenuClick}*/}
              {/*    >*/}
              {/*      <span*/}
              {/*        dangerouslySetInnerHTML={{*/}
              {/*          __html: translatedTxt.Certification_progress,*/}
              {/*        }}*/}
              {/*      />*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*)}*/}
              <li>
                <Link
                  to="/market-insight/seegene-on-newsletter"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Seegene_on_newsletter,
                    }}
                  />
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid className={classes.tabletMenu}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-header-tablet-library.svg`}
              alt="library"
            />
            <ul>
              <li>
                <Link
                  to="/academic-library/article"
                  className={classes.bold}
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Academic_Library,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to="/academic-library/article" onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Article,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/academic-library/white-paper"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.White_Paper,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/academic-library/registration-status"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Registration_Status,
                    }}
                  />
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid className={classes.tabletMenu}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-header-tablet-ecampus.svg`}
              alt="ecampus"
            />
            <ul>
              <li>
                <Link
                  to="/main"
                  className={classes.bold}
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.e_Campus,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to="/ecampus-management/course" onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Course,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/ecampus-management/my-class"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.My_Class,
                    }}
                  />
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid className={classes.tabletMenu}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-header-tablet-videos.svg`}
              alt="Conference"
            />
            <ul>
              <li>
                <Link
                  to={`/conference/conference`}
                  className={classes.bold}
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Conference,
                    }}
                  />
                </Link>
              </li>

              <li>
                <Link to={`/conference/conference`} onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.List_by_Country,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/conference/conference-support-request"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Support_Request,
                    }}
                  />
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid className={classes.tabletMenu}>
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-header-tablet-mypage.svg`}
              alt="mypage"
            />
            <ul>
              <li>
                <Link
                  to="/main"
                  className={classes.bold}
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.My_page,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to="/main" onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.My_request,
                    }}
                  />
                </Link>
              </li>
              {userInfo.tp === "2" && (
                <li>
                  <Link to="/mypage/my-distributor" onClick={handleMenuClick}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: translatedTxt.My_distributor,
                      }}
                    />
                  </Link>
                </li>
              )}
              <li>
                <Link to="/main" onClick={handleMenuClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Download,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/mypage/change-my-infomation"
                  onClick={handleMenuClick}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Change_my_information,
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link to="/mypage/delete-account" onClick={resetSchTxt}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translatedTxt.Delete_account,
                    }}
                  />
                </Link>
              </li>
            </ul>
          </Grid>
        </Drawer>
      </Toolbar>
    );
  };

  useEffect(() => {
    let path = location.pathname;
    path = path.split("/");
    setNowPath(path);
    console.log("path: ", path);
    setIsOpenSearchBox(false);
  }, [location]);

  useEffect(async () => {
    if (userInfo) {
      const downloadCount = await getDownloadCount();
      setDownloadCount(downloadCount);
    }
  }, [userInfo]);

  useEffect(() => {
    if (size.width < 1366) {
      handleClosePopover(); // 태블릿 화면 해상도일때 모달 닫음 처리
    }
  }, [size]);

  if (!nowPath || !translatedTxt || !userInfo) return <></>;

  return (
    <>
      <AppBar className="header">
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>

      <div
        className={
          nowPath[1] === "main" || nowPath[1] === "search"
            ? "header-search-box desktop " +
              classes.desktopSearchBox +
              " " +
              classes.boxShadow
            : "header-search-box desktop " + classes.desktopSearchBox
        }
        style={{
          height:
            nowPath[1] === "main" || nowPath[1] === "search" || isOpenSearchBox
              ? 60
              : 0,
        }}
      >
        <Box className={classes.Box} component="form" onSubmit={handleSearch}>
          <Input
            className="search-input"
            placeholder=""
            inputProps={{ "aria-label": "Search everything, anywhere" }}
            value={schTxt}
            onChange={handleChangeTxt}
            onFocus={handleFocus}
            onBlur={handleFocusOut}
          />
          <Button
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-search-red.svg`}
              alt="search"
            />
          </Button>
        </Box>
        {isFocus && keyWordList && keyWordList.length > 0 && (
          <Box className={classes.keywordBox} component="div">
            {keyWordList.map((item, index) => (
              <p
                key={"keyWordList2-" + index}
                onClick={(e) => {
                  handleKeywordSearch(e, item.kwd);
                }}
              >
                {getHighlightText(item.kwd)}
              </p>
            ))}
          </Box>
        )}
        {cookie.globalLang && cookie.globalLang !== "en" && (
          <Button
            type="button"
            onClick={(e) => {
              setCookie("globalLang", "en");
            }}
            style={{
              position: "absolute",
              left: "78.5%",
            }}
          >
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-translation-gray.svg`}
              alt="category"
            />
            <p
              style={{
                color: "#9c9c9c",
                marginLeft: 6,
              }}
            >
              Show Original
            </p>
          </Button>
        )}
      </div>
      <PathBar
        top={mobileView ? 0 : 130}
        nowPath={nowPath}
        translatedTxt={translatedTxt}
      />

      <p
        className={isActive ? null : "menu-background"}
        onClick={handleCloseMenu}
      />
      <Popover
        className={classes.menuPopover}
        open={isOpenPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div className="content">
          <div className="btn-close">
            <Button className="btn-icon" onClick={handleClosePopover}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icon/icon-close-btn.svg`}
                alt="Close"
              />
            </Button>
          </div>
          <div className="avatar-area">
            <Avatar
              src={
                userInfo && userInfo.aiSeq && userInfo.aiSeq !== ""
                  ? axios.defaults.baseURL +
                    "/api/st/attach-image/get/" +
                    userInfo.aiSeq
                  : process.env.PUBLIC_URL + "/images/icon/default-avatar.svg"
              }
            />
            <p>{userInfo && userInfo.nm}</p>
            <span
              className="my-page"
              onClick={() => {
                handleMovePage(
                  userInfo.tp === 2
                    ? "/mypage/my-distributor"
                    : "/mypage/my-request"
                );
              }}
            >
              <span>{translatedTxt["My page"]}</span>
            </span>
          </div>
          <ul>
            <li>
              <p>
                {translatedTxt["E-mail"]}({translatedTxt.ID})
              </p>
              <p>{userInfo && userInfo.email}</p>
            </li>
            <li>
              <p>{translatedTxt.Name}</p>
              <p>{userInfo && userInfo.nm}</p>
            </li>
            <li>
              <p>{translatedTxt.Company}</p>
              <p>{userInfo && userInfo.company}</p>
            </li>
            <li>
              <p>{translatedTxt.Tel}</p>
              <p>{userInfo && userInfo.tel}</p>
            </li>
            <li>
              <p>{translatedTxt.Mobile}</p>
              {/* <p>
                {userInfo ? (userInfo.cph !== "" ? userInfo.cph : "-") : "-"}
              </p> */}
              <p>{userInfo && userInfo.cph}</p>
            </li>
            <li>
              <p>{translatedTxt.Country}</p>
              <p>{userInfo && userInfo.country}</p>
            </li>
            <li>
              <p>{translatedTxt.Authorization}</p>
              <p>
                {userInfo &&
                  (userInfo.tp === "1"
                    ? "Seegene HQ"
                    : userInfo.tp === "2"
                    ? "RM"
                    : userInfo.dstbtTp)}
              </p>
            </li>
          </ul>
          <div className="download-count">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/mypage/download");
                handleMenuClick();
                handleClosePopover();
              }}
            >
              {translatedTxt.Download}
            </span>
            <Badge
              badgeContent={downloadCount}
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/mypage/download");
                handleMenuClick();
                handleClosePopover();
              }}
            />
          </div>
        </div>
        <div className="popover-bottom">
          <Button onClick={handleLogOut}>{translatedTxt.Logout}</Button>
          <span className="line" />
          <Button
            onClick={() => {
              //handleMovePage("/mypage/change-my-infomation");
              handleMovePage(userInfo && userInfo.loginType ==="azureAD"?"/mypage/modify-infomation":"/mypage/change-my-infomation");
            }}
          >
            {translatedTxt["Change Info"]}
          </Button>
        </div>
      </Popover>
      <Dialog
        className={classes.dialog}
        open={isOpenEmptyTxtAlert}
        onClose={() => {
          setIsOpenEmptyTxtAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>Please enter the keyword.</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpenEmptyTxtAlert(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        id="simple-menu"
        anchorEl={expendAnchorEl}
        open={Boolean(expendAnchorEl)}
        onClose={handleExpendClose}
        PaperProps={{
          style: {
            left: "50%",
            transform: "translateX(10%) translateY(15%)",
            width: 200,
          },
        }}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            window.open("https://sgstats.seegene.com/landing", "_blank");
          }}
        >
          {translatedTxt.SG_STATS}
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(
              "https://kms.seegene.com/login/login.asp?menu_num=6",
              "_blank"
            );
          }}
        >
          {translatedTxt.KMS}
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open("https://seegene.force.com", "_blank");
          }}
        >
          Partner portal
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open("https://seegene.my.salesforce.com", "_blank");
          }}
        >
          Smart Service System
        </MenuItem>
      </Menu>
      <Menu
          id="simple-menu"
          anchorEl={expendFamilyAnchorEl}
          open={Boolean(expendFamilyAnchorEl)}
          onClose={handleFamilyExpendClose}
          style={{left:"-190px",}}
          PaperProps={{
            style: {
              left: "50%",
              transform: "translateX(10%) translateY(15%)",
              width: 200,
            },
          }}
          MenuListProps={{
            style: {
              padding: 0,
            },
          }}
      >
        <MenuItem
            onClick={() => {
              window.open("/main", "_self");
            }}
        >
          SG Archive
        </MenuItem>
        <MenuItem
            onClick={() => {
              window.open(
                  "/outsourcing/communication",
                  "_self"
              );
            }}
        >
          SG ONE
        </MenuItem>
      </Menu>
       <TranslatePopup />
    </>
  );
}
export default withRouter(withCookies(HeaderMenu));
