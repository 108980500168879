import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export default function MyPage(props) {
  const classes = useStyles();

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">01</Typography>
          <Typography className="tit">My Page (RM)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span className="Accordion-tit">1. Distributor management</span>
            You can add distributors to your list of distributors and find
            information on the staff members of your distributors. You may also
            suspend and reinstate, for legitimate reasons, your distributors’
            access to the website.
            <span className="Accordion-tit">My Page &#62; My Distributors</span>
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              2. Approval of distributors’ requests
            </span>
            RMs can approve and manage their distributors’ requests for services
            and documents, such as requests for support to attend local events,
            requests for manuals, requests for available promotional materials,
            and requests for permission to produce new promotional materials.
            <span className="Accordion-tit">
              My Page &#62; Distributors’ Requests
            </span>
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              3. History of official information shared with distributors
              (available after September)
            </span>
            The history of information exchanged between RMs and distributors
            will be restored and available for new RMs’ easier transition and
            follow ups of previous communications
            <span className="Accordion-tit">My Page &#62; Q&#38;A List</span>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">02</Typography>
          <Typography className="tit">My Page (Distributors)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span className="Accordion-tit">
              1. Status of your (distributors’) membership
            </span>
            You can check the list of the staffs under your distributors and
            make requests to cancel the membership of staff members whose
            accounts have been inactive for various reasons (e.g. staff member
            no longer works for you).
            <span className="Accordion-tit">My Page &#62; Member</span>
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              2. Status tracking of your requests submitted to RM, including
              requests for information, promotional materials, or support to
              attend a local event.
            </span>
            While using the services provided through this website, distributors
            can check the list and track the status of their requests, such as
            requests for information, support to attend a local events, and
            promotional materials. Upon your RM’s approval, your request will be
            submitted to the head office.
            <span className="Accordion-tit">
              My Page &#62; My Request History
            </span>
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              3. History of official information shared with RMs and order
              information
            </span>
            You can view a list of all your questions and its answers. You can
            also see what information has been exchanged between you and your
            RM.
            <span className="Accordion-tit">My Page &#62; Q&#38;A List</span>
            <span className="Accordion-tit">
              My Page &#62; My Request History
            </span>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">03</Typography>
          <Typography className="tit">
            Change of Personal Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If necessary, you may change the personal information that you
            provided upon becoming a member. However, please note that making
            any changes to the information on a distributor’s account will cause
            the changes of all other distributor staffs.
            <span className="Accordion-tit">My Page &#62; My Information</span>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">04</Typography>
          <Typography className="tit">Designation of an RM</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Distributors cannot designate their RMs. the RM is designated for
            the distributors, and they are responsible for providing you with
            all necessary information and deciding whether to approve your
            requests. Subsidiaries can receive such support from the Subsidiary
            Management Team of the head office. If you have any problems to
            communicate with your RM, please contact
            <a href={`mailto:marketingapps@seegene.com`}>marketingapps@seegene.com</a>.
            <span className="Accordion-tit">My Page &#62; My Page Main</span>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">05</Typography>
          <Typography className="tit">Request List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Both distributors and RMs can review your request list by category
            here. And for distributors, if the requested information or data is
            required your RM’s approval, you can track the approval status. The
            download of the relevant information or data will be available after
            receiving approval.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">06</Typography>
          <Typography className="tit">
            Approval of Orders Placed by Distributors (RM)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            RMs can review the orders that have been placed by distributors
            here. Also, the approval for downloading of the relevant information
            or data that the distributor requested can made here.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
