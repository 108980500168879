import { Paper, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    fontSize: "14px",

    "& ul": {
      marginLeft: "20px",
    },
  },
  head: {
    fontSize: "20px",
    fontWeight: "700",
    paddingTop: "40px",
  },
  cont: {
    paddingTop: "30px",
  },
  listDot: {
    "& li": {
      listStyleType: "disc",
      marginLeft: "20px",
    },
  },
  title: {
    fontWeight: "700",
    fontSize: "16px",
    padding: "30px 0 15px 0",
  },
}));

const Privacy = (props) => {
  console.log("props", props);

  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0}>
      <h4 className={classes.head}>
        1. Personal Information Management and Usage
      </h4>
      <p className={classes.cont}>
        This Privacy Policy (“Policy”) explains how Seegene, Inc. (“Seegene”,
        “we” or “us”) handles and manages personal information (including
        information related to your use of our website) that you provide to us
        on our website controlled by Seegene (including its subsidiaries and
        affiliates) which links to this Policy.
      </p>
      <ul className={classes.listDot}>
        <li>
          <p className={classes.title}>Definition of Personal Information</p>
          <p className="desc">
            Personal information means information that pertains to you,
            including your name, company address, email address, phone number or
            images, by which the individual in question can be identified. For
            the purpose of protecting and managing personal information pursuant
            to any applicable laws and regulations, including Personal
            Information Protection Act of Korea, Seegene adopts and abides by
            this Policy in handling and managing your personal information.
          </p>
        </li>
        <li>
          <p className={classes.title}>Collection of Personal Information</p>
          <p className="desc">
            This website may automatically record and collect information about
            your visits to our website in our server logs from your browser,
            including IP address, country, and the pages you request, without
            you actively submitting such unidentifiable information. This can be
            done through various technologies, such as cookies, Internet tags,
            and web beacons. Information collected by these technologies cannot
            be used to identify you without additional identifiable information.
            Seegene also collects personal information that you provide us on
            our website when you sign up in this website for the purpose of
            making a request to Seegene for materials and information relating
            to promotion, marketing, seminar, conference, symposium and
            technical training Seegene offers on the website.
          </p>
        </li>
        <li>
          <p className={classes.title}>
            Purpose of Seegene’s use of Personal Information
          </p>
          <p className="desc">
            Personal information means information that pertains to you,
            including your name, company address, email address, phone number or
            images, by which the individual in question can be identified. For
            the purpose of protecting and managing personal information pursuant
            to any applicable laws and regulations, including Personal
            Information Protection Act of Korea, Seegene adopts and abides by
            this Policy in handling and managing your personal information.
          </p>
        </li>
      </ul>

      <h4 className={classes.head}>
        2. Information Relating to Users of Our Website
      </h4>
      <p className={classes.cont}>
        For the purpose of identifying information while users use our website,
        we may collect and use the IP address of your computer to maintain
        communications with you as you move about our website and its content.
        We also collect information about your use of our website through
        cookies. We may use the data we obtain through the use of cookies: To
        improve the functioning of our website by monitoring traffic in website
        and to change information and services to meet your demand and request.
        For our business purposes, including operation of our website, research
        and product analyses to help us make available marketing and promotional
        material and better communicate with you.
      </p>
      <ul className={classes.listDot}>
        <li>
          <p className={classes.title}>
            Users That Register to Use Our Online Information and Service
          </p>
          <p className="desc">
            If you sign up to use our online information and services, we may
            also collect and use the personal information you provide us to send
            you an e-mail confirming your registration and to respond to your
            questions. Upon your request, we may also send you e-mails with
            information about our products and services including marketing and
            promotional material and software.
          </p>
        </li>
      </ul>

      <h4 className={classes.head}>3. Modification of Information</h4>
      <p className={classes.cont}>
        You may update your personal data by informing us or withdraw permission
        to use your personal data at any time by sending us an email to the
        email address specified below. For any question or suggestion regarding
        this Policy, please send an email to dataprotection@seegene.com.
      </p>

      <h4 className={classes.head}>4. Changes to this Policy</h4>
      <p className={classes.cont}>
        Seegene reserves the right to amend or update this Policy without prior
        notice to reflect legal and regulatory changes, technological advances,
        and good business practice. If Seegene changes this Policy, an updated
        Policy will reflect those changes and the effective date of the updated
        Policy will be set forth in the Policy.
      </p>
    </Paper>
  );
};

export default Privacy;
