import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as actions from "../reducers/user";

function checkWhiteUserApi() {
  return axios.get("/api/non-login/ip/is-whitelist");
}

function loginApi(payload) {
  return axios.post("/api/auth/token", {
    ...payload.loginData,
    grantType: "password",
  });
}

function refeshTokenAPI(payload) {
  return axios.post(`/api/auth/token`, {
    grantType: "refresh_token",
    refreshToken: payload,
  });
}

function changePasswordApi(payload) {
  return axios.post("/api/mb/member/change-password", {
    ...payload,
  });
}

function* checkIsWhiteUser({ payload }) {
  try {
    const response = yield call(checkWhiteUserApi, payload);
    yield put({
      type: actions.GET_WHITE_USER,
      payload: {
        isWhiteUser: response.data.data,
      },
    });
  } catch (error) {
    // window.location.href = "/login";
  }
}

function* login({ payload }) {
  try {
    const response = yield call(loginApi, payload);

    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        loginResponseData: response.data.data,
      },
    });
  } catch (error) {
    yield put({
      type: actions.LOGIN_FAILURE,
      payload: {
        loginFailReason: error.response.data.message,
      },
    });
  }
}

function* refeshTokenRequest({ payload }) {
  console.log("payload: ~~~~~~~~~~~~~~~ ", payload);

  try {
    const response = yield call(refeshTokenAPI, payload.refreshToken);

    yield put({
      type: actions.REFRESH_TOKEN_SUCCESS,
      payload: {
        loginResponseData: response.data,
      },
    });
  } catch (error) {
    yield put({
      type: actions.REFRESH_TOKEN_FAILURE,
      payload: {
        refreshTokenFailReason: error.response.data.message,
      },
    });
  }
}

function* changePassword({ payload }) {
  try {
    yield call(changePasswordApi, payload);
    console.log("payload", payload);

    // yield put({
    //   type: actions.LOGIN_REQUEST,
    //   payload: {
    //     loginData: {
    //       password: payload.password,
    //       username: payload.id,
    //     },
    //   },
    // });
  } catch (error) {
    window.location.href = "/login";
  }
}

function* watchWhiteUser() {
  yield takeEvery(actions.CHECK_WHITE_USER, checkIsWhiteUser);
}

function* watchLogin() {
  yield takeEvery(actions.LOGIN_REQUEST, login);
}

function* watchRefeshTokenRequest() {
  yield takeEvery(actions.REFRESH_TOKEN_REQUEST, refeshTokenRequest);
}

function* watchChangePassword() {
  yield takeEvery(actions.CHANGE_PASSWORD_REQUEST, changePassword);
}

export default function* userSaga() {
  yield all([
    fork(watchWhiteUser),
    fork(watchLogin),
    fork(watchChangePassword),
  ]);
}
