import { Paper, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    fontSize: "14px",
    "& li": {
      wordBreak: "keep-all",
      "& + li": {
        paddingTop: "20px",
      },
    },
  },
  top: {
    "& >h2": {
      marginTop: 20,
    },
    "& >div": {
      marginTop: 16,
      marginBottom: 16,
      border: "1px solid black",
      padding: "6px 10px",
    },
  },
  head: {
    fontSize: "20px",
    fontWeight: "700",
    padding: "40px 0 20px 0",
  },
  list: {
    "& p": {
      marginLeft: "20px",
    },
  },
  listDot: {
    paddingBottom: "10px",
    "& li": {
      listStyleType: "disc",
      marginLeft: "20px",
    },
    "& +ul": {
      paddingTop: "10px",
    },
  },
  listNum: {
    paddingBottom: "10px",
    "& li": {
      counterIncrement: "li",
      "& p": {
        marginLeft: "20px",
      },
      "&::marker": {
        content: "counter(li, decimal-leading-zero)",
        fontWeight: "700",
        fontSize: "12px",
        paddingRight: "5px",
      },
      marginLeft: "35px",
    },
  },
}));

const Terms = (props) => {
  const classes = useStyles();
  const [language, setLanguage] = useState("kor");

  const korVersion = () => {
    return (
      <Paper className={classes.root} elevation={0}>
        <div className={classes.top}>
          <h2>SG Archive에 오신 것을 환영합니다!</h2>
          <div>
            이 웹사이트는 Seegene, Inc.의 고객만을 위한 서비스로 관리됩니다. 본
            웹 사이트를 이용함으로써 귀하는 다음의 이용 약관을 준수하고 이에
            따르는 것에 동의하는 것입니다. 약관을 주의 깊게 검토해 주시기
            바랍니다. 본 약관에 동의하지 않는 경우 본 웹 사이트 및 서비스를
            이용해서는 안 됩니다.
          </div>
          <p>
            본 이용약관은 Seegene, Inc.(이하 "Seegene” 또는 “소유자"라 함)의
            웹사이트 sgarchive.seegene.com(이하 "SG Archive") 이용 및 사용에 적용되는
            약관입니다. 본 계약은 "소유자"와 귀하 간의 구속력있는 법적 계약임을
            유의하십시오.
            <br />
            본 웹 사이트는 Seegene의 자회사, 지사, 대리점, 총판 및 해당 직원에게
            홍보 자료 및 정보 자료, 교육 및 교육 자료를 제공하기 위한 것입니다.
            또한 본 웹사이트는 기타 정보 자원을 제공하고 관리하기 위한 목적으로
            운영됩니다.
            <br />
            <br />
            본 웹사이트는 사용자 등록을 통해 접속할 수 있으며, 사용자 정보는
            배포된 홍보물 및 Seegene이 고려하는 기타 목적으로 사용될 수
            있습니다. 또한, 사용자의 정보는 Seegene이 공지사항 안내 및 마케팅
            목적으로 사용할 수 있습니다.
            <br />
            <br />
            SG Archive에 제공되는 모든 서비스에 등록하면 고객("클라이언트")이
            되며, 귀하가 고객으로 있는 한 본 계약(이하 "약관")에 명시된 모든
            조항 및 조건에 구속되는 데 동의합니다.
          </p>
        </div>
        <h4 className={classes.head}>1. 데이터 개인 정보 보호.</h4>
        <ul className={classes.list}>
          <p>
            귀하의 SG Archive방문은 당사의 개인 정보 보호 정책도 적용됩니다.
            [sgarchive.seegene.com/ PrivacyPolicy]에서 개인정보 보호정책을
            검토하십시오.
          </p>
        </ul>
        <h4 className={classes.head}>2. 소유권</h4>
        <ul className={classes.list}>
          <p>
            본 SG Archive에 포함된 모든 콘텐츠는 웹 사이트 소유자의 재산이며,
            해당 저작권, 특허, 상표 및 기타 재산권에 따라 보호됩니다. 본
            계약에서 명시적으로 허용된 경우를 제외하고 귀하가 이러한 내용 또는
            SG Archive의 일부를 복사, 재 배포, 사용 또는 출판하는 것을 엄격히
            금지합니다. 귀하는 어떠한 경우에도 SG Archive의 사용에 의해 또는 SG
            Archive의 사용을 통해 콘텐츠에 대한 어떠한 소유권 또는 기타 권리를
            취득하지 않습니다.
            <br />
            <br />
            사용자가 회의, 세미나 및 판매/홍보 활동을 위한 브로셔, 배너, 포스터,
            선물 등의 인쇄 또는 디지털 버전을 포함하는 홍보 자료(Video,
            Article등)를 제작하는 경우, Seegene이 제공하거나 제공한 홍보 자료를
            이용함에 있어 이를 별도의 서면 허락을 받아야 함을 인지하고 이에
            동의합니다.
            <br />
            <br />본 웹 사이트에서 사용자는 다음 조건을 준수해야 합니다.
            사용자는 이용약관에 특별한 허가가 없는 한 홍보물을
            사용·복사·번역·보급·발행·판매·임대·서약권 설정·보안권
            설정·변경하여서는 아니 된다. 사용자는 홍보물의 지적재산권에 대한
            소유권을 표시하는 통지, 표시 또는 라벨을 제거해서는 안 됩니다.
          </p>
        </ul>
        <h4 className={classes.head}>3. 서비스 대상</h4>
        <ul className={classes.list}>
          <p>
            SG Archive는 성인 전용입니다. SG Archive는 18세 미만의 어린이를
            대상으로 하지 않습니다.
          </p>
        </ul>
        <h4 className={classes.head}>
          4. SG Archive의 개인적 및 비상업적 사용
        </h4>
        <ul className={classes.list}>
          <p>
            SG Archive는 본 계약에 달리 명시되지 않은 한 개인 및 비상업적 사용을
            위한 것입니다. 귀하는 웹 사이트 소유자의 권한이 있는 대리인의 사전
            명시적 서면 허가 없이 SG Archive가 제공하는 서비스를 상업적 목적을
            포함한 다른 목적으로 사용할 수 없습니다. 귀하는 SG Archive에서
            얻었거나 SG Archive에 연결된 모든 정보, 콘텐츠, 소프트웨어, 제품
            또는 서비스와 관련된 지적 재산권을 수정, 복사, 배포, 전송, 복제,
            출판, 라이선스, 파생 저작물 생성, 전송, 판매 또는 침해할 수
            없습니다.
          </p>
        </ul>
        <h4 className={classes.head}>5. 법률 준수</h4>
        <ul className={classes.list}>
          <p>
            귀하는 SG Archive사용에 관한 모든 관련 법률을 준수하기로 동의합니다.
            당신은 또한 당신이 제공한 정보가 진실하고 당신이 아는 한 정확하다는
            것에 동의합니다. Seegene은 필요에 따라 이용약관을 변경할 수 있으며
            개정된 이용약관은 웹사이트 및 이메일을 통하여 공지 및 안내 합니다.
          </p>
        </ul>
        <h4 className={classes.head}>6. 배상.</h4>
        <ul className={classes.list}>
          <p>
            귀하는 본 이용약관 위반으로 인하여Seegene이 입은 모든 손해에 대해
            배상하며, Seegene이 면책권을 행사하는 데 동의합니다. Seegene이 입은
            손해는 모든 결과적 손실, 손상, 판결, 보상, 비용 및 변호사 수임료를
            포함하는 데 동의합니다. 또한 귀하는 SG Archive에서 액세스한 모든
            콘텐츠 또는 정보 또는 서비스의 사용으로 인해 발생하는 제3자에 의한
            어떠한 청구로부터도 면책 당사자에게 면책권을 행사하고 무해한 상태로
            유지해야 합니다.
          </p>
        </ul>
        <h4 className={classes.head}>7. 정보 제공 및 보증</h4>
        <ul className={classes.list}>
          <p>
            이 SG Archive의 정보는 ”있는 그대로 상태” 로 제공됩니다. 사용자는 이
            SG Archive상의 정보의 사용으로 인한 문제 발생시 전적으로 사용자의
            책임이며, 모든 책임을 부담하는 것에 동의합니다. Seegene 및
            정보제공자는 이에 따라 어떠한 종류의 책임도 부담하지 않으며, 정보의
            진실성 등 그 어떠한 보증도 제공하지 않습니다. 만약 귀하가 SG
            Archive사용에 관한 본 무보증 정책에 동의하지 않으시면 해당 사이트
            이용을 중단 하여 주십시오.
          </p>
        </ul>
        <h4 className={classes.head}>8. 책임의 제한</h4>
        <ul className={classes.list}>
          <p>
            Seegene은 어떠한 경우에도 어떠한 직접적, 간접적, 부수적,
            결과적(비즈니스 손실, 이익 손실, 소송 또는 이와 유사한 손해 포함),
            법적(특별, 표준적), 징벌적 또는 기타 손해에 대해서도 책임을 지지
            않습니다. 사이트 또는 콘텐츠에 대한 불만족에 대한 유일한 구제책은
            귀하의 SG Archive사용을 모두 중단하는 것입니다.
          </p>
        </ul>
        <h4 className={classes.head}>9. 정보의 사용</h4>
        <ul className={classes.list}>
          <p>
            웹 사이트 소유자는 귀하의 SG Archive사용에 관한 모든 정보와 귀하가
            당사의 개인 정보 보호 정책에 부합하는 모든 정보를 사용하고 할당할 수
            있는 권한을 보유하며, 이에 따라 귀하는 웹 사이트 소유자에게 권한을
            부여합니다. 또한 웹 사이트 소유자는 해당 법률, 규정, 법적 절차 또는
            정부 요청을 충족하기 위해 필요한 모든 정보를 항상 공개할 수 있는
            권리를 보유합니다.
          </p>
        </ul>
        <h4 className={classes.head}>10. 수정 및 종료</h4>
        <ul className={classes.list}>
          <p>
            웹 사이트 소유자는 SG Archive 또는 SG Archive(또는 SG Archive의
            일부)가 제공하는 서비스를 통지 없이 언제든지 또는 수시로 수정 또는
            중단할 수 있는 권한을 보유합니다. 귀하는 웹 사이트 소유자가 SG
            Archive 또는 SG Archive가 제공하는 서비스의 수정, 중단 또는 중단에
            대해 귀하 또는 제3자에게 책임을 지지 않는다는 데 동의합니다.
          </p>
        </ul>
        <h4 className={classes.head}>11. Relationship.</h4>
        <ul className={classes.list}>
          <p>
            본 계약에 포함된 어떤 내용도 또는 귀하의 SG 보관소 이용도 상대방의
            파트너, 공동 투자자, 직원 또는 대리인으로서 어느 한 당사자를
            구성하는 것으로 해석되지 않으며, 어느 당사자도 이를 주장하지
            않습니다. 어느 당사자도 서면 또는 다른 방법으로 어떠한 종류의 보증,
            책임 또는 기타 의무를 발생, 인수 또는 생성할 권리나 권한이 없으며,
            양 당사자는 각 당사자가 자신의 행동에 대해 책임을 지는 독립 계약자로
            남도록 명시적 또는 묵시적 의도를 가지고 있습니다.
          </p>
        </ul>
        <h4 className={classes.head}>12. 모든 동의.</h4>
        <ul className={classes.list}>
          <p>
            Owners have the right to monitor materials posted on SG Archive, but
            they are not obligated. In addition, information that our employees
            find unpleasant or inappropriate may be deleted at Owner’s sole
            discretion.
          </p>
        </ul>
        <h4 className={classes.head}>13. 연락처.</h4>
        <ul className={classes.list}>
          <p>
            본 약관과 관련하여 질문이 있는 경우
            [marketingapps@seegene.com]로 문의하십시오.
          </p>
        </ul>
        <h4 className={classes.head}>14. 감시할 권리.</h4>
        <ul className={classes.list}>
          <p>
            웹 사이트 소유자는 SG Archive에 게시된 자료를 모니터링할 권리를
            보유하지만 의무는 아닙니다. 또한 당사 직원이 불쾌하거나 부적절하다고
            판단되는 정보를 삭제할 수 있습니다.
          </p>
        </ul>
        <h4 className={classes.head}>15. 준법.</h4>
        <ul className={classes.list}>
          <p>
            본 계약 또는 본 계약으로 인해 발생하는 모든 분쟁은 법률 상충의
            조항과 관계없이 대한민국 법에 따릅니다.
          </p>
        </ul>
        <h4 className={classes.head}>16. 분쟁</h4>
        <ul className={classes.list}>
          <p>
            당사자들은 약관 및 본 계약으로 인해 발생하는 분쟁에 대해 원만한
            해결을 위해 노력한다. 만약 당사자들이 원만한 해결에 도달할 수
            없다면, 분쟁은 조정을 위해 제출되어야 한다. 중재는
            한국상사중재위원회(KCAB)의 국제중재규칙에 따라 한국 서울에서
            진행되며 영어로 진행된다. 중재 판결은 최종적이고 당사자들에게
            구속력을 가지며 사법권을 가진 모든 법원과 함께 들어갈 수 있다.
            귀하는 SG Archive에서 제공되거나 SG Archive에 관련된 모든 서비스
            또는 조항이 이러한 청구 또는 원인에 반하는 모든 법령 또는 법률에
            관계없이 (1)년 이내에 제기되어야 한다는 데 동의합니다.
          </p>
        </ul>
        <h4 className={classes.head}>17. SEVERABILITY</h4>
        <ul className={classes.list}>
          <p>
            효력이 없는 조항이 발견되면 나머지 조항은 전면 시행된다.
            <br />
            <br />
            약관에 동의하지 않는 경우 SG Archive 서비스에 등록하지 마십시오.
            아래의 "동의함" 버튼을 클릭하면 약관을 읽고 이해했으며 약관에
            따르기로 동의한다는 것에 명시적으로 동의하는 것입니다.
          </p>
        </ul>
      </Paper>
    );
  };

  const engVersion = () => {
    return (
      <Paper className={classes.root} elevation={0}>
        <div className={classes.top}>
          <h2>Welcome to SG Archive!</h2>
          <div>
            This website is managed as a service only for customers of Seegene,
            Inc. By using this website, you agree to comply with and comply with
            the following terms and conditions. Please review the terms and
            conditions carefully. If you do not agree to these terms and
            conditions, you should not use these websites and services.
          </div>
          <p>
            These Acceptable Use Policy Terms and Conditions (hereinafter
            referred to as "Policy") stipulate the terms and conditions of use
            of the website, <strong>sgarchive.seegene.com</strong> (hereinafter referred
            to as "SG Archive") and describe the terms and conditions applicable
            to the use of SG Archive. Please note that this Policy is a legal
            contract between Seegene, Inc. (hereinafter referred to as either
            “Seegene” or "Owner") and you. Except for the "Seegene eDoc" page that is available to the public,
            this website is intended to provide
            promotional materials, information materials, training and training
            materials to Seegene's subsidiaries, branches, distributors, and
            their employees. In addition, this website is operated for the
            purpose of providing and managing other information resources.
            <br />
            <br />
            This website can be accessed through user registration, and user
            information can be used for the purposes of distribution of
            promotional materials and other purposes considered by Seegene. In
            addition, user information can be used by Seegene for notification
            and marketing purposes.
            <br />
            <br />
            Registering for all services provided on SG Archive will make you a
            “user” and agree to be bound by this Policy.
          </p>
        </div>
        <h4 className={classes.head}>1. Data privacy.</h4>
        <ul className={classes.list}>
          <p>
            Seegene’s privacy policy shall govern your use of SG Archive. Review
            Seegene’s privacy policy at [sgarchive.seegene.com/PrivacyPolicy].
          </p>
        </ul>
        <h4 className={classes.head}>2. Ownership.</h4>
        <ul className={classes.list}>
          <p>
            All content contained in this SG Archive is the property of Seegene
            and is protected under its copyright, patents, trademarks, and other
            property rights. Except as expressly permitted in this Policy, we
            strictly prohibit you from copying, redistributing, using, or
            publishing any of these contents or any part of the SG Archive. You
            do not, in any case, acquire any ownership or other interest in the
            content from SG Archive or through the use of SG Archive.
            <br />
            <br />
            When users create promotional materials (Video, Article, etc.) that
            include print or digital versions of brochures, banners, posters,
            gifts for meetings, seminars and sales/promotion activities from SG
            Archive, such user shall enter into a separate license agreement for
            user’s use of the content derived from SG Archive.
            <br />
            <br />
            On this website, users must comply with the following conditions:
            Users shall not use, copy, translate, distribute, issue, sell,
            lease, pledge rights, establish or change promotional materials
            unless there is a special permission under a separate license
            agreement. Users shall not remove notices, marks, or labels
            indicating ownership of intellectual property rights of promotional
            materials.
          </p>
        </ul>
        <h4 className={classes.head}>3. Intended Audience.</h4>
        <ul className={classes.list}>
          <p>
            SG Archive is for adults only. SG Archive is not intended for
            children under the age of 18.
          </p>
        </ul>
        <h4 className={classes.head}>
          4. Personal and non-commercial use of SG Archive.
        </h4>
        <ul className={classes.list}>
          <p>
            SG Archive is for personal and non-commercial use purposes only
            unless otherwise specified in a separate license agreement. You may
            not use the services provided by SG Archive for any other purpose,
            including commercial purposes, without prior explicit written
            permission from an authorized representative of the website Owner.
            You may not modify, copy, distribute, transmit, duplicate, publish,
            license, create, transfer, sell, or infringe any intellectual
            property rights related to information, content, software, products
            or services obtained or linked to SG Archive.
            <br />
            Users may not distribute, transfer or use promotional materials for
            purposes other than Seegene's instructions, which are provided only
            for the purpose of promoting cooperation with Seegene.
          </p>
        </ul>
        <h4 className={classes.head}>5. Compliance with the law.</h4>
        <ul className={classes.list}>
          <p>
            You agree to comply with all relevant laws regarding the use of SG
            Archive. You also agreed that the information you provided was true
            and accurate. Seegene can change the terms and conditions as
            necessary, and the revised terms and conditions will be notified and
            guided through the website and e-mail.
          </p>
        </ul>
        <h4 className={classes.head}>6. Indemnification.</h4>
        <ul className={classes.list}>
          <p>
            You agree to indemnify and hold the Owner, its subsidiaries,
            affiliates, licensees, content providers, service providers,
            employees, agents, executives, directors and contractors
            (hereinafter referred to as "Indemnified Parties") harmless from all
            claims, damages and related costs, including legal fees, arising out
            of your non-compliance with any of your commitments under this
            Policy.
          </p>
        </ul>
        <h4 className={classes.head}>7. Disclaimer.</h4>
        <ul className={classes.list}>
          <p>
            INFORMATION ON THIS SG ARCHIVE IS PROVIDED ON AN "AS IS" BASIS,
            WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR
            IMPLIED INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OR CONDITIONS
            OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
            PARTICULAR PURPOSE. Each user is solely responsible for determining
            the appropriateness of using information provided under SG Archive
            and assumes all risks associated with its exercise of rights under
            this Policy, including but not limited to the risks and costs of
            information errors, compliance with applicable laws, damage to or
            loss of data, programs or equipment, and unavailability or
            interruption of operations.
          </p>
        </ul>
        <h4 className={classes.head}>8. Limitation of liability.</h4>
        <ul className={classes.list}>
          <p>
            In no event shall Owner be liable for any direct, indirect,
            incidental, consequential (including business loss, loss of profit,
            litigation or similar damages), legal (special exemplary), punitive,
            or other damages whatsoever. You assume all risks and liabilities
            resulting from the use of the information provided under SG Archive.
          </p>
        </ul>
        <h4 className={classes.head}>9. Use of information.</h4>
        <ul className={classes.list}>
          <p>
            The Owner has the right to use and allocate all information about
            your use of SG Archive and all information that conforms to our
            privacy policy, and you hereby authorize the Owner for such
            activities. The Owner also reserves the right to disclose all
            necessary information to meet applicable laws, regulations, legal
            procedures, or government requests.
          </p>
        </ul>
        <h4 className={classes.head}>10. MODIFICATION AND TERMINATION</h4>
        <ul className={classes.list}>
          <p>
            The Owner reserves the right to modify or discontinue services
            provided by SG Archive at any time or from time to time without
            notice. You agree that the Owner is not liable to you or a third
            party for any modification, interruption or interruption of services
            provided by SG Archive.
          </p>
        </ul>
        <h4 className={classes.head}>11. Relationship.</h4>
        <ul className={classes.list}>
          <p>
            Nothing contained in this Policy or the use of your SG archive is
            construed as constituting either party as a partner, co-investor,
            employee or agent of the other party, and neither party asserts it.
            Neither party has the right or authority to create, take over, or
            otherwise create any kind of guarantee, liability, or other
            obligation, and both parties have an explicit or implied intention
            to ensure that each party remains an independent contractor
            responsible for its actions.
          </p>
        </ul>
        <h4 className={classes.head}>12. Entire agreements.</h4>
        <ul className={classes.list}>
          <p>
            These terms and conditions constitute a complete agreement between
            you and the Owner, and replace all prior or simultaneous
            communications and proposals between you and the Owner in connection
            with SG Archive. Notwithstanding the foregoing, you may be subject
            to additional terms and conditions, published policies (including
            privacy policies, not limited), guidelines, or rules that apply when
            using SG Archive. The Owner can revise these terms and conditions at
            any time by renewing this Policy and posting it to SG Archive.
            Therefore, you should visit SG Archive to periodically review the
            terms and conditions to see if there are any changes. Continuing to
            use this SG Archive even after the terms and conditions have been
            changed means your consent to the change or amendment of the terms
            and conditions of this Policy.
          </p>
        </ul>
        <h4 className={classes.head}>13. Contact.</h4>
        <ul className={classes.list}>
          <p>
            If you have any questions regarding these terms and conditions,
            please contact at [marketingapps@seegene.com].
          </p>
        </ul>
        <h4 className={classes.head}>14. Right to monitor.</h4>
        <ul className={classes.list}>
          <p>
            Owners have the right to monitor materials posted on SG Archive, but
            they are not obligated. In addition, information that our employees
            find unpleasant or inappropriate may be deleted at Owner’s sole
            discretion.
          </p>
        </ul>
        <h4 className={classes.head}>15. Governing law</h4>
        <ul className={classes.list}>
          <p>
            Any dispute arising from the use of SG Archive or this Policy shall
            be governed by the laws of the Republic of Korea without application
            of its conflict of laws principles.
          </p>
        </ul>
        <h4 className={classes.head}>16. Disputes</h4>
        <ul className={classes.list}>
          <p>
            The parties endeavor to resolve the terms and conditions and
            disputes arising from the use of SG Archive or this Policy smoothly.
            If the parties cannot reach a smooth resolution, the dispute must be
            submitted for arbitration to be conducted in Seoul, Korea, in
            accordance with the International Arbitration Rules of the Korea
            Commercial Arbitration Commission (KCAB) and shall be conducted in
            English unless both parties agree to be conducted in Korean
            language. The arbitration ruling is final, binding on the parties,
            and can enter with all courts with jurisdiction.
            <br />
            You agree that all services or provisions provided by SG Archive or
            related to SG Archive must be filed within (1) year, regardless of
            all statutes or laws contrary to these claims or causes.
          </p>
        </ul>
        <h4 className={classes.head}>17. Severablity</h4>
        <ul className={classes.list}>
          <p>
            If any provision of this Policy is declared invalid by any court or
            government agency, all other provisions shall remain in full force
            and effect.
            <br />
            <br />
            If you do not agree to the terms and conditions of this Policy, do
            not register with the SG Archive service. Click the "Agreed" button
            below to explicitly agree that you have read, understood, and agreed
            to comply with the terms and conditions of this Policy.
          </p>
        </ul>
      </Paper>
    );
  };

  return <>{language === "eng" ? korVersion() : engVersion()}</>;
};

export default Terms;
