import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

export default function PrProducts(props) {
  const classes = useStyles();

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">01</Typography>
          <Typography className="tit">Orderable Materials</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span className="Accordion-tit">1. Promotional brochures</span>
            As a rule, all brochures are printed out and delivered; they are not
            provided as electronic files.
          </Typography>
          <Typography>
            <span className="Accordion-tit">1. Promotional materials</span>
            You can order promotional materials that you would like to use for
            your sales activities.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">02</Typography>
          <Typography className="tit">How to Order Brochures</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Click on<strong> Promotional Materials</strong>, look through the
            different types of promotional materials (in the form of prints),
            and order the ones you want. The quantity of an order for
            promotional materials that has already been placed can be changed in
            the<strong> Cart List</strong>.<br /> (Printed promotional materials
            for use at a local conference can be ordered by going to the
            Conference section and providing the requested information on the
            conference to be attended.)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">03</Typography>
          <Typography className="tit">
            How to Order Promotional Materials
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Click on<strong> Promotional Materials </strong>and then
            <strong> Promotional Items </strong>to see the different types of
            items and how many are in stock. If the head office has the items
            you want in stock, it will send them to you.
            <br /> (Promotional materials for use at a local conference can be
            ordered by going to the<strong> Conference </strong>section and
            providing the requested information on the conference to be
            attended.)
            <br /> The quantity of an order for promotional materials that has
            already been placed can be changed in the
            <strong> Cart List.</strong>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">04</Typography>
          <Typography className="tit">
            How to Check Order Status and RM’s Approval of an Order
            (Distributors)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Your order for promotional materials will be placed on the
            <strong> Cart List</strong>, and the status of your order can be
            checked in<strong> My Page &#62; My Request History.</strong>
            <br /> All orders require prior consultation with and the approval
            of your RM.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">06</Typography>
          <Typography className="tit">
            Rejection or Cancellation of an Order
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Orders placed a distributor can be rejected by their RM or manager,
            following a review. A distributor may discuss any rejected order
            with their RM and seek to have it placed again or modified. If you
            would like to cancel or edit an order that has already been placed,
            you need to contact your RM via email or phone. In addition to
            orders placed by distributors, RMs can also reject or cancel orders
            that they themselves have placed.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">07</Typography>
          <Typography className="tit">Delivery Fee and Procedure</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The delivery fee and procedure for orders should be discussed with
            the RMs.
            <br /> If the head office is scheduled to deliver other items (e.g.
            MDx assays, instruments, etc.) to you, your order can be packed and
            delivered together with such items. Otherwise, your order will be
            delivered by the requested date.
          </Typography>
          <Typography>
            * Delivery procedure
            <br />
            Promotional materials and their quantities are decided (by
            distributors) &#62; Delivery of items is approved and payment means
            is decided (by RM) &#62; Order is sent to the team in charge (Digital marketing team) &#62; Requested promotional materials are packed
            &#62; RMs send the materials &#62; Distributors receive the
            materials
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
