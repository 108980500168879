import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  line: {
    display: "block",
    width: "100%",
    height: "1px",
    background: "#eee",
  },
  mainContent: {
    padding: "100px 100px 0 100px",
  },
  content: {
    padding: "0",
    "&:first-child": {
      padding: "0",
      "&>div": {
        display: "flex",
        "justify-content": "space-between",
      },
    },
    "& h2": {
      "font-size": "20px",
      "font-weight": "bold",
    },
  },
  mainTitle: {
    padding: "0 0 40px 0",
    "& h2": {
      "font-size": "24px",
      "font-weight": "bold",
    },
  },
  title: {
    padding: "50px 0 30px",
  },
  primarytitle: {
    padding: "0px 0 30px",
    color: "#cf000e",
  },
  list: {
    "font-size": "14px",
    color: "#000",
    "margin-bottom": "0",
    width: "90%",
    "& li": {
      display: "flex",
      "margin-bottom": "30px",
      "&:first-child": {
        "margin-bottom": "40px",
      },
      "&:last-child": {
        "margin-bottom": "0",
      },
      "& .num": {
        flex: "0 0 1.5%",
        color: "#000",
        "font-weight": "bold",
        margin: "2px 15px 0 0",
      },
      "& .title": {
        "font-size": "16px",
        "font-weight": "bold",
        "padding-bottom": "15px",
      },
    },
  },
  box: {
    width: "110%",
    padding: "40px",
    margin: "30px 0 0px",
    border: "1px solid #ccc",
    "border-radius": "30px",
    color: "#000",
    "font-size": "14px",
    "& ul": {
      width: "90%",
      "& li:not(:last-child)": {
        "margin-bottom": "15px",
      },
    },
    "& .small-dot": {
      flex: "0 0 1.5%",
      "margin-top": "9px",
      "background-image": `url(${
        process.env.PUBLIC_URL
      }/images/icon/icon-list-dot.svg)`,
      "background-repeat": "no-repeat",
    },
  },
  boxtitle: {
    color: "#000",
    padding: "0 0 20px 0",
    "&:not(:first-child)": {
      "margin-top": "40px",
    },
    "& h2": {
      "font-size": "16px",
    },
  },
}));

const Conduct = (props) => {
  //   console.log("props", props);

  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.primarytitle}>
          MISSION OF SEEGENE’S PARTNER
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <ul>
            <li>
              <div>
                To bring a healthier life for humanity and provide high-quality
                products and services to the world.
              </div>
            </li>
            <li>
              <span className="num">I.</span>
              <div>
                <div className="title">Targeting Quality and Safety</div>
                <div className="text">
                  Seegene aims to develop and produce high-quality products to
                  improve the public health of the world. As our products’
                  quality is closely related to patient’s diagnosis and
                  treatment, it is crucial to be accurate and precise in all
                  respect. Preventing misdiagnosis is our most important goal.
                  Working with Seegene, Seegene’s Partner (“Partner”) shares the
                  same value and objectives. Therefore, Partner has a duty to
                  put quality and safety as priorities.
                </div>
              </div>
            </li>
            <li>
              <span className="num">II.</span>
              <div>
                <div className="title">
                  Interacting with Healthcare Professionals
                </div>
                <div className="text">
                  To achieve our mission to enhance the healthcare system,
                  Seegene interacts with many healthcare professionals.
                  Healthcare professionals’ assistance is at the heart of our
                  success.
                  <br />
                  <br />
                  Partner has a mission to protect healthcare professionals’
                  rights and avoid any illegitimate interaction with healthcare
                  professionals. Partner shall not provide tangible or
                  intangible benefits to healthcare professionals against any
                  applicable laws and regulations. Partner must understand that
                  higher ethical standards will be required for business
                  practices in the healthcare industry. Thus, Partner has a duty
                  to understand and comply with national rules and regulations
                  of the countries in which Partner operates.
                </div>
              </div>
            </li>
            <li>
              <span className="num">III.</span>
              <div>
                <div className="title">Promoting and Marketing</div>
                <div className="text">
                  Our customers’ benefit is the best value we can create.
                  Partner must try one’s best to meet our customers’ needs by
                  providing clear and accurate information about our products
                  and services. Moreover, it is a Partner’s duty to avoid unfair
                  marketing practices and comply with any and all applicable
                  rules and regulations. Remember that providing wrong
                  information and using unfair marketing strategies can impair
                  patients’ health.
                </div>
              </div>
            </li>
          </ul>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle
          id="alert-dialog-title"
          className={classes.primarytitle}
          style={{ paddingTop: 70 }}
        >
          PARTNER CODE OF CONDUCT
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            Seegene pursues ethical and responsible management and is committed
            to doing business in compliance with all applicable laws and
            regulations. Accordingly, Partner must also comply with all
            applicable laws, regulations, and ethical standards.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Partner must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Implement a code of conduct or any other appropriate
                  compliance program which reflects applicable laws and
                  regulations and Provide regular training regarding major
                  compliance issues for all employees;
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Understand the contents of this Code of Conduct and explain
                  the contents to all employees, including dispatched workers
                  and management level workers; and
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  In case of any violation of rules or regulations, promptly
                  report the fact to Seegene and actively find a solution plan.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          1. Human Rights and Labor
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <ul>
            <li>
              <div>
                Seegene respects the various international initiatives related
                to human rights that have been selected and announced by
                international organizations regarding general human rights, such
                as the International Labor Organization (ILO) Fundamental
                Conventions, UN Guiding Principles on Business and Human Rights,
                Universal Declaration of Human Rights announced by the UN, etc.
              </div>
            </li>
          </ul>
          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Partner must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Not hire a child that is younger than the minimum working age.
                  The minimum working age, working hours, working conditions
                  shall be complied for youth labor.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Prohibit all labor against a worker’s will, such as forced
                  labor, sweated labor, etc. Write an employment contract that
                  specifies the clear labor conditions in the native language of
                  the worker.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Prohibit discrimination based on race, skin color, age,
                  gender, sexual orientation, origin, nationality, disability,
                  pregnancy, religion, political orientation, unionization,
                  marital status, etc. regarding the employment practices, such
                  as recruitment, promotion, compensation, provision of
                  opportunity for education, etc.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Treat all workers respectfully, and form a work environment
                  free of inhumane treatment or threat, such as sexual
                  harassment, abuse, punishment, mental or physical pressure,
                  etc.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Comply with all legal standards regarding the maximum working
                  hours and days, minimum wage, welfare and compensation, etc.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Acknowledge the freedom of association and right of collective
                  bargaining. Form an environment in which a worker can
                  communicate with the management regarding working conditions
                  without fear of discrimination, retaliation, intimidation, or
                  harassment.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Take prevention measures required by law, such as assessment
                  and removal of risks, consistent education, emergency reaction
                  training, provision of personal protection equipment, etc. to
                  enable the workers to work in a safe, healthy working
                  environment.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>
      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          2. Sustainability of Environment
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <ul>
            <li>
              <div>
                Seegene complies with all applicable laws and regulations
                regarding the environment and pursues environmental management.
                Partners shall comply with all applicable laws and regulations
                regarding the environment accordingly.
              </div>
            </li>
          </ul>
          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Partner must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Acquire, maintain, and renew all mandatory environmental
                  approval and registration. Make best efforts to minimize all
                  negative impacts on the environment and society preemptively,
                  such as toxic substances, waste, waste water, air pollution,
                  etc. that are generated from the manufacturing process.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Execute a life cycle assessment regarding the production,
                  product, and transport in case Seegene makes a reasonable
                  request and provide environmental data supporting the
                  assessment.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Make best efforts to expand the use of renewable energy in the
                  production of products.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Prohibit the use of raw material acquired through illegal,
                  unethical, or inappropriate method that can incur health or
                  safety risk, or environmental destruction, such as water
                  resource depletion, pollution, etc.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>
      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          3. Anti-Corruption and Bribery
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <ul>
            <li>
              <div>
                Seegene strongly prohibits any form of corruption and bribery.
                This means that Seegene’s Partner must avoid and prevent any
                practice that can lead up to corruption or bribery.
              </div>
            </li>
            <li>
              <div>
                <div className="title">3-1. Bribery</div>
                <div className="text">
                  Bribery is one form of corruption that Seegene never
                  tolerates. In most countries, bribery is a criminal offense.
                  Seegene prohibits any act of bribery or dealings that can be
                  seen as bribery. Bribery is direct and indirect offering,
                  giving or soliciting of any tangible or intangible benefits
                  with intention to influence the action of counterparts. Bribes
                  can include, but not limited to, gifts, money, stocks,
                  hospitality, and facilitation payments. Partners must comply
                  with any applicable bribery act of the countries in which they
                  operate. Moreover, Partners must not create an appearance of
                  impropriety in regards to bribery issues.
                </div>
              </div>
            </li>
            <li>
              <div>
                <div className="title">
                  3-2. Gifts, Hospitality and other Benefits
                </div>
                <div className="text">
                  In many countries, it is pretty common to give gifts and host
                  entertainment events while doing business with partners.
                  However, according to bribery acts of many countries, the
                  offer of gifts and entertainment is bribery when such actions
                  may influence the recipient’s decision-making process. Partner
                  has a duty to prevent any possible harm that can be caused by
                  gifts, hospitality, and other benefits.
                </div>
              </div>
            </li>
            <li>
              <div>
                <div className="title">3-3. Travel Expenses</div>
                <div className="text">
                  In certain circumstances, companies may be required to pay
                  third-party’s travel expenses for business transactions.
                  However, excessive reimbursements may inappropriately
                  influence the recipient or at least create the appearance of
                  influence. Therefore, travel expenses must be of a reasonable
                  amount.
                  <br />
                  <br />
                  When companies need to reimburse or pay travel expenses,
                  including but not limited to flights, accommodations, and
                  meals, Partners must be careful and should not pay an
                  excessive amount. You must not create an appearance of
                  impropriety.
                </div>
              </div>
            </li>
            <li>
              <div>
                <div className="title">
                  3-4. Sponsorship, Donations, Contributions, and Memberships
                </div>
                <div className="text">
                  To fulfill social responsibility, many companies spend money
                  or offer other benefits in the form of sponsorship or
                  donation. However, charitable contributions and community
                  investment shall not be used as a channel of bribery.
                </div>
              </div>
            </li>
            <li>
              <div>
                <div className="title">3-5. Involvement of Third Parties</div>
                <div className="text">
                  While doing business, third parties can be involved for
                  various reasons. However, Partner must not use third parties
                  as agents to solicit any kind of illegal acts, including
                  bribery. Furthermore, we expect our Partner to monitor third
                  parties’ past and present activities to prevent the
                  involvement of corruption.
                </div>
              </div>
            </li>
          </ul>
          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Partner must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Avoid using funds when the purpose of the use is unclear or
                  illegitimate.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Make payments by following applicable procedures and with
                  clear documentation.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Maintain control system internally to accurately book and
                  report any payments made.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Never offer, promise to give, or express your intent to give
                  bribes to business partners or other individuals who are in a
                  business relationship with you.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Also make sure to prevent any corruption or bribery act when
                  dealing with a government official.
                </div>
              </li>
            </ul>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Gifts, hospitality and other benefits must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Be appropriate in terms of value, type, and frequency to the
                  occasion and the recipient’s position.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Be transparent and accurately recorded in the company’s books
                  and records.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Never give the appearance of dishonesty or inappropriateness.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Not be offered, provided, demanded, solicited, or accepted
                  with the expectation of any advantage.
                </div>
              </li>
            </ul>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Sponsorships, donations, charitable
              contributions, and memberships must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Not be offered or received when it implies any illegitimate
                  objectives.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Not be used as a channel of bribery. It must be thoroughly
                  examined whether there is a clear and legitimate purpose.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          4. Respect Fair Competition, Antitrust Laws and Intellectual Property
          Rights
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            Seegene acknowledges the importance of government regulations of the
            countries in which we operate. Therefore, we require Partners to
            comply with all relevant laws and regulations, especially with fair
            competition and antitrust regulations.
            <br />
            <br />
            Antitrust laws strictly prohibit monopolization by outlawing a price
            agreement, bid-rigging, and customer allocation among competitors.
            Contracts or any other practices that restrain fair competition of
            the market are also outlawed. In addition to this, Seegene prohibits
            Partner from abusing a dominant position.
            <br />
            <br />
            Also, it is very important to protect intellectual property rights.
            Partner must put your best effort into the protection of
            intellectual property rights, such as copyrights, patent,
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Partner must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Be aware of any applicable antitrust or fair competition law.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Avoid making any decision that can be seen as a price
                  agreement, bid-rigging, or customer allocation.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Not infringe anyone’s intellectual property rights and those
                  of Seegene.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  In case of any confusion, actively seek legal assistance.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Promptly report any violation, if any, to Seegene and
                  cooperate with the authorities when investigated.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          5. Awareness of Conflicts of Interest
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            Partner must avoid transactions with employees of Seegene,
            customers, or government entities that might conflict, or appear to
            conflict, with their ability to act objectively in the sale or
            distribution of Seegene products or provisions of services to or on
            behalf of Seegene. Conflicts of interest may arise in many ways.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Partner must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Not employ, offer to employ, or make payments to a Seegene
                  customer or a customer’s employee during the course of any
                  transaction on behalf of Seegene.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Disclose to Seegene that the fact that Partner’s employee is a
                  family relation to a Seegene customer or government official
                  or Partner has any other relationship with a Seegene customer
                  or government official that might represent a conflict of
                  interest.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Be careful with putting your personal interest in our business
                  relationship.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Implement internal control systems or measures to manage
                  conflicts of interest problems.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Report any conflicts of interest issue to Seegene as soon as
                  it is spotted.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          6. Anti-Money Laundering and Terrorism Financing
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            Financial crime, such as money laundering or terrorist financing,
            can be detrimental to our business. Therefore, Seegene strictly
            prohibits any act that can lead up to financial crimes.To prevent
            such risk, Seegene maintains business relationships with Partner if
            and only if Partner complies with legal requirements and meets
            Seegene’s ethical standards. One of the key standards Seegene
            carefully examines in this regard is the financial structure of
            Partner.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Partner must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Answer to Seegene’s inquiries and provide required documents,
                  if necessary, to verify your financial structure and economic
                  background.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>Keep accurate and transparent financial records.</div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Monitor any suspicious business practice or relationship of
                  your employees and any relevant third parties and promptly
                  report to Seegene and any applicable government authorities.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Never solicit any act that can lead up to financial crimes.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          7. Respect Data Privacy
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            Seegene is committed to building trust with customers. In order to
            retain public confidence, the protection of personal data is very
            important, especially in the healthcare industry. Partner is
            required to understand and comply with data protection laws.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Partner must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Clarify the purpose of collection whenever you collect and
                  process personal data.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Be aware of the risk of data leakage and implement an
                  appropriate control system internally.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Whenever a data leakage issue is raised in connection with
                  Seegene business, promptly inform Seegene of the fact and seek
                  legal solutions without delay.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Never transfer personal data to a third party without the
                  prior consent of the individual.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>Seek legal support if needed.</div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Never collect more personal data than needed for an intended
                  purpose.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          8. Observe Export Control and Customs Regulations
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            Seegene is a global company that has customers all over the world.
            To meet all the needs of customers, Seegene must comply with customs
            and export regulations. Seegene expects Partner to be aware of both
            national and international embargo system and comply with the
            applicable rules and regulations. Especially in the case of foreign
            trade, it is Partner’s duty to check updated regulations of each
            country. Export control can regulate not only the export of goods
            but also the export of software and technology.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Partner must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Research national and international rules of customs, export,
                  and movement of technology.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Keep an internal control system to manage and record movements
                  of goods and technology.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  If required, obtain a license or certificate and do record
                  keeping before exporting goods or technology.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          9. Accurate Reporting &amp; Accounting
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            Seegene values integrity and accuracy. To keep a mutual trust
            relationship with our business partners and customers, we keep
            honest and accurate records in all respects. Furthermore, we are
            putting extra effort into accounting transparency by hiring
            trustworthy accountants.Likewise, Seegene expects Partner to do
            business with honesty and integrity. First of all, Partner must
            comply with relevant laws and accounting standards. It is Seegene
            and Partner’s duty to deliver trustworthy information to customers,
            investors, and the public. Any documents Partner provides to Seegene
            or open to the public must include only true facts. Especially in
            publication record, you must exclude any false or misleading
            information as it may lead you to a criminal accusation.
          </div>

          <DialogContentText className={classes.box}>
            <DialogTitle id="alert-dialog-title" className={classes.boxtitle}>
              We expect Partner to : Partner must
            </DialogTitle>
            <ul>
              <li>
                <span className="small-dot" />
                <div>
                  Implement and manage internal documentation and accounting
                  system that you can trust.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Include only true, fair, and complete information in public or
                  non-public records.
                </div>
              </li>
              <li>
                <span className="small-dot" />
                <div>
                  Be aware of the fact that any misrepresentation of fact or
                  forgery can end up with a criminal accusation.
                </div>
              </li>
            </ul>
          </DialogContentText>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle
          id="alert-dialog-title"
          className={classes.primarytitle}
          style={{ paddingTop: 60 }}
        >
          Reaching Out: Contacting Point
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            If there is any question regarding this Partner Code of Conduct or
            any other ethical issues, employees of Partner must contact their
            company first and seek internal solution following the company’s
            procedure.Partner has a duty to encourage one’s employees or
            directors to report violations in any circumstances. Partner must
            use reasonable effort to protect the anonymity of the informant and
            should not give any disadvantage to the informant. Partner must
            investigate violations or suspicious events thoroughly. Last but not
            least, if Partner knows or believes that Seegene’s employees or
            agents have committed any illegal or unethical act, it should
            promptly report the matter to Seegene.
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogContent className={classes.content}>
        <DialogTitle
          id="alert-dialog-title"
          className={classes.primarytitle}
          style={{ paddingTop: 60 }}
        >
          How do I contact the Compliance Team of Seegene?
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.list}
        >
          <div>
            You are encouraged to reach out to the Compliance Team of Seegene
            directly through winwinseegene@seegene.com
          </div>
        </DialogContentText>
      </DialogContent>
    </>
  );
};

export default Conduct;
