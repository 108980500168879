import React from "react";

// 독립 URL 미리보기
const CreateIndependentUrl = React.lazy(() =>
  import("../pages/educational-video/create-independent-url")
);
const CreateIndependentUrlPreview = React.lazy(() =>
  import("../pages/educational-video/create-independent-url-preview")
);

// MarketInsight>MarketVoyager
const MarketVoyager = React.lazy(() =>
  import("../pages/market-insight/market-voyager")
);
// MarketInsight>MarketReport
const MarketReport = React.lazy(() =>
  import("../pages/market-insight/market-report")
);
// MarketInsight>CompetitorProfiles
const CompetitorProfiles = React.lazy(() =>
  import("../pages/market-insight/competitor-profiles")
);
// MarketInsight>NudgeScholar
const NudgeScholar = React.lazy(() =>
  import("../pages/market-insight/nudge-scholar")
);

// Documents
const DocumentsManual = React.lazy(() => import("../pages/documents/manual"));
const DocumentsMSDS = React.lazy(() => import("../pages/documents/msds"));
const DocumentsCOA = React.lazy(() => import("../pages/documents/coa"));
const DocumentsDOC = React.lazy(() => import("../pages/documents/doc"));
const DocumentsStandardBrochure = React.lazy(() =>
  import("../pages/documents/standard-brochure")
);
const DocumentsQuickProtocol = React.lazy(() =>
  import("../pages/documents/quick-protocol")
);

// Files
const FileMain = React.lazy(() => import("../pages/file/main"));
const FileManual = React.lazy(() => import("../pages/file/manual"));
const FileMSDS = React.lazy(() => import("../pages/file/msds"));
const FileCOA = React.lazy(() => import("../pages/file/coa"));
const FileStandardBrochure = React.lazy(() =>
  import("../pages/file/standard-brochure")
);

// Marketing Asset
const Product = React.lazy(() => import("../pages/marketing-asset"));
const ProductType = React.lazy(() =>
  import("../pages/marketing-asset/product-type")
);

// Notice
const Notice = React.lazy(() => import("../pages/notice"));
const Certification = React.lazy(() => import("../pages/notice/certification"));
const CertificationInProgress = React.lazy(() =>
  import("../pages/notice/certification-in-progress")
);
const SeegeneOnNewsletter = React.lazy(() =>
  import("../pages/market-insight/seegene-on-newsletter")
);

// Academic Library
const Article = React.lazy(() => import("../pages/academic-library/article"));
const WhitePaper = React.lazy(() =>
  import("../pages/academic-library/white-paper")
);
const RegistrationStatus = React.lazy(() =>
  import("../pages/academic-library/registration-status")
);

// ECAMPUS
const Course = React.lazy(() => import("../pages/ecampus-management/course"));
const MyClass = React.lazy(() =>
  import("../pages/ecampus-management/my-class")
);
const MyClassDetail = React.lazy(() =>
  import("../pages/ecampus-management/my-class-detail")
);
const MyClassVideo = React.lazy(() =>
  import("../pages/ecampus-management/my-class-video")
);
const EcampusPreview = React.lazy(() =>
  import("../pages/ecampus-management/ecampus-preview")
);

// conference
const ConferenceListByCountry = React.lazy(() =>
  import("../pages/conference/conference")
);
const ConferenceSupportRequest = React.lazy(() =>
  import("../pages/conference/conference-support-request")
);

// 로그인
const Login = React.lazy(() => import("../pages/login"));

const ChangePassword = React.lazy(() =>
  import("../pages/login/change-password")
);

//비디오
const Videos = React.lazy(() => import("../pages/videos/index"));
const Promotion = React.lazy(() => import("../pages/videos/promotion"));
const VideoView = React.lazy(() => import("../pages/videos/view"));
const CategoryList = React.lazy(() => import("../pages/videos/category-list"));

// MyPage
const MyDistributor = React.lazy(() =>
  import("../pages/mypage/my-distributor")
);
const AddDistributor = React.lazy(() =>
  import("../pages/mypage/add-distributor")
);
const MyRequest = React.lazy(() => import("../pages/mypage/my-request"));
const Download = React.lazy(() => import("../pages/mypage/download"));

const ChangeMyInfomation = React.lazy(() =>
  import("../pages/mypage/change-my-infomation")
);
const ModifyInfomation = React.lazy(() =>
  import("../pages/mypage/modify-infomation")
);
const DeleteAccount = React.lazy(() =>
  import("../pages/mypage/delete-account")
);

// 검색 페이지
const Search = React.lazy(() => import("../pages/search"));

// sg one
const SGOneComm = React.lazy(() => import("../pages/outsourcing/communication"));
const SGOneOutsourcingList = React.lazy(() => import("../pages/outsourcing/outsourcing-list"));
const SGOneMyBusiness = React.lazy(() => import("../pages/outsourcing/my-business"));
const SGOneOutsourcingWrite = React.lazy(() => import("../pages/outsourcing/outsourcing-write"));
const SGOneMyBusinessOrder = React.lazy(() => import("../pages/outsourcing/order-detail"));

// 가이드
const Guide = React.lazy(() => import("../pages/guide/guide"));
const Home = React.lazy(() => import("../pages/home"));
const Button = React.lazy(() => import("../pages/guide/button"));
const Form = React.lazy(() => import("../pages/guide/form"));
const Table = React.lazy(() => import("../pages/guide/table"));
const Tabs = React.lazy(() => import("../pages/guide/tabs"));
const Category = React.lazy(() => import("../pages/guide/category"));
const Skeletons = React.lazy(() => import("../pages/guide/skeletons"));
const OidCallback = React.lazy(() => import("../pages/login/oidCallback"));

const Routes = [
  // 홈
  {
    name: "Home",
    path: "/main",
    component: Home,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },

  // 로그인
  {
    name: "로그인",
    path: "/login",
    component: Login,
    auth: false,
    menu: false,
    footer: true,
  },

  // 비밀번호 변경
  {
    name: "비밀번호 변경",
    path: "/change-password",
    component: ChangePassword,
    auth: false,
    menu: false,
    footer: true,
  },

  // 독립 URL 미리보기
  {
    name: "독립 URL 생성관리 비디오 재생",
    path: "/educational-video/create-independent-url",
    component: CreateIndependentUrl,
    auth: false,
    menu: false,
    footer: false,
  },
  {
    name: "독립 URL 생성관리 비디오 재생 미리보기",
    path: "/educational-video/create-independent-url-preview",
    component: CreateIndependentUrlPreview,
    auth: false,
    menu: false,
    footer: false,
  },

  // Documents
  {
    name: "메뉴얼",
    path: "/documents/manual",
    component: DocumentsManual,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "MSDS",
    path: "/documents/msds",
    component: DocumentsMSDS,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "COA",
    path: "/documents/coa",
    component: DocumentsCOA,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "DOC",
    path: "/documents/doc",
    component: DocumentsDOC,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2"],
  },
  {
    name: "StandardBrochure",
    path: "/documents/standard-brochure",
    component: DocumentsStandardBrochure,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "QuickProtocol",
    path: "/documents/quick-protocol",
    component: DocumentsQuickProtocol,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },

  // Files
  {
    name: "메인",
    path: "/eDoc",
    component: FileMain,
    auth: false,
    menu: true,
    footer: false,
    edocFooter: true,
    checkWhiteList: true,
  },
  {
    name: "메뉴얼",
    path: "/file/manual",
    component: FileManual,
    auth: false,
    menu: true,
    footer: false,
    edocFooter: true,
    checkWhiteList: true,
  },
  {
    name: "MSDS",
    path: "/file/msds",
    component: FileMSDS,
    auth: false,
    menu: true,
    footer: false,
    edocFooter: true,
    checkWhiteList: true,
  },
  {
    name: "COA",
    path: "/file/coa",
    component: FileCOA,
    auth: false,
    menu: true,
    footer: false,
    edocFooter: true,
    checkWhiteList: true,
  },
  {
    name: "StandardBrochure",
    path: "/file/standard-brochure",
    component: FileStandardBrochure,
    auth: false,
    menu: true,
    footer: false,
    edocFooter: true,
    checkWhiteList: true,
  },

  // Marketing Asset
  {
    name: "Product",
    path: "/marketing-asset",
    component: Product,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "ProductType",
    path: "/marketing-asset/product-type/:type",
    component: ProductType,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },

  // Video
  {
    name: "Videos",
    path: "/videos",
    component: Videos,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Promotion",
    path: "/videos/promotion",
    component: Promotion,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "CategoryList",
    path: "/videos/category-list/:category",
    component: CategoryList,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "VideoView",
    path: "/videos/view/:seq",
    component: VideoView,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },

  // Notice
  {
    name: "Notice",
    path: "/notice",
    component: Notice,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Certification",
    path: "/notice/certification",
    component: Certification,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2"],
  },
  {
    name: "CertificationInProgress",
    path: "/notice/certification-in-progress",
    component: CertificationInProgress,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1","2","3","4","5"],
  },
  {
    name: "SeegeneOnNewsletter",
    path: "/market-insight/seegene-on-newsletter",
    component: SeegeneOnNewsletter,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  // Conference
  {
    name: "ConferenceListByCountry",
    path: "/conference/conference",
    component: ConferenceListByCountry,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "ConferenceSupportRequest",
    path: "/conference/conference-support-request",
    component: ConferenceSupportRequest,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },

  // Library
  {
    name: "Academic Library",
    path: "/academic-library/article",
    component: Article,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Academic materials",
    path: "/academic-library/white-paper",
    component: WhitePaper,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Status",
    path: "/academic-library/registration-status",
    component: RegistrationStatus,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },

  // Market Insight
  {
    name: "SeegeneOnNewsletter",
    path: "/market-insight/seegene-on-newsletter",
    component: SeegeneOnNewsletter,
  },
  {
    name: "Market Voyager",
    path: "/market-insight/market-voyager",
    component: MarketVoyager,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Nudge Scholar",
    path: "/market-insight/nudge-scholar",
    component: NudgeScholar,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Market Report",
    path: "/market-insight/market-report",
    component: MarketReport,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Competitor Profiles",
    path: "/market-insight/competitor-profiles",
    component: CompetitorProfiles,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },

  // ECAMPUS
  {
    name: "Course",
    path: "/ecampus-management/course",
    component: Course,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "My Class",
    path: "/ecampus-management/my-class",
    component: MyClass,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "My Class 상세",
    path: "/ecampus-management/my-class-detail",
    component: MyClassDetail,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "My Class Video 시청 화면",
    path: "/ecampus-management/my-class-video",
    component: MyClassVideo,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Ecampus 미리보기",
    path: "/ecampus-management/ecampus-preview",
    component: EcampusPreview,
  },

  // MyPage
  {
    name: "My Distributor",
    path: "/mypage/my-distributor",
    component: MyDistributor,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Add Distributor",
    path: "/mypage/add-distributor",
    component: AddDistributor,
    auth: true,
    menu: true,
    footer: true,
    tp: ["2"],
  },
  {
    name: "My Request",
    path: "/mypage/my-request",
    component: MyRequest,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Download",
    path: "/mypage/download",
    component: Download,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Change My Infomation",
    path: "/mypage/change-my-infomation",
    component: ChangeMyInfomation,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Change My Infomation",
    path: "/mypage/modify-infomation",
    component: ModifyInfomation,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Delete Account",
    path: "/mypage/delete-account",
    component: DeleteAccount,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },

  // 검색 페이지
  {
    name: "Search",
    path: "/search",
    component: Search,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },

  // 가이드페이지
  {
    name: "Guide",
    path: "/guide",
    component: Guide,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
    checkWhiteList: true,
  },
  {
    name: "Button",
    path: "/guide/button",
    component: Button,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Form",
    path: "/guide/form",
    component: Form,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Tabs",
    path: "/guide/tabs",
    component: Tabs,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Table",
    path: "/guide/table",
    component: Table,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Category",
    path: "/guide/category",
    component: Category,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "Skeletons",
    path: "/guide/skeletons",
    component: Skeletons,
    auth: true,
    menu: true,
    footer: true,
    tp: ["1", "2", "3"],
  },
  {
    name: "OidCallback",
    path: "/login/oauth2/code/azure",
    component: OidCallback,
    auth: false,
    footer: false,
  },
  // SG ONE COMM
  {
    name: "SG ONE Communication",
    path: "/outsourcing/communication",
    component: SGOneComm,
    auth: true,
    sgOne: true,
    menu: true,
    footer: false,
    edocFooter: true,

  },
  {
    name: "SG ONE Outsourcing list",
    path: "/outsourcing/outsourcing-list",
    component: SGOneOutsourcingList,
    auth: true,
    sgOne: true,
    menu: true,
    footer: false,
    edocFooter: true,

  },
  {
    name: "SG ONE My Business",
    path: "/outsourcing/my-business",
    component: SGOneMyBusiness,
    auth: true,
    sgOne: true,
    menu: true,
    footer: false,
    edocFooter: true,
  },
  {
    name: "SG ONE My Business",
    path: "/outsourcing/outsourcing-write",
    component: SGOneOutsourcingWrite,
    auth: true,
    sgOne: true,
    menu: true,
    footer: false,
    edocFooter: true,
  },
  {
    name:"",
    path: "/outsourcing/my-business/order/:seq",
    component: SGOneMyBusinessOrder,
    auth: true,
    sgOne: true,
    menu: true,
    footer : false,
    edocFooter : true,
  },
];
export default Routes;
