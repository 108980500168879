import { CssBaseline } from "@material-ui/core/";
import { ThemeProvider } from "@material-ui/styles";
import axios from "axios";
import { createContext, default as React } from "react";
import { Cookies } from "react-cookie";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { lightTheme } from "Theme";
import App from "./App";
import GlobalStyles from "./assets/js/global";
import rootReducer from "./redux/reducers";
import rootSaga from "./redux/sagas";
import reportWebVitals from "./reportWebVitals";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { HelmetProvider } from "react-helmet-async";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
const cookies = new Cookies();

sagaMiddleware.run(rootSaga);
// console.log("process.env", process.env);

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.headers.Authorization = window.localStorage.getItem(
  "X-AUTH-TOKEN"
);

const refreshTokenAPI = () => {
  console.log("refreshTokenAPI");

  return new Promise((resolve, reject) => {
    axios
      .post(`/api/auth/token`, {
        grantType: "refresh_token",
        refreshToken: window.localStorage.getItem("X-REFRESH-TOKEN"),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log("error: ", error);
        let retUrl = "";
        if(window.location.path){
              retUrl = "?prev="+window.location.path;
              if(window.location.search){
                retUrl += window.location.search;
              }
        }
        window.location.href = "/login"+retUrl;
        reject(null);
      });
  });
};

axios.interceptors.response.use(
  (config) => {
    //console.log("config: ", config);
    return config;
  },
  async (error) => {
    console.log("error: ==================================== ", error);
    console.log("error.response.data.status: ", error.response?.data.status);
    if (error.response?.data.status == 401) {
      if (
        window.location.pathname ===
          "/educational-video/create-independent-url" ||
        window.location.pathname ===
          "/educational-video/create-independent-url-preview"
      )
        return;

      const newToken = await refreshTokenAPI();
      if (!newToken) return;

      console.log("window", window.location);

      window.localStorage.setItem(
        "X-AUTH-TOKEN",
        `${newToken.data.tokenType} ${newToken.data.accessToken}`
      );

      window.localStorage.setItem(
        "X-REFRESH-TOKEN",
        `${newToken.data.refreshToken}`
      );

      axios.defaults.headers.Authorization = `${newToken.data.tokenType} ${
        newToken.data.accessToken
      }`;

      error.config.headers.Authorization = `${newToken.data.tokenType} ${
        newToken.data.accessToken
      }`;

      return axios.request(error.config);
    }
    return Promise.reject(error);
  }
);

const customTheme = () => {
  let isLight = cookies.get("isLight");
  return lightTheme;
  // if (isLight === "true") {
  //   return lightTheme;
  // } else {
  //   return darkTheme;
  // }
};
export const rootContext = createContext();
const apiKey = "dj0t0m0cbm8a0bqhu9gyez5hnm9mr8fuq66d3nvzcc5pahmr";

const instance = createInstance({
  urlBase: "https://sgarchive.seegene.com",
  siteId: 9,
  userId: "undefined", // optional, default value: `undefined`.
  trackerUrl: "https://seegenepr.com/piwik/piwik.php", // optional, default value: `${urlBase}matomo.php`
  srcUrl: "https://seegenepr.com/piwik/piwik.js", // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});

function render() {
  const rootElement = document.getElementById("root");

  if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(
      <MatomoProvider value={instance}>
        <Provider store={store}>
          <ThemeProvider theme={customTheme()}>
            <CssBaseline />
            <GlobalStyles />
            <rootContext.Provider value={{ apiKey }}>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </rootContext.Provider>
          </ThemeProvider>
        </Provider>
      </MatomoProvider>,
      document.getElementById("root")
    );
  } else {
    ReactDOM.render(
      <MatomoProvider value={instance}>
        <Provider store={store}>
          <ThemeProvider theme={customTheme()}>
            <CssBaseline />
            <GlobalStyles />
            <rootContext.Provider value={{ apiKey }}>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </rootContext.Provider>
          </ThemeProvider>
        </Provider>
      </MatomoProvider>,
      document.getElementById("root")
    );
  }
}

render();
store.subscribe(render);
reportWebVitals();
