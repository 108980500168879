import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  fileDownload: {
    cursor: "pointer",
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "flex-start",
    "& img": {
      width: 24,
      height: 24,
    },
    "& span": {
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.secondary.sub,
    },
  },
}));

export default function Notice(props) {
  const classes = useStyles();

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">01</Typography>
          <Typography className="tit">
            공지 배포 가이드 다운로드 (RM / 법인영업)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            SG Archive 사이트 공지 시스템을 이용 시 참고하실 수 있는 '공지 배포
            가이드'문서입니다.
            <br /> 오른쪽 버튼을 클릭하여 PDF파일을 다운로드할 수 있습니다.
            <br /> 추가적으로 문의 사항이 있으실 경우 홍보팀으로 연락 바랍니다.
          </Typography>

          <a
            href={`${axios.defaults.baseURL}/api/st/attach-file/pdf/4021`}
            target="_blank"
            className={classes.fileDownload}
          >
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/icon/icon-download-blue.svg`}
            />
            <span>PDF file Download</span>
          </a>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">02</Typography>
          <Typography className="tit">Types of Notices</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Notices issued by the head office are classified into five different
            categories.
          </Typography>
          <Typography>
            <span className="Accordion-tit">1. Notices regarding products</span>
            Notices regarding launches, approvals, changes/updates, and
            discontinuations of sales of Seegene products.
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              2. Notices regarding instruments
            </span>
            Notices regarding launches, approvals, change/updates, and
            discontinuations of sales of instruments Seegene provides.
          </Typography>
          <Typography>
            <span className="Accordion-tit">3. Notices regarding software</span>
            Notices regarding changes/updates of software provided by Seegene,
            including the Seegene Launcher and Seegene Viewer.
          </Typography>
          <Typography>
            <span className="Accordion-tit">
              4. Notices regarding website operation
            </span>
            Notices regarding changes to or issues concerning the operation of
            this website.
          </Typography>
          <Typography>
            <span className="Accordion-tit">5. Notices regarding policies</span>
            All other notices including any issues, and the sales and promotion
            policies of Seegene.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">03</Typography>
          <Typography className="tit">
            Popup Notices on the Main Page
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Notices regarding issues of which all employees should be aware will
            be posted at the top of the website’s main page for a specific
            period of time by the person responsible for such notices. Members
            may choose to have notices that they have already read not appear
            again on the website.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">04</Typography>
          <Typography className="tit">
            Authority Regarding Distribution of Notices (RM)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            RMs have the authority to select which notices are to be distributed
            to their distributors. Furthermore, each RM has the authority to
            make changes to the list of their distributors, receive and approve
            requests from their distributors, and deliver information and
            notices from the head office to distributors to ensure they get the
            support they need.
            <span className="Accordion-tit">
              My Page (RM View) &#62; My Distributors
            </span>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">05</Typography>
          <Typography className="tit">Distribution of Notices (RM)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Depending on the approval of RMs, certain notices may be delivered
            to only certain distributors. An RM may select the distributors to
            which they wish to deliver a specific notice by clicking
            <strong> Send to the Selected Target.</strong> Please double check
            when you select a target list since the unselected distributors will
            be unable to read some important notice.
            <span className="Accordion-tit">Notice &#62; List (RM View)</span>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">06</Typography>
          <Typography className="tit">
            Use of “Important Note” (Notice creator to RMs)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Upon creating and delivering a notice, a comment can be placed in
            the<strong> Important Note</strong> section to make sure RMs are
            informed of any issues related to the notice, including specific
            issues of which RMs in certain countries should be aware.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">07</Typography>
          <Typography className="tit">
            Use of “Important Note” (RMs to Distributors)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            RMs can also leave comments in the
            <strong> Important Notice </strong>
            section to provide information of which their distributors should be
            aware. These comments can also be delivered through a popup window
            on the main page or a section for details in the list of notices.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">08</Typography>
          <Typography className="tit">
            Distributor List Management (RM)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Each RM can add to their list of distributors by selecting and
            adding distributors from the list of all distributors. However, RMs
            are not allowed to delete or register new distributors.
            <br /> Only the website manager has the authority to register new
            distributors. If you need to register a new distributor, please
            contact the website manager. In addition, please note that when
            another RM takes one of your distributors, that distributor will be
            automatically removed from your list of distributors.
            <br /> Detailed lists of distributors can be found in the
            <strong> My Page </strong>
            section, where you can also find information on the staffs of each
            distributor. Based on this information, you may request that
            relatively inactive staff members be asked to leave. You may also
            manage security issues by suspending or reinstating your
            distributors’ access to the website.
            <span className="Accordion-tit">
              Notice &#62; List (RM View) &#62; Popup (Edit my Distributors)
            </span>
            <span className="Accordion-tit">My Page &#62; My Distributors</span>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">09</Typography>
          <Typography className="tit">
            How to Check Whether Notices Have Been Read and Send Reminder Emails
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Whether an issued notice has been read can be confirmed by checking
            the Status of <strong>“Read”</strong> list.
            <br /> Pushing
            <strong> Send a Reminder Message </strong>will send an email to
            those who have not yet read the notice, reminding them that they
            need to do so.
            <span className="Accordion-tit">
              Notice &#62; List (RM View) &#62; Popup
            </span>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">10</Typography>
          <Typography className="tit">Feedback</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            On the page where notices are posted, there is a section where RMs
            and distributors can ask and answer questions and provide feedback.
            In the <strong>Q&#38;A List </strong>on<strong> My Page</strong>,
            you can find categorized lists of all previous questions and answers
            as well as post new questions and answers.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <img
              src={`${process.env.PUBLIC_URL}/images/icon/icon-arrow-down.svg`}
              alt="bottomIcon"
            />
          }
        >
          <Typography className="num">11</Typography>
          <Typography className="tit">Attachment of Files</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In general, all notices will be delivered in the form of a PDF file.
            However, additional information or reference documents for
            distributors’ use may also be attached.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
