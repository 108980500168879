import React, { useEffect, useState, useRef, useCallback } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Menu from "components/ui/menu";
import PublicMenu from "components/ui/public-menu";
import SgOneMenu from "components/ui/sgone-menu";
import Routes from "routes/Routes";
import Footer from "components/Footer";
import EdocFooter from "components/EdocFooter";
import axios from "axios";

export default function CustomRoute(props) {
  const { userInfo, isWhiteUser } = props;
  const [curRoute, setCurRoute] = useState(null);
  const curRouteRef = useRef(null);
  const refreshToken = useRef(window.localStorage.getItem("X-REFRESH-TOKEN"));
  const [viewState, setViewState] = useState({
    mobileView: false,
    drawerOpen: false,
    top: false,
  });

  const location = useLocation();

  const setResponsiveness = useCallback(() => {
    return window.innerWidth <= 1366
      ? setViewState((prevState) => ({ ...prevState, mobileView: true }))
      : setViewState((prevState) => ({ ...prevState, mobileView: false }));
  }, []);

  const checkRoute = useCallback(
    (route, props) => {
      // console.log("info----", route, userInfo, isWhiteUser);
      // console.log("route", route);
      // console.log("userInfo", userInfo);
      // console.log("refreshToken", refreshToken);
      curRouteRef.current = route;
      refreshToken.current = window.localStorage.getItem("X-REFRESH-TOKEN");

      // 새로고침했을 때 리프레쉬 토큰이 남아있을 경우 임시로 컴포넌트 렌더링
      if (!userInfo && refreshToken.current)
        return <route.component {...props} />;

      // whiteList 체크하여 유저 리다이렉트 처리
      if (
        !userInfo &&
        route.checkWhiteList &&
        route.path !== "/login" &&
        isWhiteUser === false
      ) {
        return <Redirect to="/login" />;
      }

      // 비로그인 사용자가 로그인이 필요한 페이지에 접근했을 시
      if (route.auth && !userInfo) {
        // console.log("route", route, window.location);
        return (
          <Redirect to={`/login?prev=${route.path + window.location.search}`} />
        );
      }

      //SG ONE페이지에 대리점직원이 접근하면 메인페이지로 이동
      if (!userInfo &&
            route.path.includes("/outsourcing/") && userInfo.mbrDtlTp === "3" )
          return <Redirect to="/main" />;


      // 해당 페이지가 요구하는 tp값을 만족하지 못했을 시
      if (
        route.tp &&
        _.filter(route.tp, (t) => t === userInfo?.tp).length === 0
      )
        return <Redirect to="/main" />;

      // 임시 비밀번호로 로그인했을 시
      if (
        userInfo &&
        route.auth &&
        (userInfo.tmpPwdYn === "Y" || userInfo.pwdUpdtYn == "N")
      )
        return <Redirect to="/change-password" />;

      return <route.component {...props} />;
    },
    [props]
  );

  useEffect(async () => {
    if (curRouteRef.current) {
      setCurRoute(curRouteRef.current);
    }
  }, [location]);

  useEffect(async () => {
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <div className="App-contents">
      {curRoute?.menu && (
        <>
          {curRoute?.auth ? (
                <>
                  {curRoute?.sgOne ? (
                      <SgOneMenu viewState={viewState} setViewState={setViewState} />
                  ):(
                      <div
                          className="App-header"
                          style={{ paddingBottom: viewState.mobileView ? 0 : 130 }}
                      >
                          <Menu viewState={viewState} setViewState={setViewState} />
                      </div>

                  )}
                </>
          ) : (
            <PublicMenu viewState={viewState} setViewState={setViewState} />
          )}
        </>
      )}
      <Switch>
        {Routes.map((route) => {
          return (
            <Route
              key={route.path}
              exact
              path={route.path}
              render={(props) => checkRoute(route, props)}
            />
          );
        })}
        <Redirect
          from="*"
          to={userInfo || refreshToken.current ? "/main" : "/login"}
        />
      </Switch>
      {curRoute?.footer && <Footer />}
      {curRoute?.edocFooter && <EdocFooter />}
    </div>
  );
}
